import axios from 'axios'
import { errorMessages } from 'utils'

import { getAuthToken } from 'utils'

import { TEAMMATE } from './reducers'


/**
 * Add one Teamates
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */
export const addTeammate = (data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: FACULTY.LOADING })
    try {
      const res = await axios({
        method: 'POST',
        url: '/user/addTeammate',
        data
      })

      if (callback) callback(res, false)
      dispatch({ type: TEAMMATE.Add_ONE_TEAMMATE, payload: res.data })

    } catch (err) {

      const errMessage =
        err.response?.data?.message || errorMessages.ERROR_MESSAGE
      dispatch({ type: TEAMMATE.ERROR, payload: err.response })
      if (callback) callback(errMessage, true)
    }
  }
}
/**
  console.log()
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchTeammate = (callback) => {
  return async (dispatch) => {
    // dispatch({ type: TEAMMATE.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/teammate',
      })
      dispatch({ type: TEAMMATE.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: TEAMMATE.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}


/**
 * update teammate data.
 * @param {String} teammate teammateId
 * @param {Object} data name,workspaces
 * @param {Function} callback callback
 */

export const updateTeammate = (teammateId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `user/${teammateId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TEAMMATE.UPDATE_ONE_TEAMMATE, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TEAMMATE.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Delete one teammate of a user.
 * @param {String} teammate teammateId
 * @param {Function} callback  callback function
 */
export const deleteTeammate = (teammateId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/user/${teammateId}`
      })
      if (callback) callback(res.data, false)

      dispatch({ type: TEAMMATE.DELETE_ONE_TEAMMATE, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TEAMMATE.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


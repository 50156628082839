import axios from 'axios'
import { ME } from 'thunks/me/reducers'
import { USER } from 'thunks/user/reducers'

export const verifyEmail = (data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'get',
        url: `/user/emailverify`
      })
      // dispatch(fireSuccessToaster('Password Changed'))
      // dispatch({ type: USER.USERNAMEUPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      // dispatch({
      //   type: USER.ERROR,
      //   payload: err?.response?.data?.message ?? 'Some Error Occured'
      // })
      // fireErrorToaster(err?.response?.data?.message ?? 'Some Error Occured')
      if (callback)
        callback(err?.response?.data?.message ?? 'Some Error Occured', true)
    }
  }
}

/**
 * update a user password
 * @param {Object} data  old password,new password
 * @param {Function} callback callback function
 */

export const changePassword = (data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'POST',
        url: `/user/changepassword`,
        data
      })
      // dispatch(fireSuccessToaster('Password Changed'))
      // dispatch({ type: USER.USERNAMEUPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      // dispatch({
      //   type: USER.ERROR,
      //   payload: err?.response?.data?.message ?? 'Some Error Occured'
      // })
      dispatch()
      // fireErrorToaster(err?.response?.data?.message ?? 'Some Error Occured')
      if (callback)
        callback(err?.response?.data?.message ?? 'Some Error Occured', true)
    }
  }
}

/**
 * update Teamamte isACtive status.
 * @param {id} id Teammate id
 * @param {Object} data isACtive
 * @param {Function} callback callback function
 */
export const updateOneTeammate = (id, data, callback) => {
  console.log(id, data)
  return async (dispatch) => {
    // dispatch({type: })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/${id}`,
        data
      })
      dispatch({ type: ME.UPDATE, payload: res.data })
      if (callback) callback(res, false)
    } catch (err) {
      console.log(err)
      // dispatch({type: , payload:err.response.data.message})
      if (callback) callback(err.response, true)
    }
  }
}

/**
 * update a user
 * @param {Object} data profile image ,name
 * @param {Function} callback callback function
 */

export const updateUser = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      me: {
        data: { _id: userId }
      }
    } = getState()

    dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/${userId}`,
        data
      })
      // dispatch(fireSuccessToaster('Profile Updated'))
      dispatch({ type: USER.UPDATED, payload: res.data })
      dispatch({ type: ME.UPDATED, payload: res.data })

      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: USER.ERROR, payload: err?.response?.data?.message })
      // dispatch(
      //   fireErrorToaster(err?.response?.data?.message ?? 'Some Error Occured')
      // )
      if (callback) callback(err.response.data.message, true)
    }
  }
}

const initialState = {
  loading: true,
  error: false,
  fetched: false,
  data: []
}

export const SECTION = {
  LOADING: 'SECTION_LOADING',
  ERROR: 'SECTION_ERROR',
  FETCHED: 'SECTION_FETCHED',
  UPDATED: 'SECTION_UPDATED',
  ADD_ONE_SECTION: 'ADD_ONE_SECTION',
  UPDATE_ONE_SECTION: 'UPDATE_ONE_SECTION',
  DELETE_ONE_SECTION: 'DELETE_ONE_SECTION'
}

const sectionReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case SECTION.LOADING:
      return { ...state, loading: true, error: false, fetched: true }

    case SECTION.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case SECTION.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case SECTION.UPDATED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case SECTION.ADD_ONE_SECTION:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data],
        fetched: true
      }

    case SECTION.UPDATE_ONE_SECTION:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        fetched: true,
        data: updatedData1
      }

    case SECTION.DELETE_ONE_SECTION:
      return {
        ...state,
        loading: false,
        fetched: true,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }
    default:
      return state
  }
}

export default sectionReducer

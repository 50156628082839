import Dashboard from 'containers/Dashboard/Dashboard'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import UserDashboard from '../components/UserDashboard/UserDashboard'
import App from '../containers/App/App'

// import MyTablePagination from '../components/Faculty1/table_pagination'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { checkLogin } from 'utils'

import { LOGIN } from 'thunks/login/reduers'

import { fetchMeData } from '../thunks/me/actions'

import Login from '../components/Login/LoginCopy'
import Signup from '../components/Signup/Signup'
import ForgetPassword from '../components/Forget/Forgot'

import ProtectedRoutes from './ProtectedRoutes'

import Settings from '../components/Settings/Settings'
import Faculty from '../components/Faculty/Faculty'
import Student from '../components/Student/Student'
import Teammates from '../components/Teammates/Teammates'
import ContactUs from '../components/ContactUs/ContactUs'
import Subject from '../components/Subject/Subject'
import Topics from '../components/Topics/Topics'
import Exam from '../components/Exams/Exam'
import examCategory from '../components/ExamCategory/ExamCategory'

// import section from '../components/Section/Section'

import Questions from '../components/Questions/Questions'

import FacultyRegistration from '../components/Faculty/facultyRegistration/FacultyRegistration'
import StudentRegistration from '../components/Student/registration/StudentRegistration'

import userRoles, { getUserPath } from 'utils/userRoles'

const Routes = () => {
  const { loggedIn } = useSelector((state) => state.login)
  const dispatch = useDispatch()

  // const meData = useSelector((state) => state.me.data)

  useEffect(() => {
    if (checkLogin()) {
      dispatch({ type: LOGIN.LOGGED_IN })
    }
  }, [])
  const meCallback = (res, err) => { }
  // useEffect(() => {
  //   dispatch(fetchMeData(meCallback))
  // }, [])

  return (
    <BrowserRouter>
      <Switch>
        <App path="/">
          <Route path={'/admin'}>
            <Dashboard>
              <Switch>
                <ProtectedRoutes
                  path="/admin/dashboard"
                  component={UserDashboard}
                  name="Dashboard"
                />

                <ProtectedRoutes
                  path={`/admin/subject/topic/:subjectId`}
                  component={Topics}
                />

                <ProtectedRoutes path="/admin/category" component={examCategory} />

                {/* <ProtectedRoutes path="/admin/section" component={section} /> */}

                <ProtectedRoutes path="/admin/exam" component={Exam} />

                <ProtectedRoutes path="/admin/faculty" component={Faculty} />

                <ProtectedRoutes path="/admin/student" component={Student} />
                <ProtectedRoutes
                  path="/admin/teammates"
                  component={Teammates}
                />

                <ProtectedRoutes path="/admin/subject" component={Subject} />
                <ProtectedRoutes path="/admin/question" component={Questions} />

                <ProtectedRoutes
                  path="/admin/contactus"
                  component={ContactUs}
                />
                <ProtectedRoutes path="/admin/settings" component={Settings} />
              </Switch>
            </Dashboard>
          </Route>

          <Route path={'/faculty'}>
            <Dashboard>
              <Switch>
                <ProtectedRoutes
                  path="/faculty/dashboard"
                  component={UserDashboard}
                  name="Dashboard"
                />

                <ProtectedRoutes
                  path="/faculty/settings"
                  component={Settings}
                />
              </Switch>
            </Dashboard>
          </Route>

          <Route path={'/student'}>
            <Dashboard>
              <Switch>
                <ProtectedRoutes
                  path="/student/dashboard"
                  component={UserDashboard}
                  name="Dashboard"
                />
                <ProtectedRoutes
                  path="/student/settings"
                  component={Settings}
                />
              </Switch>
            </Dashboard>
          </Route>

          <Switch>
            <Route path="/facultySignup" component={FacultyRegistration} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={StudentRegistration} />
            <Route path="/forget" component={ForgetPassword} />
          </Switch>
        </App>
      </Switch>
    </BrowserRouter>
    // <BrowserRouter>
    //   <Switch>
    //     <App>
    //       <Route path="/test">
    //         <Task />
    //       </Route>
    //       <Route>
    //         <Dashboard>
    //           <Switch>
    //             <Route path="/dashboard/test">
    //               <Task />
    //             </Route>

    //             <Route path="/faculty/accept">
    //               <MyTablePagination />
    //             </Route>
    //           </Switch>
    //         </Dashboard>
    //       </Route>
    //     </App>
    //   </Switch>
    // </BrowserRouter>
  )
}

export default Routes

import React, { useState } from 'react'
import logo from '../../static/images/logoSignIn.svg'
import '../Login/Login.css'
import { Link, useHistory } from 'react-router-dom'

import { TextField, Typography } from '@material-ui/core'

import CustomButton from '../InputElements/Button'

import background from '../../static/images/background2.svg'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword } from 'thunks/forgotPassword'

import useTitle from 'customHooks/useTitle'
import examhub_logo from '../../static/images/examhub_logo.png'

import examhub_name_logo from '../../static/images/logo1.png'




const SignIn = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [linkSent, setLinkSent] = useState(false)
  const [email, setEmail] = useState('')

  useTitle('Forget Password | Exam HUB', examhub_logo)


  const handleEmailInput = (e) => {
    const { value } = e.target
    setEmail(value)
  }

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    forgotPassword(email, handleSubmitCallback)
  }

  const handleSubmitCallback = (res, err) => {
    setLoading(false)
    if (err) {
      //   dispatch(fireErrorToaster(res.message))
    } else {
      //   dispatch(fireSuccessToaster(res.message))
      setLinkSent(true)
    }
  }

  return (
    <div className="login">
      <header className="header">
        <Link to={'/login'} className="text-primary">
          {' '}
          <img style={{background: "#ede2e1", borderRadius: "20px", width: '150px', height: '40px'}} src={examhub_name_logo} alt="logo" />
        </Link>
      </header>
      <div className="login-container">
        <div className="login-hero">
          <img src={background} alt="background" />
        </div>
        <div className="login-content">
          <h2 className="login-welcome">Forget Password! 👋</h2>
          <form onSubmit={handleSubmit}>
            {linkSent ? (
              <div
                style={{
                  alignSelf: 'flex-start',
                  paddingLeft: '5%',
                  marginTop: '-12px'
                }}
              >
                <Typography> Reset link has been sent to your mail</Typography>
              </div>
            ) : (
              <>
                <p>Enter your registered Email id below</p>

                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="email"
                  name="email"
                  onChange={handleEmailInput}
                  value={email}
                  required
                />

                <div className="form-button">
                  <CustomButton loading={loading} fullWidth type="submit">
                    Send Reset Instructions
                  </CustomButton>
                </div>
              </>
            )}
          </form>
          <p className="login-isNew login-create" style={{ marginTop: '20px' }}>
            Return to <Link to={'/login'}>Login</Link>
          </p>
          <div className="divider"></div>
        </div>
      </div>
    </div>
  )
}

export default SignIn

import axios from 'axios'
import { errorMessages, userRoles } from 'utils'
import { storeToken } from 'utils/userLogin'

/**
 * User signup api call
 * @param {Object} data Use sigup data
 * @param {Function} callback Callback function. Receives (res/err, errStatus) as params
 */
export const userSignup = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/user/signup',
      data
    })
    storeToken(res.data.login.token)
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

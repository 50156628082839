import axios from 'axios'
import { PERMISSION } from './reducers'
import { errorMessages } from 'utils'

export const addPermission = (data) => {
  return async (dispatch) => {
    try {
      // const res = await axios({
      //   method: 'POST',
      //   url: '/category',
      //   data
      // })
      dispatch({ type: PERMISSION.ADD_ONE_PERMISSION, payload: data })
      // if (callback) callback(data, false)
    } catch (err) {
      // console.log(err)
      // if (callback)
      //   callback(
      //     err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
      //     true
      //   )
      // dispatch({
      //   type: PERMISSION.ERROR,
      //   payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      // })
    }
  }
}

const userRoles = {
  USER_PRO_ADMIN: 10,
  ADMIN: 11,
  ADMIN_MANAGER: 12,
  ADMIN_VIEWER: 13,

  USER_FACULTY: 20,

  USER_STUDENT: 30
}

export default userRoles

export const Role = {
  USER_PRO_ADMIN: 10,
  ADMIN: 11,
  ADMIN_MANAGER: 12,
  ADMIN_VIEWER: 13,

  USER_FACULTY: 20,

  USER_STUDENT: 30
}

export const roleName = {
  10: 'Pro Admin',
  11: 'Admin',
  12: 'Manager',
  13: 'Viewer',

  20: 'Faculty',

  30: 'Student'
}

export const proAdminRoleOptions = [
  { value: 11, label: 'Admin' },
  { value: 12, label: 'Manager' },
  { value: 13, label: 'Viewer' }
]

export const facultyRoleOptions = [{ value: 20, label: 'Faculty' }]

export const studentRoleOptions = [{ value: 20, label: 'Student' }]

export const getUserPath = (role) => {
  const arr = [
    userRoles.USER_PRO_ADMIN,
    userRoles.ADMIN_ADMIN,
    userRoles.ADMIN_MANAGER,
    userRoles.ADMIN_VIEWER,
    userRoles.USER_FACULTY,
    userRoles.USER_STUDENT
  ]
  if (
    [
      userRoles.USER_PRO_ADMIN,
      userRoles.ADMIN_ADMIN,
      userRoles.ADMIN_MANAGER,
      userRoles.ADMIN_VIEWER
    ].includes(role)
  )
    return '/admin'
  else if ([userRoles.USER_FACULTY].includes(role)) return '/faculty'
  else return '/student'
}

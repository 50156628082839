import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@material-ui/core'

import { OuterContentWrapper, SectionHeading } from 'global/globalComponents'
import General from './General/General'

import Security from './Security/Security'

import userRoles, { getUserPath } from 'utils/userRoles'

import { ReactComponent as ProfileIcon } from 'static/svg/profile.svg'
import { ReactComponent as SecurityIcon } from 'static/svg/security.svg'
import {
  Route,
  useLocation,
  Switch,
  useRouteMatch,
  useHistory
} from 'react-router-dom'

import { useSelector } from 'react-redux'

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

const Settings = (props) => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()

  const [selectedTab, setSelectedTab] = useState(pathname)

  const meData = useSelector((state) => state.me.data)

  // const [currentTab, setCurrentTab] = useState(0)

  const tabs = [
    {
      name: 'General',
      value: path + '/general',
      icon: <ProfileIcon />
    },
    {
      name: 'Security',
      value: path + '/security',
      icon: <SecurityIcon />
    }
    // { name: 'Notifications' },
    // { name: 'Plan' },
    // { name: 'Billing' },
    // { name: 'Team Members' }
  ]

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
    history.push(newVal)
  }

  return (
    <OuterContentWrapper>
      <SectionHeading className="mb-9">Settings</SectionHeading>

      <div className="mb-8">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          {tabs.map((item, index) => (
            <Tab
              label={
                <div className="flex items-center gap-1">
                  <span>{item.icon}</span> <span>{item.name}</span>
                </div>
              }
              key={index}
              value={item.value}
            />
          ))}
        </Tabs>
        <div style={{ border: '1px solid #ECEBF5' }}></div>
      </div>
      <Switch>
        <Route path={`${getUserPath(meData.role)}/settings/general`}>
          <General />
        </Route>
        <Route
          path={`${getUserPath(meData.role)}/settings/security`}
          component={Security}
        />
      </Switch>
    </OuterContentWrapper>
  )
}

export default Settings

import { GENERAL_APP_STATE } from './reducers'

export const toggleSideDrawer = () => {
  return (dispatch) => {
    dispatch({ type: GENERAL_APP_STATE.TOGGLE_SIDE_DRAWER })
  }
}

export const toggleAddFacultySidePanel = (open, data = {}) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        addFaculty: {
          open:
            open === undefined
              ? !state.generalAppState.sidePanel.addFaculty.open
              : open,
          data
        }
      }
    })
  }
}

export const updateUserAcces = (role) => {
  return (dispatch, getState) => {
    // const {
    //   me: { data }
    // } = getState()
    dispatch({
      type: GENERAL_APP_STATE.UPDATE_USER_ACCESS,
      payload: role
    })
  }
}

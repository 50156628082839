const initialState = {
  loading: true,
  error: false,
  data: [],
  fetched: false
}

export const SUBJECT = {
  LOADING: 'SUBJECT_LOADING',
  ERROR: 'SUBJECT_ERROR',
  FETCHED: 'SUBJECT_FETCHED',
  UPDATED: 'SUBJECT_UPDATED',
  ADD_ONE_SUBJECT: 'SUBJECT_ADD_ONE_SUBJECT',
  UPDATE_ONE_SUBJECT: 'UPDATE_ONE_SUBJECT',
  DELETE_ONE_SUBJECT: 'DELETE_ONE_SUBJECT'
}

const studentReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)

  switch (action.type) {
    case SUBJECT.LOADING:
      return { ...state, loading: true, error: false, fetched: false }

    case SUBJECT.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case SUBJECT.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case SUBJECT.UPDATED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case SUBJECT.ADD_ONE_SUBJECT:
      return {
        ...state,
        loading: false,
        error: false,
        data: [...state.data, action.payload]
      }

    case SUBJECT.UPDATE_ONE_SUBJECT:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        data: updatedData1,
        fetched: true
      }

    case SUBJECT.DELETE_ONE_SUBJECT:
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data.filter((item) => item._id !== action.payload._id),
        fetched: true
      }
    default:
      return state
  }
}

export default studentReducer

const FormLabel = (props) => {
  const { children, required = false, className, ...restProps } = props
  return (
    <label
      className={`block text-xs font-medium text-gray-700 pb-1 ${
        required ? 'required' : ''
      } ${className}`}
      {...restProps}
    >
      {children}
    </label>
  )
}

export default FormLabel

import axios from 'axios'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import { errorMessages } from 'utils'

import { getAuthToken } from 'utils'
import { STUDENT } from './reducers'


/**
 * Add one Student
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */
export const addStudent = (data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: FACULTY.LOADING })
    try {
      const res = await axios({
        method: 'POST',
        url: '/user/addStudent',
        data
      })
      if (callback) callback(res, false)
      dispatch({ type: STUDENT.ADD_ONE_STUDENT, payload: res.data })

    } catch (err) {
      const errMessage =
        err.response?.data?.message || errorMessages.ERROR_MESSAGE
      dispatch({ type: STUDENT.ERROR, payload: err.response })
      if (callback) callback(errMessage, true)
    }
  }
}
/**
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchStudent = (callback) => {
  return async (dispatch) => {
    // dispatch({ type: STUDENT.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/student',
      })
      dispatch({ type: STUDENT.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: STUDENT.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}


/**
 * update student data.
 * @param {String} student studentId
 * @param {Object} data name,workspaces
 * @param {Function} callback callback
 */

export const updateStudent = (studentId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/${studentId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: STUDENT.UPDATE_ONE_STUDENT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: STUDENT.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Delete one student of a user.
 * @param {String} student student Id
 * @param {Function} callback  callback function
 */
export const deleteStudent = (studentId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/user/${studentId}`,
        data: data
      })
      if (callback) callback(res.data, false)

      dispatch({ type: STUDENT.DELETE_ONE_STUDENT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: STUDENT.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


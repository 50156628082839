import axios from 'axios'
import { LOGIN } from 'thunks/login/reduers'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import { getAuthToken } from 'utils'

import { errorMessages } from 'utils'
import { storeToken } from 'utils/userLogin'

import { FACULTY } from './reducers'

var facultyTempData = {
  data: [
    {
      _id: 1,
      name: 'Jane Cooper',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 20,
      isActive: true,
      isBlocked: false,
      status: 'accepted',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
    },

    {
      _id: 2,
      name: 'Jane Cooper',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 20,
      isActive: true,
      isBlocked: true,
      status: 'accepted',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
    },

    {
      _id: 3,
      name: 'Jane Cooper',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 20,
      isActive: true,
      isBlocked: false,
      status: 'accepted',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
    },

    {
      _id: 4,
      name: 'Jane Cooper',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 20,
      isActive: true,
      isBlocked: false,
      status: 'accepted',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
    },

    {
      _id: 5,
      name: 'Inactive Faculty -2',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 20,
      isActive: true,
      isBlocked: false,
      status: 'accepted',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
    },

    {
      _id: 6,
      name: 'Request Faculty-1',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 20,
      isActive: true,
      isBlocked: false,
      status: 'request',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
    },
    {
      _id: 7,
      name: 'Rejected Faculty-1',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 20,
      isActive: false,
      isBlocked: false,
      status: 'rejected',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
    }
  ]
}

/**
 * Add one Faculty
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */
export const addFaculty = (data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: FACULTY.LOADING })
    try {

      const res = await axios({
        method: 'POST',
        url: '/user/addFaculty',
        data
      })

      storeToken(res.data.login.token)
      dispatch({ type: LOGIN.LOGGED_IN })
      dispatch({ type: FACULTY.ADD_ONE_FACULTY, payload: res.data })

      callback(res.data)
      // if (callback) callback(res, false)


    } catch (err) {
      const errMessage =
        err.response?.data?.message || errorMessages.ERROR_MESSAGE
      dispatch({ type: FACULTY.ERROR, payload: err.response })
      if (callback) callback(errMessage, true)
    }
  }
}

/**
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchFaculty = (callback) => {
  return async (dispatch) => {
    // dispatch({ type: FACULTY.LOADING })
    try {

      const res = await axios({
        method: 'GET',
        url: '/user/faculty',
        headers: {
          Authorization: getAuthToken()
        }
      })

      dispatch({ type: FACULTY.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)

    } catch (err) {
      console.log(err)
      dispatch({ type: FACULTY.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}


/**
 * update faculty data.
 * @param {String} faculty facultyId
 * @param {Object} data name,workspaces
 * @param {Function} callback callback
 */

export const updateFaculty = (facultyId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/${facultyId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: FACULTY.UPDATE_ONE_FACULTY, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: FACULTY.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Delete one faculty of a user.
 * @param {String} faculty faculty Id
 * @param {Function} callback  callback function
 */
export const deleteFaculty = (facultyId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/user/${facultyId}`,
        data: data
      })
      if (callback) callback(res.data, false)

      dispatch({ type: FACULTY.DELETE_ONE_FACULTY, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: FACULTY.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


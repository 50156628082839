import axios from 'axios'
// import { updateUserAcces } from 'thunks/generlAppState/actions'
import { getAuthToken } from 'utils'
import { ME } from './reducers'

/**
 * Fetch data of user
 * @param {Function} callback function will run after fetch. Recieve (err/response, error status) as arguments
 */
export const fetchMeData = (callback) => {
  return async (dispatch) => {
    dispatch({ type: ME.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/me',
        headers: {
          Authorization: getAuthToken()
        }
      })
      dispatch({ type: ME.FETCHED, payload: res.data })
      // dispatch(updateUserAcces(res.data.role))
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: ME.ERROR, payload: err.response })
      if (callback) callback(err, true)
    }
  }
}

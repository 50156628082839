const initialState = {
  loading: true,
  error: false,
  fetched: false,
  data: []
}

export const PERMISSION = {
  LOADING: 'PERMISSION_LOADING',
  ERROR: 'PERMISSION_ERROR',
  FETCHED: 'PERMISSION_FETCHED',
  // UPDATED: 'PERMISSION_UPDATED',
  ADD_ONE_PERMISSION: 'ADD_ONE_PERMISSION'
  // UPDATE_ONE_PERMISSION: 'UPDATE_ONE_PERMISSION',
  // DELETE_ONE_PERMISSION: 'DELETE_ONE_PERMISSION'
}

console.log()
const permissionReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case PERMISSION.LOADING:
      return { ...state, loading: true, error: false, fetched: true }

    case PERMISSION.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case PERMISSION.ADD_ONE_PERMISSION:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data],
        fetched: true
      }

    default:
      return state
  }
}

export default permissionReducer

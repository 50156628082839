import React, { useState, useEffect } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import { updateExam } from '../../thunks/exam/actions'

import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'

import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

import { fetchAllExamCategory } from 'thunks/examCategory/actions'


import ExamStyles from './ExamStyles'

const ExamEditPanel = (props) => {
  const { open, onClose, data } = props

  const classes = ExamStyles()
  const allExamCategory = useSelector(state => state.examCategory)

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()


  const [examCategoryOptions, setExamCategoryOptions] = useState({
    loading: true,
    data: []
  })

  const [examData, setExamData] = useState({
    name: '',
    examCategory: [],
    totalQuestions: null,
    totalTimeInMinutes: null
  })


  useEffect(() => {
    setExamData((prev) => ({
      ...prev,
      name: data.name,
      examCategory: data.examCategory,
      totalQuestions: data.totalQuestions,
      totalTimeInMinutes: data.totalTimeInMinutes
    }))
  }, [data])

  useEffect(() => {
    if (!allExamCategory.fetched) {
      dispatch(fetchAllExamCategory())
    }
    setExamCategoryOptions(prev => ({ ...prev, loading: false, data: allExamCategory.data.map((item) => ({ label: item.name, value: item._id })) }))
  }, [allExamCategory])

  const formInputHandler = (e) => {
    const { value, name } = e.target

    setExamData((prevState) => ({ ...prevState, [name]: value }))
  }

  const clientCallback = (res, err) => {
    setLoading(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      onClose()
      setExamData({ name: '' })
      dispatch(fireSuccessToaster('Exam Updated'))
    }
  }

  const handleUpdateExam = () => {
    setLoading(true)
    const filteredDataSet = {
      ...examData,
      name: examData.name.trim().toLowerCase()
    }

    if (!filteredDataSet.name.trim()) {
      dispatch(fireErrorToaster('Invalid name value!'))
      return
    }

    dispatch(updateExam(data._id, filteredDataSet, clientCallback))
  }

  const handleExamChange = (e) => {
    setExamData((oldState) => ({
      ...oldState,
      type: e.value
    }))
  }

  return (
    <>
      <CustomDrawer
        onClose={onClose}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={onClose}>Update Exam</DrawerHeader>
        <DrawerContent open={open}>
          <Box mt={2}>
            <FormLabel required>Exam Name</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              name="name"
              inputProps={{
                autoComplete: 'off'
              }}
              value={examData.name}
              type="text"
              placeholder="Full Name"
              onChange={formInputHandler}
            />
          </Box>

          <Box mt={2}>
            <FormLabel>Type</FormLabel>
            <Select
              className="col-span-4 md:col-span-2 text-sm"
              placeholder="Type"
              name="type"
              disabled={true}
              isSearchable={false}

              value={examData?.examCategory?.map(item => ({ label: item.name, value: item._id }))}

              isMulti
              options={examCategoryOptions.data}
              // onChange={handleExamCategoryChange}
              styles={reactSelectCustomStyles}
            />
          </Box>

          <div className="flex justify-between items-center space-x-4">

            <Box mt={2} className="flex-1" >
              <FormLabel required>Total Time (in Minutes)</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="totalTimeInMinutes"
                type="number"
                placeholder="Enter Exam Time in Minutes (eg. 60)"
                value={examData.totalTimeInMinutes}
                onChange={formInputHandler}
                required
              />
            </Box>

            <Box mt={2} className="flex-1" >
              <FormLabel required>Total Questions</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="totalQuestions"
                type="number"
                placeholder="Total Questions"
                value={examData.totalQuestions}
                onChange={formInputHandler}
                required
              />
            </Box>
          </div>

        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button variant="outlined" onClick={onClose} className="w-full">
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleUpdateExam}
            className="w-full"
          >
            Update
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(ExamEditPanel)

import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Button } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { useDispatch, useSelector } from 'react-redux'

import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { updateUserAcces } from 'thunks/generlAppState/actions'
import SectionHeading from 'global/globalComponents/SectionHeading'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation
} from 'react-router'
import { clearAndReplaceBreadCumbs } from 'thunks/breadcrumbs/action'
import userRoles, { getUserPath } from 'utils/userRoles'
import { CustomTabs } from 'global/globalComponents'

import ActiveTeammates from './ActiveTeammates'
import InactiveTeammates from './InactiveTeammates'
import BlockedTeammates from './BlockedTeammates'
import AddNewTeammate from './AddNewTeammate'

import { fetchTeammate } from 'thunks/teammate/actions'
// import InactiveTeammates from './InactiveTeammates'

const Teammates = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { data: me } = useSelector((state) => state.me)
  const [selectedTab, setSelectedTab] = useState(pathname)
  const [teammateAddPanel, setTeammateAddPanel] = useState(false)

  var userTeammate = useSelector((state) => state.teammate)

  const tabsData = useMemo(() => {
    const arr = [
      { label: 'Teammates', value: path + '/all' },
      { label: 'Inactive Teammates', value: path + '/inactive' }
      // { label: 'Blocked Teammates', value: path + '/blocked' }
    ]

    return arr
  }, [me])

  useEffect(() => {
    if (!userTeammate.fetched) {
      dispatch(fetchTeammate())
    }
  }, [userTeammate.fetched])

  useEffect(() => {
    dispatch(
      clearAndReplaceBreadCumbs([
        {
          name: 'Teammates',
          link: `${getUserPath(me?.role)}/dashboard`,
          index: 0
        }
      ])
    )
  }, [me])

  useEffect(() => {
    dispatch(updateUserAcces(me.role))
  }, [])

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  const handleTabChange = useCallback((newVal) => {
    setSelectedTab(newVal)
    history.push(newVal)
  }, [])

  const handleClose = () => {
    setTeammateAddPanel(false)
  }

  const handleSidePanel = () => {
    setTeammateAddPanel(true)
  }

  return (
    <OuterContentWrapper>
      <div className="flex justify-between items-center mb-9">
        <SectionHeading>Teammates</SectionHeading>
        <Button
          variant="outlined"
          startIcon={<AddIcon fontSize="small" />}
          onClick={handleSidePanel}
        >
          New Teammates
        </Button>
      </div>
      <div className="flex justify-between items-center mb-9 ">
        <div className="w-full">
          <CustomTabs
            tabsData={tabsData}
            value={selectedTab}
            onChange={handleTabChange}
          />
        </div>
      </div>

      <Switch>
        <Route exact path={path + '/inactive'}>
          <InactiveTeammates />
        </Route>
        {/* <Route exact path={path + '/blocked'}>
          <BlockedTeammates />
        </Route> */}

        <Route path={path}>
          <ActiveTeammates />
        </Route>
      </Switch>

      <AddNewTeammate open={teammateAddPanel} onClose={handleClose} />
    </OuterContentWrapper>
  )
}

export default Teammates

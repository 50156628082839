import React, { useEffect, useState } from 'react'
import { Button, Chip, FormLabel, Radio, TextField, } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import {
  OuterContentWrapper,
  SectionHeading,
} from 'global/globalComponents'

import clsx from 'clsx'

import Select from 'react-select'

import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

import QuestionsEditor from './QuestionEditor'

import { fetchTopicsBySubjectId } from 'thunks/topic/actions'

import { fetchSubject } from 'thunks/subject/actions'
import { LoadingButton } from 'global/globalComponents'

import useTitle from 'customHooks/useTitle'
import examhub_logo from '../../static/images/examhub_logo.png'

import { fetchAllExam } from 'thunks/exam/actions'

import { fireSuccessToaster, fireErrorToaster } from 'thunks/fireToaster/actions'

import { fetchAllExamCategory } from 'thunks/examCategory/actions'


import PreviewQuestion from './PreviewQuestion'
import { useRef } from 'react'



const Questions = () => {
  useTitle('Questions', examhub_logo)

  const ref = useRef()
  const dispatch = useDispatch()

  const [previewQuestionState, setPreviewQuestionState] = useState(false)

  const allExamCategory = useSelector(state => state.examCategory)

  var allSubjects = useSelector((state) => state.subject)
  var allCategory = useSelector((state) => state.exams)

  var [examCategoryOptions, setExamCategoryOptions] = useState({
    loading: true,
    data: []
  })

  var [selectedExamCategory, setSelectedExamCategory] = useState([])


  var [selectedExamType, setSelectedExamType] = useState([])

  var [categoryOptions, setCategoryOptions] = useState({
    loading: true,
    data: []
  })
  var [selectedCategory, setSelectedCategory] = useState([])

  var [correctAnswerSelect, setCorrectAnswerSelect] = useState(null)

  var [subjectOptions, setSubjectOptions] = useState({
    loading: true,
    data: []
  })

  var [selectedSubject, setSelectedSubject] = useState({})


  const [topicsOptions, setTopicsOptions] = useState({
    loading: false,
    data: []
  })

  const [selectedTopic, setSelectedTopic] = useState({})

  const [questionState, setQuestionState] = useState({
    level: [],
    category: [],
    subject: null,
    topics: null,
    question: null,
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    option5: '',
    correctAnswer: "",
    explaination: null
  })

  const [questionArray, setQuestionArray] = useState([])


  const [defaultOption, setDefaultOption] = useState(true)

  var examType = [
    {
      label: "Free",
      value: "free"
    },
    { label: "Paid", vaue: "paid" }

  ]

  useEffect(() => {
    if (!allExamCategory.fetched) {
      dispatch(fetchAllExamCategory())
    }
    setExamCategoryOptions(prev => ({ ...prev, loading: false, data: allExamCategory.data.map((item) => ({ label: item.name, value: item._id })) }))
  }, [allExamCategory])

  useEffect(() => {
    if (selectedSubject.value) {
      setTopicsOptions({ data: [], loading: true })

      dispatch(
        fetchTopicsBySubjectId(selectedSubject.value, (res, err) => {
          if (res) {
            setTopicsOptions((prev) => ({
              ...prev,
              loading: false,
              data: res.map((item) => ({ label: item.name, value: item._id }))
            }))
          } else {
            setTopicsOptions((prev) => ({ ...prev, loading: false }))
          }
        })
      )
    }
  }, [selectedSubject.value])

  useEffect(() => {
    if (allCategory.data.length === 0) {
      dispatch(fetchAllExam())
    }
    setCategoryOptions(prev => ({ ...prev, loading: false, data: allCategory.data.map((item) => ({ label: item.name, value: item._id })) }))
  }, [allCategory])

  useEffect(() => {
    if (allSubjects.data.length === 0) {
      dispatch(fetchSubject())
    }
  }, [allSubjects])

  useEffect(() => {
    var options = []
    selectedExamCategory?.map(async item => {
      var findExamCategory = allExamCategory?.data?.find(examCategory => examCategory._id === item.value)
      if (findExamCategory) {
        findExamCategory?.exams?.map(async exam => {
          var checkExistingSubject = options?.find(options => options._id === exam._id)
          if (!checkExistingSubject) {
            options.push(exam)
          }
        })
      }
      setCategoryOptions(prev => ({ ...prev, loading: false, data: options.map((item) => ({ label: item.name, value: item._id })) }))
    })
  }, [selectedExamCategory])


  useEffect(() => {
    var options = []
    selectedCategory?.map(item => {
      var findCategory = allCategory?.data?.find(category => category._id === item.value)
      if (findCategory) {
        findCategory?.subject?.map(sub => {
          var checkExistingSubject = options?.find(option => option._id === sub._id)
          if (!checkExistingSubject)
            options.push(sub)
        })
      }
    })
    setSubjectOptions(prev => ({ ...prev, loading: false, data: options.map((item) => ({ label: item.name, value: item._id })) }))
  }, [selectedCategory])


  const handleChange = (newVal, e) => {
    const { name, option } = e

    if (name === "examType") {
      setSelectedExamType(newVal)
    } else if (name === "examcategory") {
      setSelectedExamCategory(newVal)
    } else if (name === "exam") {
      setSelectedCategory(newVal)
    } else if (name === "subject") {
      setSelectedSubject((prev) => ({ ...prev, ...newVal }))
    } else if (name === "topic") {
      setSelectedTopic((prev) => ({ ...prev, ...newVal }))
    }
  }

  const handleOptionsChange = (e) => {
    setQuestionState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const handleChangeCorrectAnswer = (e) => {
    setCorrectAnswerSelect(e.target.value)
  }

  const updateQuestion = (question) => {
    setQuestionState(prev => ({ ...prev, question }))
  }

  const handleAddQuestion = () => {

    var finalObj = {
      ...questionState,
      examType: selectedExamType.map(item => item.value),
      examCategory: selectedExamCategory.map(item => item.value),
      exam: selectedCategory.map(item => item.value),
      subject: selectedSubject.value,
      topics: selectedTopic.value,
      question: questionState.question,
      correctAnswer: correctAnswerSelect,
    }




    if (finalObj.examType == 0) {
      return dispatch(fireErrorToaster('Please select Exam Type'))
    } else if (finalObj.examCategory == 0) {
      return dispatch(fireErrorToaster('Please select Exam Category'))
    } else if (finalObj.exam == 0) {
      return dispatch(fireErrorToaster('Please select Exam'))
    } else if (!finalObj.subject) {
      return dispatch(fireErrorToaster('Please select subject'))
    } else if (!finalObj.topics) {
      return dispatch(fireErrorToaster('Please select topic'))
    } else if (!finalObj.question) {
      return dispatch(fireErrorToaster('Please enter question'))
    } else if (!finalObj.option1 || !finalObj.option2 || !finalObj.option3 || !finalObj.option4) {
      return dispatch(fireErrorToaster('Please enter options'))
    } else if (!finalObj.correctAnswer) {
      return dispatch(fireErrorToaster('Please select correct answer'))
    } else {


      setQuestionArray(prev => ([...prev, finalObj]))

      setCorrectAnswerSelect(null)
      setTopicsOptions({
        loading: false,
        data: []
      })
      setQuestionState({
        level: [],
        category: [],
        subject: null,
        topics: null,
        question: null,
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        option5: '',
        correctAnswer: "",
        explaination: null
      })

      ref.current.clearEditor()


      if (defaultOption == false) {
        setSelectedSubject({})
        setSelectedExamCategory([])
        setCategoryOptions({ loading: true, data: [] })
        setSelectedCategory([])
        setSubjectOptions({ loading: true, data: [] })
        setSelectedTopic({})
      }
      dispatch(fireSuccessToaster('Question added successfully'))
    }
  }

  const handlePreviewButton = () => {
    setPreviewQuestionState(prev => !previewQuestionState)
  }

  const handleDefaultValue = (e) => {

    setDefaultOption(prev => !prev)

  }

  return (
    <OuterContentWrapper>
      <SectionHeading>Question </SectionHeading>

      <div className="flex space-x-6 py-8 bg-white border border-gray-300 shadow p-4 rounded">
        <div className="flex-1">

          <div setDefaultOptionclassName="mb-2">

            <div className="flex space-x-4 mb-4">

              <div className="flex-1">
                <Select
                  // isLoading={examCategoryOptions.loading}
                  options={examType}
                  value={
                    selectedExamType.length ? selectedExamType : ''
                  }
                  styles={reactSelectCustomStyles}
                  isMulti
                  placeholder="Exam Type "
                  className="w-full"
                  name="examType"
                  onChange={handleChange} fireSuccessToaster
                />
              </div>

              <div className="flex-1">
                <Select
                  isLoading={examCategoryOptions.loading}
                  options={examCategoryOptions.data}
                  value={
                    selectedExamCategory.length ? selectedExamCategory : ''
                  }
                  styles={reactSelectCustomStyles}
                  isMulti
                  placeholder="Exam Category "
                  className="w-full"
                  name="examcategory"
                  onChange={handleChange}
                />
              </div>

              <div className="flex-1">
                <Select
                  options={categoryOptions.data}
                  isLoading={categoryOptions.loading}
                  value={
                    selectedCategory.length ? selectedCategory : ''
                  }
                  styles={reactSelectCustomStyles}
                  placeholder="Select Exam"
                  className="w-full"
                  name="exam"
                  isMulti
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex space-x-4 mb-4">
              <div className="flex-1">
                <Select
                  options={subjectOptions.data}
                  isLoading={subjectOptions.loading}
                  value={
                    selectedSubject.value ? selectedSubject : ''
                  }
                  styles={reactSelectCustomStyles}
                  placeholder="Choose a subject "
                  className="w-full"
                  name="subject"
                  onChange={handleChange}
                />
              </div>

              <div className="flex-1">
                <Select
                  placeholder="Topics"
                  options={topicsOptions.data}
                  value={selectedTopic.value ? selectedTopic : ''}
                  className="w-full"
                  styles={reactSelectCustomStyles}
                  name="topic"
                  isLoading={topicsOptions.loading}
                  onChange={handleChange}
                />
              </div>
            </div>


            <div className="flex mb-4 mt-4 space-x-4 justify-end">
              {/* <Button
                variant="outlined"
                size="small"
              >
                Clear
              </Button> */}

              <Button
                variant="contained"
                // style={clsx(defaultOption ? { backgroundColor: "red" } : { backgroundColor: "blue" })}
                onClick={handleDefaultValue}
                size="small"
              >
                {defaultOption ? "Remove From Default" : "Set As Default"}
                {/* Set As Default */}
              </Button>
            </div>

            {/* <div className="flex justify-between"> */}
            <div >
              <QuestionsEditor
                placeholder="Question"
                taskDescription={questionState.question}
                updateQuestion={updateQuestion}
                elmFor="question"
                ref={ref}
              />
            </div>

            {/* </div> */}

            {/* options 1-2 */}
            <div className=" flex mb-2 mt-4 text-xs text-primary-main">


              <Radio
                name="option1Answer"
                color="success"
                value="option1"
                checked={correctAnswerSelect === 'option1'}
                onChange={handleChangeCorrectAnswer}
              />
              <div className="mb-2 flex-1">
                <TextField
                  placeholder="Option 1"
                  className="w-full"
                  name="option1"
                  multiline={false}
                  value={questionState.option1}
                  onChange={handleOptionsChange}
                />
              </div>

              <Radio name="option2Answer"
                color="success"
                value="option2"
                checked={correctAnswerSelect === 'option2'}
                onChange={handleChangeCorrectAnswer}
              />
              <div className="mb-2 flex-1">
                <TextField
                  placeholder="Option 2"
                  className="w-full"
                  name="option2"
                  multiline={true}
                  value={questionState.option2}
                  onChange={handleOptionsChange}
                />
              </div>
            </div>

            {/* Options 3- 4 */}
            <div className="flex mb-2 text-xs text-primary-main">

              <Radio name="option3Answer"
                color="success"
                value="option3"
                checked={correctAnswerSelect === 'option3'}
                onChange={handleChangeCorrectAnswer}
              />
              <div className="mb-2 flex-1">
                <TextField
                  placeholder="Option 3"
                  className="w-full"
                  name="option3"
                  multiline={false}
                  value={questionState.option3}
                  onChange={handleOptionsChange}
                />
              </div>

              <Radio name="option4Answer"
                value="option4"
                checked={correctAnswerSelect === 'option4'}
                color="success" onChange={handleChangeCorrectAnswer}
              />
              <div className="mb-2 flex-1">
                <TextField
                  placeholder="Option 4"
                  className="w-full"
                  name="option4"
                  multiline={true}
                  value={questionState.option4}
                  onChange={handleOptionsChange}
                />
              </div>
            </div>


          </div>

          <div className="space-x-4 flex mt-6">

            {
              questionArray.length > 0 && (
                <Button
                  // variant="outlined"
                  className="flex-1"
                  onClick={handlePreviewButton}
                  size="small"
                  style={{ backgroundColor: "red" }}
                >
                  Preview & Final Upload
                </Button>

              )
            }

            <LoadingButton
              loading={false}
              onClick={handleAddQuestion}
              className="w-full flex-1"
            >
              Add Next Question
            </LoadingButton>
          </div>
        </div>
      </div >


      <PreviewQuestion
        questions={questionArray}
        open={previewQuestionState}
        setPreviewQuestionState={setPreviewQuestionState}
        questionArray={questionArray}
      />

    </OuterContentWrapper >
  )
}

export default Questions

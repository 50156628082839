import React, { useEffect } from 'react'
import { makeStyles, createStyles, Toolbar, Drawer } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import { showHeaderShadow } from 'thunks/generlAppState/actions'
import { useDispatch } from 'react-redux'
// import { hideChatMessenger, showChatMessenger } from 'utils'

const CustomDrawer = ({ children, open, ...rest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(showHeaderShadow(!open))

  //   if (open) {
  //     hideChatMessenger()
  //   } else {
  //     showChatMessenger()
  //   }
  // }, [open])

  return (
    <Drawer
      // BackdropProps={{ invisible: true }}
      open={open}
      {...rest}
      classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
      BackdropProps={{
        classes: { root: classes.backdropRoot }
      }}
    >
      {children}
    </Drawer>
  )
}

export default CustomDrawer

export const DrawerHeader = (props) => {
  const { onClose, children, ...restProps } = props
  const classes = useStyles()
  return (
    <Toolbar
      {...restProps}
      classes={{ root: classes.toolbarRoot, gutters: classes.toolbarGutters }}
    >
      <div className="flex justify-between items-center w-full">
        <h3 className="text-primary-dark text-xl font-bold">{children}</h3>
        <button
          onClick={onClose}
          className="w-8 h-8 rounded-full border flex justify-center items-center border-primary-mid-light"
        >
          <CloseIcon className="text-primary-main w-5 h-5" />
        </button>
      </div>
    </Toolbar>
  )
}

export const DrawerContent = (props) => {
  const { className, open = false, children, ...restProps } = props

  // useEffect(() => {
  //   if (open) {
  //     window.dashly.onReady(function () {
  //       window.dashly.removeChat()
  //     })
  //   } else {
  //     window.dashly?.messenger?.toStateCollapsed()
  //   }
  // }, [open])

  useEffect(() => {
    if (open) {
      if (window.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: true
        })
      }

      window.$crisp?.push(['do', 'chat:hide'])
    } else {
      if (window.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: false
        })
      }
      window.$crisp?.push(['do', 'chat:show'])
    }
  }, [open])

  return (
    <div
      {...restProps}
      className={clsx('px-8 flex-grow overflow-y-auto', className)}
    >
      {children}
    </div>
  )
}

export const DrawerFooter = (props) => {
  const { className, children, ...restProps } = props
  const classes = useStyles()
  return (
    <div {...restProps} className={clsx(classes.saveBtnContainer, className)}>
      {children}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    drawerRoot: {
      zIndex: '999 !important'
    },
    backdropRoot: {
      backgroundColor: 'rgba(0, 0, 0, 0.33)',
      backdropFilter: 'blur(1px)'
    },
    drawerPaper: {
      width: '70vw',
      maxWidth: '600px',
      marginTop: theme.custom.header.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      '&.drawerOpen': {
        boxShadow: theme.custom.drawerPaper.boxShadow,
        overflow: 'visible'
      }
    },
    toolbarRoot: {
      backgroundColor: '#fff',
      height: '40px',
      width: '100%',
      minHeight: '50px',
      marginTop: 16,
      zIndex: theme.zIndex.appBar
    },
    toolbarGutters: {
      padding: '0px 30px'
    },
    saveBtnContainer: {
      width: '100%',
      flexGrow: 0,
      flexShrink: 0,
      // bottom: '0',
      zIndex: '2',
      backgroundColor: '#fff',
      padding: theme.spacing(1.75, 4),
      // marginLeft: theme.spacing(-2),
      borderTop: '1px solid #c6c6c6',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(2)
    }
  })
)

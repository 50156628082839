import { combineReducers } from 'redux'
import generalReducer from 'thunks/generlAppState/reducers'
import { fireToaster } from 'thunks/fireToaster/reducers'

import { loginReducer } from 'thunks/login/reduers'
import meReducer from 'thunks/me/reducers'

import studentReducer from 'thunks/student/reducers'
import contactUsReducer from 'thunks/contactUs/reducers'
import subjectReducer from 'thunks/subject/reducers'
import facultyReducer from 'thunks/faculty/reducers'
import teammateReducer from 'thunks/teammate/reducers'
import topicReducer from 'thunks/topic/reducers'
import examReducer from 'thunks/exam/reducers'
import examCategoryReducer from 'thunks/examCategory/reducers'
import permissionReducer from 'thunks/permission/reducers'
import sectionReducer from 'thunks/section/reducers'


const appReducer = combineReducers({
  generalState: generalReducer,
  appToaster: fireToaster,
  login: loginReducer,
  me: meReducer,
  faculty: facultyReducer,
  student: studentReducer,
  teammate: teammateReducer,
  contactUs: contactUsReducer,
  subject: subjectReducer,
  topic: topicReducer,
  exams: examReducer,
  examCategory: examCategoryReducer,
  permission: permissionReducer,
  section: sectionReducer
  // user: userReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localStorage.removeItem('authToken')

    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer

import React, { useState, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment
} from '@material-ui/core'

import Select from 'react-select'
import userRoles from 'utils/userRoles'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  LoadingButton,
  ToggleSwitch,
  Pill,
  CustomTooltip
} from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import SubjectStyles from './SubjectStyles'

import { addTopics } from 'thunks/topic/actions'

const AddNewSubject = (props) => {
  const { open, onClose } = props
  const classes = SubjectStyles()

  const [loading, setLoading] = useState(false)
  const { subjectId } = useParams()

  const dispatch = useDispatch()
  const [offering, setOffering] = useState({
    code: '',
    name: ''
  })

  // const [toggleSwitch, setToggleSwitch] = useState(false)

  const [topicData, setTopicData] = useState({
    isActive: false,
    topics: []
  })

  // const formInputHandler = (e) => {
  //   const { value, name } = e.target
  //   setTopicData((prevState) => ({ ...prevState, [name]: value }))
  // }

  const handleButtonClick = () => {
    let data = {
      ...topicData,
      subject: subjectId,
      isActive: true
    }

    setTopicData(data)
    setLoading(true)

    data.topics.map((topic, index) => {
      var finalTopic = {
        ...topic,
        order: index + 1,
        subject: data.subject
      }
      dispatch(
        addTopics(finalTopic, (res, err) => {
          addTopicsCallback(res, err)
        })
      )
    })
  }

  const addTopicsCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      let data = {
        code: '',
        name: '',
        isActive: false
      }
      setTopicData(data)
      // setToggleSwitch(false)

      dispatch(fireSuccessToaster('Subject added successfully!'))
      closeSidePanel()
    }
  }

  const closeSidePanel = () => {
    onClose()
    setTopicData({
      code: '',
      name: '',
      isActive: false
    })

    // setToggleSwitch(false)
  }

  const handleOffering = (e, index) => {
    setTopicData((prev) => ({
      ...prev,
      topics: Object.assign([], topicData.topics, {
        [index]: e.target.value
      })
    }))
  }

  var randomNumber = Math.floor(1000 + Math.random() * 9000)

  const handleOfferingChange = (e) => {
    setOffering({
      code: `EHT-${randomNumber}`,
      name: e.target.value,
      isActive: true
    })
  }

  const handleAddOffering = (e) => {
    e.preventDefault()

    if (topicData.topics.length >= 30) {
      dispatch(fireErrorToaster('You can add maximum 30 topics'))
      return
    }

    setTopicData((prev) => ({
      ...prev,
      topics: [...prev.topics, offering]
    }))

    setOffering({ code: '', name: '' })
  }

  const handleDeleteOfferings = (value) => {
    const arr = topicData.topics.filter((item, index) => index !== value)
    setTopicData((prev) => ({
      ...prev,
      topics: arr
    }))
  }

  return (
    <>
      <CustomDrawer
        onClose={closeSidePanel}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={closeSidePanel}>
          Add Topics{' '}
          <span className="font-normal ml-1 text-xs">(Maximum 30)</span>
          <span className="font-normal ml-1 text-xs">
            (left: {30 - (topicData?.topics?.length ?? 0)})
          </span>
        </DrawerHeader>
        <DrawerContent open={open}>
          <div>
            <form onSubmit={handleAddOffering}>
              <div className="flex items-center justify-between gap-2">
                <TextField
                  name="topicName"
                  value={offering.name}
                  onChange={handleOfferingChange}
                  className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                  placeholder="Enter topic name"
                  id="topicName"
                />
                <Button
                  type="submit"
                  disabled={offering.name.trim().length === 0}
                  size="small"
                >
                  Add
                </Button>
              </div>
            </form>
          </div>

          <div className="mt-3">
            {topicData.topics?.map((item, index) => (
              <li className={'flex items-center py-2 '} key={index}>
                <span className="mr-2 text-sm">{index + 1}.</span>
                <div className="flex items-center">
                  <div>
                    <span className="block text-sm font-medium">
                      {item.name}
                    </span>
                  </div>
                  <div className="ml-4">
                    <Pill variant="blue">{item.code}</Pill>
                  </div>
                </div>
                <div className="ml-auto leading-3 flex space-x-4">
                  <CustomTooltip title="Remove" placement="top">
                    <button
                      className="text-custom-red-main opacity-60 hover:opacity-100"
                      onClick={() => handleDeleteOfferings(index)}
                      data-id={index}
                    >
                      <TrashIcon />
                    </button>
                  </CustomTooltip>
                </div>
              </li>
            ))}
          </div>
        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button
            variant="outlined"
            onClick={closeSidePanel}
            className="w-full"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleButtonClick}
            className="w-full"
          >
            Add
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(AddNewSubject)

import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Button } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'

import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { updateUserAcces } from 'thunks/generlAppState/actions'
import SectionHeading from 'global/globalComponents/SectionHeading'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation
} from 'react-router'

import { CustomTabs } from 'global/globalComponents'

import ActiveSubject from './ActiveSubject'
import InactiveSubject from './InactiveSubject'
import AddNewSubject from './AddNewSubject'
import { useDispatch, useSelector } from 'react-redux'

import { fetchSubject } from 'thunks/subject/actions'

import { fetchAllExam } from 'thunks/exam/actions'


const Subject = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const { data: me } = useSelector((state) => state.me)
  const permission = useSelector((state) => state.permission.data)[0]

  const examData = useSelector((state) => state.exams)


  const [selectedTab, setSelectedTab] = useState(pathname)
  const [subjectAddPanel, setSubjectAddPanel] = useState(false)

  var subject = useSelector((state) => state.subject)

  const tabsData = useMemo(() => {
    const arr = [
      { label: 'Subject', value: path + '/all' },
      { label: 'Inactive Subject', value: path + '/inactive' }
    ]
    return arr
  }, [me])

  useEffect(() => {
    if (!examData.fetched) {
      dispatch(fetchAllExam())
    }

  }, [examData])

  useEffect(() => {
    if (!subject.fetched) {
      dispatch(fetchSubject())
    }
  }, [subject.fetched])

  // useEffect(() => {
  //   dispatch(
  //     clearAndReplaceBreadCumbs([
  //       {
  //         name: 'Subject',
  //         link: `${getUserPath(me?.role)}/dashboard`,
  //         index: 0
  //       }
  //     ])
  //   )
  // }, [me])

  useEffect(() => {
    dispatch(updateUserAcces(me.role))
  }, [])

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  const handleTabChange = useCallback((newVal) => {
    setSelectedTab(newVal)
    history.push(newVal)
  }, [])

  const handleClose = () => {
    setSubjectAddPanel(false)
  }

  const handleSidePanel = () => {
    setSubjectAddPanel(true)
  }

  return (
    <OuterContentWrapper>
      <div className="flex justify-between items-center mb-9">
        <SectionHeading>Subject</SectionHeading>
        {permission?.subject?.create && (
          <Button
            variant="outlined"
            startIcon={<AddIcon fontSize="small" />}
            onClick={handleSidePanel}
          >
            New Subject
          </Button>
        )}
      </div>
      <div className="flex justify-between items-center mb-9 ">
        <div className="w-full">
          <CustomTabs
            tabsData={tabsData}
            value={selectedTab}
            onChange={handleTabChange}
          />
        </div>
      </div>

      <Switch>
        <Route exact path={path + '/inactive'}>
          <InactiveSubject />
        </Route>

        <Route path={path}>
          <ActiveSubject />
        </Route>
      </Switch>

      <AddNewSubject open={subjectAddPanel} onClose={handleClose} />
    </OuterContentWrapper>
  )
}

export default Subject

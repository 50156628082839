/**
 * Converts a string into hsl color string. preferred s=30, l=80
 * @param {String} str Text String
 * @param {Number} s saturation number
 * @param {Number} l Light number
 * @returns {String} HSL color value
 */
function stringToHslColor(str, s = 51, l = 60) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  var h = hash % 360
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
}

export default stringToHslColor

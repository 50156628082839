import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover
} from '@material-ui/core'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
import { Link, useHistory } from 'react-router-dom'
import { FullscreenRounded, SettingsOutlined } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSideDrawer } from 'thunks/generlAppState/actions'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import React, { useState } from 'react'
import { LOGIN } from 'thunks/login/reduers'

import { PersonOutline, PowerSettingsNew, Settings } from '@material-ui/icons'

import examhub_logo from '../../static/images/examhub_logo.png'

import examhub_name_logo from '../../static/images/logo1.png'

import userRoles, { getUserPath } from 'utils/userRoles'

const companyLogo =
  'https://demo.jsnorm.com/react/strikingdash/static/media/Logo_Dark.9ef25a33.svg'

const profileDropDownItems = [
  // {
  //   name: 'My Profile',
  //   icon: <PersonOutline fontSize="small" />
  // },
  {
    name: 'Settings',
    icon: <Settings fontSize="small" />
  },
  {
    name: 'Logout',
    icon: <PowerSettingsNew fontSize="small" />
  }
]
const Header = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const meData = useSelector((state) => state.me.data)

  const handleToggleSideDrawer = () => {
    dispatch(toggleSideDrawer())
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [profileMenuOpened, setProfileMenuOpened] = useState(false)
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null)

  const handleLogout = () => {
    localStorage.removeItem('authToken')
    handleCloseProfileMenu()
    dispatch({ type: LOGIN.LOGOUT })
  }

  const handleCloseProfileMenu = () => {
    setProfileMenuOpened(false)
    setProfileMenuAnchor(null)
  }

  const handleOpenProfileMenu = (e) => {
    setProfileMenuAnchor(e.currentTarget)
    setProfileMenuOpened((oldState) => !oldState)
  }

  const goToSettings = () => {
    history.push('/admin/settings/general')
    // localStorage.removeItem('authToken')
    // handleCloseProfileMenu()
    // dispatch({ type: LOGIN.LOGOUT })
  }
  const dropDownfunctions = [goToSettings, handleLogout]
  return (
    <div className={classes.haderContainer}>
      <div className="flex items-center gap-1 ml-2">
        <IconButton onClick={handleToggleSideDrawer}>
          <MenuRoundedIcon />
        </IconButton>
        <Link className="w-32 flex items-center justify-center">
          {/* <img src={examhub_logo} alt="Logo" className="w-28 h-auto" /> */}
          <img
            style={{
              background: '#0066cc',
              borderRadius: '20px',
              width: '150px',
              height: '40px'
            }}
            src={examhub_name_logo}
            alt="logo"
          />
        </Link>
      </div>
      <div className="flex items-center gap-2 mr-6">
        <IconButton>
          <SettingsOutlined onClick={goToSettings} />
        </IconButton>
        <IconButton>
          <FullscreenRounded />
        </IconButton>
        <ColoredAvatars
          onClick={handleOpenProfileMenu}
          className={classes.profileIcon}
          src={
            meData?.profileImage ? `${meData?.profileImage}?${Date.now()}` : ''
          }
          // onClick={handleClick}
        >
          {(meData?.name ?? 'U').charAt(0).toUpperCase()}
        </ColoredAvatars>

        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={profileMenuOpened}
          anchorEl={profileMenuAnchor}
          onClose={handleCloseProfileMenu}
        >
          <List disablePadding dense>
            {profileDropDownItems.map((item, index) => (
              <ListItem
                onClick={dropDownfunctions[index]} // classes={{ gutters: classes.listItem }}
                button
              >
                <div className="flex items-center py-1">
                  {item.icon && (
                    <span
                      classes={{ root: classes.listItemIconRoot }}
                      className="mr-2 text-primary-main"
                      style={
                        item?.name?.includes('Logout') ? { color: 'red' } : {}
                      }
                    >
                      {item.icon}
                    </span>
                  )}
                  <ListItemText
                    primary={<p className="text-sm capitalize">{item.name}</p>}
                    classes={{ root: classes.listItemText }}
                    style={
                      item?.name?.includes('Logout') ? { color: 'red' } : {}
                    }
                  />
                </div>
              </ListItem>
              // <ListItem onClick={dropDownfunctions[index]} button>
              //   <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
              //     {item.icon}
              //   </ListItemIcon>
              //   <ListItemText
              //     classes={{ root: classes.listTextItemRoot }}
              //     primary={item.name}
              //   />
              // </ListItem>
            ))}
          </List>
        </Popover>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  haderContainer: {
    width: '100vw',
    height: theme.custom.header.height,
    display: 'flex',
    padding: theme.spacing(0, 2),
    boxShadow: 'rgb(146 153 184 / 6%) 0px 2px 30px',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    left: 0,
    'z-index': 99,
    background: '#fff'
  },
  avatarRoot: {
    width: 32,
    height: 32,
    cursor: 'pointer'
  },
  listItemIconRoot: {
    minWidth: 32
  },
  listTextItemRoot: {
    '&>span': {
      whiteSpace: 'nowrap'
    }
  }
}))

export default Header

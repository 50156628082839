import axios from 'axios'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import { getAuthToken } from 'utils'

import { errorMessages } from 'utils'

import { SUBJECT } from './reducers'


export const addSubject = (data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: Subject.LOADING })
    try {
      const res = await axios({
        method: 'POST',
        url: '/subject',
        data,
        // headers: {
        //   Authorization: getAuthToken()
        // }
      })
      dispatch({ type: SUBJECT.ADD_ONE_SUBJECT, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SUBJECT.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchSubject = (callback) => {
  return async (dispatch) => {
    // dispatch({ type: Subject.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/subject',
        // headers: {
        //   Authorization: getAuthToken()
        // }
      })
      dispatch({ type: SUBJECT.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SUBJECT.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


/**
 * update Teamamte isACtive status. 
 * @param {id} id Teammate id
 * @param {Object} data isACtive
 * @param {Function} callback callback function
 */
export const updateSubject = (id, data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: Subject.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/subject/${id}`,
        data
      })
      dispatch({ type: SUBJECT.UPDATE_ONE_SUBJECT, payload: res.data })
      if (callback) callback(res, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SUBJECT.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


/**
 * Delete one subject of a user.
 * @param {String} subject subject Id
 * @param {Function} callback  callback function
 */
export const deleteSubject = (subjectId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/subject/${subjectId}`
      })
      if (callback) callback(res.data, false)

      dispatch({ type: SUBJECT.DELETE_ONE_SUBJECT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SUBJECT.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


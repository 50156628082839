import React, { useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  // Popover,
  makeStyles
  // Popper,
  // ClickAwayListener
} from '@material-ui/core'
import clsx from 'clsx'
import CustomPopper from '../CustomPopper/CustomPopper'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'

const PopupMenu = ({ menuItems, targetComp, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  // const arrowRef = useRef();
  // const [arrowRef, setArrowRef] = useState(null)

  const handleOpenMenu = (e) => {
    e.stopPropagation()
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      if (onClick) onClick()
      setAnchorEl(e.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {!targetComp ? (
        <button onClick={handleOpenMenu}>
          <ThreeDotsIcon className="w-5 h-5" />
        </button>
      ) : (
        <>{React.cloneElement(targetComp, { onClick: handleOpenMenu })}</>
      )}

      <CustomPopper anchorEl={anchorEl} onClose={handleCloseMenu}>
        <div className={clsx('bg-white', classes.menuPaper)}>
          <List className={classes.list}>
            {menuItems.map((item, index) => (
              <SingleListItem
                key={index}
                item={item}
                index={index}
                handleCloseMenu={handleCloseMenu}
                classes={classes}
              />
            ))}
          </List>
        </div>
      </CustomPopper>
    </>
  )
}

const SingleListItem = ({ item, index, handleCloseMenu, classes }) => {
  const handleClick = (e) => {
    handleCloseMenu()
    item.method(e, item)
  }

  return (
    <ListItem
      onClick={handleClick}
      // classes={{ gutters: classes.listItem }}
      button
    >
      <div className="flex items-center py-1">
        {item.icon && (
          <span className="mr-2 text-primary-main">{item.icon}</span>
        )}
        <ListItemText
          primary={<p className="text-sm capitalize">{item.label}</p>}
          classes={{ root: classes.listItemText }}
          style={item?.label?.includes('Delete') ? { color: 'red' } : {}}
        />
      </div>
    </ListItem>
  )
}

const useStyles = makeStyles({
  menuPaper: {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    maxHeight: 350,
    overflow: 'auto'
  },

  list: {
    padding: '4px 0'
  },

  listItem: {
    padding: '0.5rem 1.5rem',
    minWidth: 115
  },

  listItemText: {
    margin: 0
  },
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '0 100%'
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '100% 0'
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '100% 100%'
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '0 0'
      }
    }
  },
  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    color: '#fff',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: '0 0 1px rgba(0, 0, 0, 0.20)',
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)'
    }
  }
})

export default React.memo(PopupMenu)

export const GENERAL_APP_STATE = {
  TOGGLE_SIDE_DRAWER: 'TOGGLE_SIDE_DRAWER',
  TOGGLE_SIDE_BAR: 'TOGGLE_SIDE_BAR',
  SHOW_INTEGRATED_HEADER: 'SHOW_INTEGRATED_HEADER',
  SHOW_NORMAL_HEADER: 'SHOW_NORMAL_HEADER F',
  UPDATE_USER_ACCESS: 'UPDATE_USER_ACCESS',
  TOGGLE_SIDE_PANEL: 'TOGGLE_SIDE_PANEL',
  SHOW_SIDEDRAWER: 'SHOW_SIDEDRAWER',
  SET_TEAMMATE_ROLE: 'SET_TEAMMATE_ROLE',
  SET_EXPAND_STATE: 'SET_EXPAND_STATE',
  OPEN_REMOVED_FROM_WS_MODAL: 'OPEN_REMOVED_FROM_WS_MODAL',
  TOGGLE_UPGRADE_PLAN_MODAL: 'TOGGLE_UPGRADE_PLAN_MODAL',
  TOGGLE_CREATE_INVOICE_MODAL: 'TOGGLE_CREATE_INVOICE_MODAL',
  TOGGLE_DOCUMENT_PREVIEW: 'TOGGLE_DOCUMENT_PREVIEW',
  TOGGLE_HEADER_SHADOW: 'TOGGLE_HEADER_SHADOW',
  SET_HEADER_SEARCH: 'HEADER_SET_HEADER_SEARCH',
  SET_BOARD_SEARCH: 'HEADER_SET_BOARD_SEARCH',
  TOGGLE_MODAL: 'TOGGLE_MODAL'
}

const initialState = {
  sideDrawerOpen: true,
  sidePanel: {
    addFaculty: {
      open: false,
      data: {}
    }
  }
}

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERAL_APP_STATE.TOGGLE_SIDE_DRAWER:
      return {
        ...state,
        sideDrawerOpen: !state.sideDrawerOpen
      }

    case GENERAL_APP_STATE.TOGGLE_SIDE_PANEL:
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export default generalReducer

import React from 'react'
import clsx from 'clsx'

const OuterContentWrapper = (props) => {
  const { children, className, ...restProps } = props
  return (
    <div
      // className="m-4 md:m-4 lg:m-6 p-4 md:px-9 md:py-4 lg:px-8 lg:py-2 rounded"
      className={clsx('p-10', className)}
      {...restProps}
    >
      {children}
    </div>
  )
}

export default OuterContentWrapper

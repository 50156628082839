export const LOGIN = {
  LOADING: 'LOGIN_LOADING',
  LOGGED_IN: 'LOGGED_IN',
  ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT'
}

const initialState = {
  loading: false,
  loggedIn: false,
  error: false
}

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.LOADING:
      return { ...state, loading: true, error: false }
    case LOGIN.LOGGED_IN:
      return { ...state, loggedIn: true, loading: false }
    case LOGIN.LOGOUT:
      return { ...state, loggedIn: false }
    case LOGIN.ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}

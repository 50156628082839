import { ToggleSwitch } from 'global/globalComponents'
import React, { useEffect, useState } from 'react'

import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { errorMessages } from 'utils'
import { PopupMenu } from 'global/globalComponents'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { useHistory } from 'react-router-dom'

import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'

import { updateStudent, deleteStudent } from 'thunks/student/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import StudentEditPanel from './StudentEditPanel'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import userRoles, { getUserPath } from 'utils/userRoles'

const ActiveStudent = () => {
  const dispatch = useDispatch()

  var userStudent = useSelector((state) => state.student)
  const permission = useSelector((state) => state.permission.data)[0]

  const [activeStudentData, setActiveStudentData] = useState([])

  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])

  const [studentData, setStudentData] = useState({
    open: false,
    data: {},
    close: true
  })

  const [deleteStudentData, setDeleteStudentData] = useState({
    open: false,
    data: {},
    loading: false
  })

  useEffect(() => {
    setActiveStudentData(userStudent.data.filter((item) => item.isActive))
  }, [userStudent])

  const handleEditPanelOpen = (item) => {
    if (studentData.open) {
      setStudentData({
        open: false,
        data: {}
      })
    } else {
      setStudentData({
        open: true,
        data: item
      })
    }
  }

  const handleSwitch = (item) => {
    setToggleSwitchLoading((prevState) => [...prevState, item._id])
    dispatch(
      updateStudent(item._id, { isActive: !item.isActive }, switchCallback)
    )
  }

  const switchCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      setToggleSwitchLoading((prevState) =>
        prevState.filter((item) => res._id !== item)
      )
    }
  }

  const handleDeleteStudent = (student) => {
    if (deleteStudentData.open) {
      setDeleteStudentData({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeleteStudentData({
        open: true,
        data: student,
        loading: false
      })
    }
  }

  const handleEditPanelClose = () => {
    setStudentData({ open: false, data: {} })
  }

  const handleStudentData = () => {
    setDeleteStudentData((prev) => ({ ...prev, loading: true }))
    dispatch(deleteStudent(deleteStudentData.data._id, deleteStudentCallback))
  }

  const deleteStudentCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setDeleteStudentData((prev) => ({
        ...prev,
        loading: false
      }))
    } else {
      setDeleteStudentData({
        open: false,
        data: {},
        loading: false
      })
      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }

  return (
    <div>
      <TableContainer mb={9}>
        <Thead>
          <Th className="w-44 whitespace-nowrap">Name</Th>
          <Th className="text-center">Status</Th>

          <Th className="text-center">Last Active</Th>

          {(permission?.faculty.update || permission?.faculty?.delete) && (
            <Th className="whitespace-nowrap text-center">More Options</Th>
          )}
        </Thead>

        <Tbody>
          {userStudent.loading ? (
            <TableDataLoading cols={5} />
          ) : activeStudentData.length > 0 ? (
            activeStudentData.map((item, index) => (
              <Row
                key={index}
                item={item}
                handleEditPanelOpen={handleEditPanelOpen}
                handleDeleteStudent={handleDeleteStudent}
                handleSwitch={handleSwitch}
                toggleSwitchLoading={toggleSwitchLoading}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
              >
                There are no Active Student
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>

      <StudentEditPanel
        open={studentData.open}
        onClose={handleEditPanelClose}
        data={studentData.data}
      />
      <DeleteModal
        warningText="All the information of this student will be deleted. Do you want to proceed ?"
        open={deleteStudentData.open}
        handleDialog={handleDeleteStudent}
        handleDeleteAction={handleStudentData}
        loading={deleteStudentData.loading}
      />
    </div>
  )
}

const Row = ({
  item,
  handleEditPanelOpen,
  handleDeleteStudent,
  handleSwitch,
  toggleSwitchLoading
}) => {
  const history = useHistory()
  const meData = useSelector((state) => state.me.data)

  const OpenSingleClientPage = (e) => {
    history.push(`${getUserPath(meData.role)}/subject/topic/${item._id}`)

    // history.push('/admin/subject/topic/' + item._id)
  }

  const openEditStudentPanel = () => handleEditPanelOpen(item)
  const handleDelete = () => handleDeleteStudent(item)

  return (
    <Tr key={item._id}>
      <Td
        // onClick={OpenSingleClientPage} //OpenSingleClientPage
        className="whitespace-nowrap text-left cursor-pointer"
      >
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.name}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.email}</span>
        </div>
      </Td>
      {/* <Td className="whitespace-nowrap text-left">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.title}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.title}</span>
        </div>
      </Td> */}

      <Td className="whitespace-nowrap text-left">
        <ToggleSwitch
          style={{ margin: '0' }}
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>
      <Td className="whitespace-nowrap text-center">
        <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          <svg
            className="mr-1 w-3 h-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {item.updatedAt ? format(new Date(item.updatedAt), 'p') : '-'}
        </span>

        {/* <span className="text-primary-mid-dark-2 font-medium text-sm">
          Student
        </span> */}
      </Td>
      <Td className=" whitespace-nowrap text-center">
        <PopupMenu
          menuItems={[
            {
              label: 'Edit',
              method: openEditStudentPanel,
              icon: <EditIcon className="text-primary-main" />
            },
            {
              label: 'Settings',
              method: OpenSingleClientPage,
              icon: <SettingIcon className="text-primary-main" />
            },
            {
              label: 'Delete',
              method: handleDelete,
              icon: <DeleteIcon className="text-error-main" />
            }
          ]}
        />
      </Td>
    </Tr>
  )
}
export default ActiveStudent

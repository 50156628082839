import React from 'react'

const Wrapper = ({ className, children }) => {
  return (
    <div
      className={`w-full bg-white shadow-lg rounded-xl shadow px-8 py-8 ${className}`}
    >
      {children}
    </div>
  )
}

export default Wrapper

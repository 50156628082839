import React, { useEffect, useState } from 'react'

import { Link, useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import userRoles from 'utils/userRoles'

import { userSignup } from 'thunks/signup'
import { storeToken } from 'utils/userLogin'

import useTitle from 'customHooks/useTitle'
import AgencySignupStep1 from './AgencySignupStep1'
import AgencySignupStep2 from './AgencySignupStep2'

import background from '../../../static/images/background2.svg'

import './FacultyRegistrationLogin.css'

import examhub_logo from '../../../static/images/examhub_logo.png'

const AgencySignup = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  // const params = useParams()
  const location = useLocation()
  // const [open, setOpen] = useState(false)
  const [response, setResponse] = useState({})
  const inviteToken = props.match?.params?.inviteToken ?? false
  const [activeTab, setActiveTab] = useState(true)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  // const [userDomainStatus, setUserDomainStatus] = useState({
  //   message: undefined,
  //   loading: true,
  //   available: undefined
  // })
  // const [waitingModalStaus, setWaitingModalStatus] = useState({
  //   open: false,
  //   time: null
  // })
  const [loading, setLoading] = useState(false)
  const [isValid, setIsvalid] = useState(true)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    designation: '',
    password: '',
    role: userRoles.USER_FACULTY
  })
  useTitle('The Exam HUB', examhub_logo)

  // const modalCloseHandler = () => {
  //   setOpen(false)
  // }

  const userSignupHandler = (e) => {
    e.preventDefault()

    if (formData.password !== confirmPassword) {
      setLoading(false)
      return dispatch(
        fireErrorToaster('Password does not match with confirm password')
      )
    }

    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    setLoading(true)
    dispatch(
      userSignup(
        {
          ...formData,
          source: window.location.href
        },
        signupCallback
      )
    )
  }

  const signupCallback = (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      setLoading(false)
      // setResponse({
      //   path: res?.path,
      //   token: res?.login?.token,
      //   id: res?.profile?._id,
      //   designation: res?.profile?.designation
      // })

      storeToken(res.login.token)
      setLoading(true)
      window.open(`${window.location.origin}${res.path}`, '_self')
      dispatch(fireSuccessToaster('Signup successful'))

      history.push(res.login.path)
    }
  }

  const formInputHandler = (e) => {
    const { name, value } = e.target

    console.log(name, value, '#####################')
    setFormData((oldState) => ({ ...oldState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  useEffect(() => {
    const reg = /\S+@\S+\.\S+/
    if (
      reg.test(formData.email) &&
      formData.name.length !== 0
      // &&
      // formData.phoneNumber.length !== 0
    ) {
      setIsvalid(false)
    } else {
      setIsvalid(true)
    }
  }, [formData])

  // useEffect(() => {
  //   if (
  //     !params &&
  //     window.location.href !== 'https://app.clientvenue.com/signup'
  //   )
  //     window.open('https://app.clientvenue.com/login', '_self')
  // }, [window.location])

  return (
    <div className="login">
      <header className="header">
        <Link to={'/login'} className="text-primary">
          <img
            style={{ height: '24px', width: '24px' }}
            src={examhub_logo}
            alt=""
          />{' '}
          <span>The Exam HUB</span>
        </Link>
      </header>
      <div className="login-container">
        <div className="login-hero">
          <img src={background} alt="background" />
        </div>
        <div className="login-content">
          <h2 className="login-welcome">Welcome to The Exam HUB! 👋</h2>
          <p>Create a Faculty Account</p>

          <div elevation={0}>
            <form onSubmit={userSignupHandler}>
              {activeTab ? (
                <AgencySignupStep1
                  formData={formData}
                  formInputHandler={formInputHandler}
                  inviteToken={inviteToken}
                  isValid={isValid}
                  setActiveTab={setActiveTab}
                />
              ) : (
                <AgencySignupStep2
                  formInputHandler={formInputHandler}
                  validPasswordHandler={validPasswordHandler}
                  passwordValid={passwordValid}
                  formData={formData}
                  setActiveTab={setActiveTab}
                  loading={loading}
                  location={location}
                  confirmPassword={confirmPassword}
                  setConfirmPassword={setConfirmPassword}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgencySignup

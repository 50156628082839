import { ToggleSwitch } from 'global/globalComponents'
import React, { useEffect, useState } from 'react'

import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { errorMessages } from 'utils'
import { PopupMenu } from 'global/globalComponents'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { useHistory } from 'react-router-dom'

import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'

import { updateTeammate, deleteTeammate } from 'thunks/teammate/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import TeammateEditPanel from './TeammateEditPanel'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import userRoles, { getUserPath } from 'utils/userRoles'

const ActiveTeammates = () => {
  const dispatch = useDispatch()
  var userTeammate = useSelector((state) => state.teammate)

  const [activeTeammateData, setActiveTeammateData] = useState([])

  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])

  const [teammateData, setTeammateData] = useState({
    open: false,
    data: {},
    close: true
  })

  const [deleteTeammateData, setDeleteTeammateData] = useState({
    open: false,
    data: {},
    loading: false
  })

  useEffect(() => {
    setActiveTeammateData(userTeammate.data.filter((item) => item.isActive))
  }, [userTeammate])

  const handleEditPanelOpen = (item) => {
    if (teammateData.open) {
      setTeammateData({
        open: false,
        data: {}
      })
    } else {
      setTeammateData({
        open: true,
        data: item
      })
    }
  }

  const handleSwitch = (item) => {
    setToggleSwitchLoading((prevState) => [...prevState, item._id])
    dispatch(
      updateTeammate(item._id, { isActive: !item.isActive }, switchCallback)
    )
  }
  const switchCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      setToggleSwitchLoading((prevState) =>
        prevState.filter((item) => res._id !== item)
      )
    }
  }

  const handleDeleteTeammate = (teammate) => {
    if (deleteTeammateData.open) {
      setDeleteTeammateData({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeleteTeammateData({
        open: true,
        data: teammate,
        loading: false
      })
    }
  }

  const handleEditPanelClose = () => {
    setTeammateData({ open: false, data: {} })
  }

  const handleTeammateData = () => {
    setDeleteTeammateData((prev) => ({ ...prev, loading: true }))
    dispatch(deleteTeammate(deleteTeammateData.data._id, deleteTeamateCallback))
  }

  const deleteTeamateCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setDeleteTeammateData((prev) => ({
        ...prev,
        loading: false
      }))
    } else {
      setDeleteTeammateData({
        open: false,
        data: {},
        loading: false
      })
      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }

  return (
    <div>
      <TableContainer mb={9}>
        <Thead>
          <Th className="w-44 whitespace-nowrap">Name</Th>
          {/* <span className="text-primary-mid-dark-2 font-medium text-sm">
          Teammate
        </span> */}{' '}
          {/* <Th className="w-44 whitespace-nowrap">Title</Th> */}
          <Th className="text-center">Status</Th>
          <Th className="text-center">Last Login</Th>
          <Th className="whitespace-nowrap text-center">More Options</Th>
        </Thead>

        <Tbody>
          {userTeammate.loading ? (
            <TableDataLoading cols={5} />
          ) : activeTeammateData.length > 0 ? (
            activeTeammateData.map((item, index) => (
              <Row
                key={index}
                item={item}
                handleEditPanelOpen={handleEditPanelOpen}
                handleDeleteTeammate={handleDeleteTeammate}
                handleSwitch={handleSwitch}
                toggleSwitchLoading={toggleSwitchLoading}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
              >
                There are no Active Teammate
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>

      <TeammateEditPanel
        open={teammateData.open}
        onClose={handleEditPanelClose}
        data={teammateData.data}
      />
      <DeleteModal
        warningText="Teammate will be deleted. Do you want to proceed ?"
        open={deleteTeammateData.open}
        handleDialog={handleDeleteTeammate}
        handleDeleteAction={handleTeammateData}
        loading={deleteTeammateData.loading}
      />
    </div>
  )
}

const Row = ({
  item,
  handleEditPanelOpen,
  handleDeleteTeammate,
  handleSwitch,
  toggleSwitchLoading
}) => {
  const history = useHistory()
  const meData = useSelector((state) => state.me.data)

  const OpenSingleClientPage = (e) => {
    history.push(`${getUserPath(meData.role)}/dashboard`)
    // history.push('/admin/dashboard')
  }

  const openEditTeammatePanel = () => handleEditPanelOpen(item)
  const handleDelete = () => handleDeleteTeammate(item)

  return (
    <Tr key={item._id}>
      <Td
        onClick={null} //OpenSingleClientPage
        className="whitespace-nowrap text-left cursor-pointer"
      >
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.name}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.email}</span>
        </div>
      </Td>
      {/* <Td className="whitespace-nowrap text-left">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.title}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.title}</span>
        </div>
      </Td> */}

      <Td className="whitespace-nowrap text-left">
        <ToggleSwitch
          style={{ margin: '0' }}
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>
      <Td className="whitespace-nowrap text-center">
        {item.lastLogin ? (
          <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
            <svg
              className="mr-1 w-3 h-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                clip-rule="evenodd"
              ></path>
            </svg>
            {item.lastLogin ? format(new Date(item.lastLogin), 'd MMM p') : '-'}
          </span>
        ) : (
          '-'
        )}
      </Td>
      <Td className=" whitespace-nowrap text-center">
        <PopupMenu
          menuItems={[
            {
              label: 'Edit',
              method: openEditTeammatePanel,
              icon: <EditIcon className="text-primary-main" />
            },
            {
              label: 'Settings',
              method: OpenSingleClientPage,
              icon: <SettingIcon className="text-primary-main" />
            },
            {
              label: 'Delete',
              method: handleDelete,
              icon: <DeleteIcon className="text-error-main" />
            }
          ]}
        />
      </Td>
    </Tr>
  )
}

export default ActiveTeammates

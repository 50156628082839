import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Button } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { KeyboardArrowDown } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
// import AddNewClient from './AddNewClient'

// import { getClient } from '../../thunks/addClient/actions'
// import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
// import { fetchClientRaisedRequests } from 'thunks/clientRaisedRequestAgency/actions'

import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { updateUserAcces } from 'thunks/generlAppState/actions'
import SectionHeading from 'global/globalComponents/SectionHeading'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation
} from 'react-router'
import { clearAndReplaceBreadCumbs } from 'thunks/breadcrumbs/action'
import userRoles, { getUserPath } from 'utils/userRoles'

import { CustomTabs } from 'global/globalComponents'

import ActiveFaculty from './ActiveFaculty'
import InactiveFaculty from './InactiveFaculty'
import RejectedFaculty from './RejectedFaculty'
import RequestedFaculty from './FacultyRequests'
import BlockedFaculty from './BlockedFaculty'

import { fetchFaculty } from 'thunks/faculty/actions'

// import ClientCompany from '../ClientCompanyComponents/ClientCompany'
// import AddNewCompany from '../ClientCompanyComponents/AddNewCompany'
// import PopupMenu from 'global/globalComponents/PopupMenu/PopupMenu'
import AddNewFaculty from './AddNewFaculty'

const Faculty = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  // const access = useSelector((state) => state.permission.data?.access)
  const { data: me } = useSelector((state) => state.me)
  const [selectedTab, setSelectedTab] = useState(pathname)
  // const addClientSidePanelStatus = useSelector(
  //   (state) => state.generalAppState.sidePanel.addClient
  // )
  // const [clientCompany, setClientCompany] = useState({
  //   open: false
  // })

  var userFaculty = useSelector((state) => state.faculty)

  const [facultyAddPanel, setFacultyAddPanel] = useState(false)

  const tabsData = useMemo(() => {
    const arr = [
      { label: 'Faculty', value: path + '/all' },
      { label: 'Inactive Faculty', value: path + '/inactive' },
      { label: 'Request Faculty', value: path + '/request' },
      { label: 'Rejected Faculty', value: path + '/reject' }
      // { label: 'Blocked Faculty', value: path + '/blocked' }
    ]

    // if (
    //   me.role !== userRoles.AGENCY_EDITOR &&
    //   me.role !== userRoles.AGENCY_VIEWER
    // ) {
    //   arr.push({ label: 'Requests', value: path + '/requests' })
    // }

    return arr
  }, [me])

  useEffect(() => {
    if (!userFaculty.fetched) {
      dispatch(fetchFaculty())
    }
  }, [userFaculty.fetched])
  // useEffect(() => {
  //   if (!userClients.fetched) {
  //     dispatch(getClient())
  //   }
  //   if (!fetchedFirstTime) {
  //     dispatch(fetchAllWorkspacesForCurrentUser())
  //   }
  // }, [userClients.fetched])

  // useEffect(() => {
  //   if (!clientRaisedRequests.fetched) {
  //     dispatch(fetchClientRaisedRequests())
  //   }
  // }, [clientRaisedRequests.fetched])

  useEffect(() => {
    dispatch(
      clearAndReplaceBreadCumbs([
        {
          name: 'Faculty',
          link: `${getUserPath(me?.role)}/dashboard`,
          index: 0
        }
      ])
    )
  }, [me])

  useEffect(() => {
    dispatch(updateUserAcces(me.role))
  }, [])

  // useEffect(() => {
  //   return () => {
  //     if (addClientSidePanelStatus) {
  //       dispatch(toggleAddFacultySidePanel(false))
  //     }
  //   }
  // }, [addClientSidePanelStatus])

  // useEffect(() => {
  //   if (clientRaisedRequests.data.length) {
  //     const pendingRequests = clientRaisedRequests.data.filter(
  //       (item) => item.status === 'pending'
  //     )
  //     setPendingRequests(pendingRequests)
  //   }
  // }, [clientRaisedRequests.data])

  // const handleChange = (e) => {
  //   setSearch(e.target.value)
  // }

  // useEffect(() => {
  //   setSearch('')
  // }, [])

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  const handleTabChange = useCallback((newVal) => {
    setSelectedTab(newVal)
    history.push(newVal)
  }, [])

  const handleClose = () => {
    setFacultyAddPanel(false)
  }

  const handleSidePanel = () => {
    // dispatch(toggleAddFacultySidePanel(true))

    setFacultyAddPanel(true)
  }

  return (
    <OuterContentWrapper>
      <div className="flex justify-between items-center mb-9">
        <SectionHeading>Faculty</SectionHeading>
        {/* {access?.clients.create && ( */}
        <Button
          variant="outlined"
          startIcon={<AddIcon fontSize="small" />}
          onClick={handleSidePanel}
        >
          New Faculty
        </Button>
        {/* )} */}
        {/* {access?.clients.create && (
          <PopupMenu
            menuItems={[
              {
                label: 'Add Client',
                method: () =>
                  setFacultyAddPanel({ open: true, clientType: 'indivisual' })
                // method: () => dispatch(toggleAddFacultySidePanel(true))
              }
              {
                label: 'Add Company',
                method: () =>
                  setFacultyAddPanel({ open: true, clientType: 'company' })
              }
            ]}
            targetComp={
              <Button endIcon={<KeyboardArrowDown />}>Client Options</Button>
            }
          />
        )} */}
      </div>
      <div className="flex justify-between items-center mb-9 ">
        <div className="w-full">
          <CustomTabs
            tabsData={tabsData}
            value={selectedTab}
            onChange={handleTabChange}
          />
        </div>
      </div>
      {/* {search !== '' && (
        <p className="text-sm mb-2 ml-1 text-gray-500 font-medium">
          Search results for:{' '}
          <span className="text-primary-dark">{search}</span>
        </p>
      )} */}

      <Switch>
        {/* <Route exact path={path + '/company'}>
          <ClientCompany search={search} />
        </Route> */}

        <Route exact path={path + '/inactive'}>
          <InactiveFaculty />
        </Route>
        <Route exact path={path + '/request'}>
          <RequestedFaculty />
        </Route>
        <Route exact path={path + '/reject'}>
          <RejectedFaculty />
        </Route>

        {/* <Route exact path={path + '/blocked'}>
          <BlockedFaculty />
        </Route> */}

        <Route path={path}>
          <ActiveFaculty />
        </Route>
      </Switch>
      {/* <AddNewCompany open={clientCompany.open} onClose={handleClose} /> */}

      <AddNewFaculty open={facultyAddPanel} onClose={handleClose} />
    </OuterContentWrapper>
  )
}

export default Faculty

import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment
} from '@material-ui/core'

import Select from 'react-select'
import userRoles, { proAdminRoleOptions } from 'utils/userRoles'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import TeammateStyles from './TeammatesStyles'

import { addTeammate } from 'thunks/teammate/actions'

import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'

const AddNewTeammate = (props) => {
  const { open, onClose } = props
  const classes = TeammateStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const meData = useSelector((state) => state.me.data)
  const [roleOptions, setRoleOptions] = useState([])

  const [passwordValid, setPasswordValid] = useState(true)
  const [showPassword, setShowPassword] = useState(false)

  const [toggleSwitch, setToggleSwitch] = useState({
    checked: false,
    disable: false,
    load: false
  })

  const [teammateData, setTeammateData] = useState({
    name: '',
    role: '',
    email: '',
    password: ''
  })

  useEffect(() => {
    setRoleOptions(proAdminRoleOptions)
  }, [meData.role])

  const formInputHandler = (e) => {
    const { value, name } = e.target
    if (name === 'password') {
      setPasswordValid(true)
    }
    if (name == 'phone' && value.length > 10) {
      return
    } else {
      setTeammateData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  const handleButtonClick = (e) => {
    e.preventDefault()

    // ------- Checking if required fields are filled --------
    if (
      !teammateData.name ||
      !teammateData.email ||
      !teammateData.password ||
      !teammateData.role
    ) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }

    if (!validEmailAddress()) {
      return dispatch(fireErrorToaster('please enter valid email address'))
    }

    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    let data = {
      ...teammateData,
      path: '/admin/dashboard'
    }
    setLoading(true)

    dispatch(
      addTeammate(data, (res, err) => {
        addTeammateCallback(res, err)
      })
    )
  }

  const addTeammateCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Teammate added successfully!'))
      closeSidePanel()
    }
  }

  const validEmailAddress = () => {
    let validEmail = /.+@.+\..+/.test(teammateData.email)
    if (!validEmail) {
      dispatch(fireErrorToaster('please enter valid email address'))
      return false
    } else {
      return true
    }
  }

  const validPasswordHandler = () => {
    const password = teammateData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const closeSidePanel = () => {
    onClose()
    setTeammateData({
      name: '',
      role: '',
      email: '',
      phone: '',
      password: '',
      address: {
        country: ''
      },
      agencyRepresentative: null,
      newWorkspace: []
    })

    setToggleSwitch((prev) => ({
      ...prev,
      checked: false,
      disable: false,
      load: false
    }))
  }

  const togglePasswordView = () => {
    setShowPassword((prev) => !prev)
  }
  const handleRoleChange = (e) => {
    setTeammateData((oldState) => ({
      ...oldState,
      role: e.value
    }))
  }

  return (
    <>
      <CustomDrawer
        onClose={closeSidePanel}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader
          className="text-lg font-medium text-gray-900"
          onClose={closeSidePanel}
        >
          Add Teammate
        </DrawerHeader>

        <DrawerContent open={open}>
          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Full Name</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="name"
                value={teammateData.name}
                type="text"
                placeholder="Full Name"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FormLabel required>Email</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              inputProps={{
                autoComplete: 'off'
              }}
              name="email"
              type="email"
              placeholder="Email"
              value={teammateData.email}
              onChange={formInputHandler}
              onBlur={validEmailAddress}
            />
          </Box>

          <Box mt={2}>
            <FormLabel>Role</FormLabel>
            <Select
              className="col-span-4 md:col-span-2 text-sm"
              placeholder="Role"
              isSearchable={false}
              options={roleOptions}
              onChange={handleRoleChange}
              // value={memberData.agencyRepresentative}
              styles={reactSelectCustomStyles}
            />
          </Box>

          <Box mt={2}>
            <FormLabel required>Set Password</FormLabel>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '12px'
              }}
            >
              <TextField
                className={classes.textFieldStyle}
                error={teammateData.password && !passwordValid ? true : false}
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={teammateData.password}
                onChange={formInputHandler}
                onBlur={validPasswordHandler}
                InputProps={{
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={togglePasswordView}>
                        {!showPassword ? (
                          <EyeIcon className="text-base" />
                        ) : (
                          <EyeSlashIcon className="text-base" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            {teammateData.password && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                Password should be a combination of an uppercase/lowercase
                letter, a number, a symbol and length between 8 to 20.
              </p>
            )}
          </Box>
        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button
            variant="outlined"
            onClick={closeSidePanel}
            className="w-full"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleButtonClick}
            className="w-full"
          >
            Add
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(AddNewTeammate)

import React, { useState, useEffect } from 'react'
import { Box, Button, TextField } from '@material-ui/core'

import { updateSubject } from '../../thunks/subject/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  LoadingButton,
  ToggleSwitch,
  CustomTooltip
} from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'

import Select from 'react-select'

import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

import SubjectStyles from './SubjectStyles'


const SubjectEditPanel = (props) => {
  const { open, onClose, data } = props
  const classes = SubjectStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()


  const categoryData = useSelector((state) => state.exams)

  const [categroyOptions, setCategroyOptions] = useState({
    loading: true,
    data: []
  })

  const [subjectData, setSubjectData] = useState({
    name: '',
    code: ''
  })

  useEffect(() => {

    setCategroyOptions((prev) => ({
      ...prev,
      loading: false,
      data: categoryData.data
        .filter((item) => item.isActive === true)
        .map((item) => ({
          label: item.name,
          value: item._id
        }))
    }))

  }, [categoryData])

  useEffect(() => {
    setSubjectData((prev) => ({
      ...prev,
      name: data.name,
      code: data.code,
      exam: data?.exam?.map(item => ({
        label: item.name,
        value: item._id
      }))
    }))
  }, [data])

  const handleCategoryChange = (val) => {
    setSubjectData((prev) => ({ ...prev, exam: val }))
  }

  const formInputHandler = (e) => {
    const { value, name } = e.target

    setSubjectData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleUpdateSubject = () => {
    const filteredDataSet = {
      code: subjectData.code,
      name: subjectData.name,
      isActive: true,
      removedCategory: [],
      newCategory: []
    }

    //Removed exam
    for (let cat of data?.exam) {
      const match = subjectData.exam.find(
        (item) => item.value === cat._id
      )

      if (!match) {
        filteredDataSet.removedCategory.push(cat._id)
      }
    }

    //Added exam
    for (let cat of subjectData.exam) {
      const match = data.exam.find((item) => item._id === cat.value)

      if (!match) {
        filteredDataSet.newCategory.push(cat.value)
      }
    }

    if (!filteredDataSet.name.trim()) {
      dispatch(fireErrorToaster('Invalid name value!'))
      return
    }

    dispatch(updateSubject(data._id, filteredDataSet, updateSubjectCallBack))
  }

  const updateSubjectCallBack = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      setSubjectData({ name: '' })
      dispatch(fireSuccessToaster('Subject added successfully!'))
      onClose()
    }
  }

  return (
    <>
      <CustomDrawer
        onClose={onClose}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={onClose}>Edit Subject</DrawerHeader>
        <DrawerContent open={open}>
          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
          // p={2}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Subject Code</FormLabel>
              <CustomTooltip title="Auto Generated. Can't edit" placement="top">
                <TextField
                  className={classes.textFieldStyle}
                  name="code"
                  value={subjectData.code}
                  type="text"
                  placeholder="Subject Code"
                  disabled={true}
                />
              </CustomTooltip>
            </Box>
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Subject Name</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="name"
                value={subjectData.name}
                type="text"
                placeholder="Subject Name"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          {/* <Box mt={2}>
            <FormLabel required>Exam exam</FormLabel>

            <Select
              styles={reactSelectCustomStyles}
              placeholder="exam"
              options={categroyOptions.data}
              value={subjectData.exam ? subjectData.exam : ''}
              className="w-full"
              name="exam"
              isMulti
              onChange={handleCategoryChange}
              isLoading={categroyOptions.loading}
            />
          </Box> */}
        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button variant="outlined" onClick={onClose} className="w-full">
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleUpdateSubject}
            className="w-full"
          >
            Update Subject
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(SubjectEditPanel)

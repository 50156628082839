const errorMessages = {
  CHANGES_SAVED_MESSAGE: 'Saved successfully',
  CREATED_MESSAGE: 'Created successfully!',
  ERROR_MESSAGE: 'Some error occurred! please try again later',
  NO_DATA: 'No data found!',
  NOT_AUTHORIZED: 'You do not have permission to perform this action',
  PROJECT_COMPLETE:
    'This project is marked as complete, you cannot edit it anymore!',
  LINK_COPIED: 'Link copied!'
}

export default errorMessages

import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment
} from '@material-ui/core'

import Select from 'react-select'
import userRoles from 'utils/userRoles'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import StudentStyles from './StudentStyles'

import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'

import { addStudent } from 'thunks/student/actions'

const AddNewStudent = (props) => {
  const { open, onClose } = props
  const classes = StudentStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [passwordValid, setPasswordValid] = useState(true)
  const [showPassword, setShowPassword] = useState(false)

  const [toggleSwitch, setToggleSwitch] = useState({
    checked: false,
    disable: false,
    load: false
  })

  const [clientData, setClientData] = useState({
    name: '',
    role: userRoles.USER_STUDENT,
    email: '',
    phoneNumber: '',
    password: ''
  })

  const formInputHandler = (e) => {
    const { value, name } = e.target
    if (name === 'password') {
      setPasswordValid(true)
    }
    if (name == 'phoneNumber' && value.length > 10) {
      return
    } else {
      setClientData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  const handleButtonClick = (e) => {
    e.preventDefault()

    // ------- Checking if required fields are filled --------
    if (!clientData.name || !clientData.email || !clientData.password) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }

    if (!validEmailAddress()) {
      return dispatch(fireErrorToaster('please enter valid email address'))
    }

    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    let data = {
      ...clientData,
      path: '/student/dashboard'
    }

    setLoading(true)

    dispatch(
      addStudent(data, (res, err) => {
        addStudentCallback(res, err)
      })
    )
  }

  const addStudentCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Student added successfully!'))
      closeSidePanel()
    }
  }

  const validEmailAddress = () => {
    let validEmail = /.+@.+\..+/.test(clientData.email)
    if (!validEmail) {
      dispatch(fireErrorToaster('please enter valid email address'))
      return false
    } else {
      return true
    }
  }

  const validPasswordHandler = () => {
    const password = clientData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const closeSidePanel = () => {
    onClose()
    setClientData({
      name: '',
      role: userRoles.USER_CLIENT,
      email: '',
      password: ''
    })

    setToggleSwitch((prev) => ({
      ...prev,
      checked: false,
      disable: false,
      load: false
    }))
  }

  const togglePasswordView = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <>
      <CustomDrawer
        onClose={closeSidePanel}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={closeSidePanel}>Add Student</DrawerHeader>
        <DrawerContent open={open}>
          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Full Name</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="name"
                value={clientData.name}
                type="text"
                placeholder="Full Name"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FormLabel required>Email</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              inputProps={{
                autoComplete: 'off'
              }}
              name="email"
              type="email"
              placeholder="Email"
              value={clientData.email}
              onChange={formInputHandler}
              onBlur={validEmailAddress}
            />
          </Box>

          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Phone Number</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="phoneNumber"
                value={clientData.phoneNumber}
                type="number"
                placeholder="Phone Number"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FormLabel required>Set Password</FormLabel>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '12px'
              }}
            >
              <TextField
                className={classes.textFieldStyle}
                error={clientData.password && !passwordValid ? true : false}
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={clientData.password}
                onChange={formInputHandler}
                onBlur={validPasswordHandler}
                InputProps={{
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={togglePasswordView}>
                        {!showPassword ? (
                          <EyeIcon className="text-base" />
                        ) : (
                          <EyeSlashIcon className="text-base" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            {clientData.password && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                Password should be a combination of an uppercase/lowercase
                letter, a number, a symbol and length between 8 to 20.
              </p>
            )}
          </Box>
        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button
            variant="outlined"
            onClick={closeSidePanel}
            className="w-full"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleButtonClick}
            className="w-full"
          >
            Add
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(AddNewStudent)

const clrPrimary = '#1600E5'
const clrPrimaryLight = '#F3F2FF'

const reactSelectCustomStyles = {
  control: (base, state) => ({
    ...base,
    paddingLeft: 3,
    paddingRight: 3,
    '&:focus-within': {
      borderColor: clrPrimary,
      boxShadow: `0 0 0 1px ${clrPrimary}`
    }
  }),

  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    fontSize: '14px',
    backgroundColor: isSelected
      ? clrPrimary
      : isFocused
        ? clrPrimaryLight
        : null,
    ':active': {
      ...provided[':active'],
      backgroundColor: clrPrimaryLight
    }
  }),

  input: (provided, state) => ({
    ...provided,
    fontSize: '14px'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    color: '#a9adb4',
    fontWeight: '300'
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  multiValue: (provided) => ({
    ...provided,
    padding: '2px 7px',
    borderRadius: '100vh',
    fontWeight: 500,
    backgroundColor: '#ECEAFF'
  }),
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: clrPrimary
    }
  },
  multiValueRemove: (provided, { data }) => ({
    ...provided,
    top: 2,
    position: 'relative',
    left: 2,
    width: 22,
    height: 22,
    color: clrPrimary,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: clrPrimary,
      color: '#fff'
    }
  })
}

export default reactSelectCustomStyles

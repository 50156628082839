import React, { useEffect, useState } from 'react'
import { Typography, TextField } from '@material-ui/core'

import CustomButton from '../../InputElements/Button'

import { Link, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import userRoles from 'utils/userRoles'

import { userSignup } from 'thunks/signup'
import { storeToken } from 'utils/userLogin'

import useTitle from 'customHooks/useTitle'

import background from '../../../static/images/background2.svg'

import './StudentLogin.css'

import examhub_logo from '../../../static/images/examhub_logo.png'

const StudentRegistration = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [passwordValid, setPasswordValid] = useState(true)

  const [loading, setLoading] = useState(false)
  const [isValid, setIsvalid] = useState(true)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: userRoles.USER_STUDENT
  })
  useTitle('The Exam HUB', examhub_logo)

  const userSignupHandler = (e) => {
    e.preventDefault()

    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    setLoading(true)
    dispatch(
      userSignup(
        {
          ...formData
        },
        signupCallback
      )
    )
  }

  const signupCallback = (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      storeToken(res.login.token)
      setLoading(true)
      window.open(`${window.location.origin}${res.path}`, '_self')
    }

    // if (err) {
    //   setLoading(false)
    //   dispatch(fireErrorToaster(res))
    // } else {
    //   setLoading(false)
    //   dispatch(fireSuccessToaster('Signup successful'))

    //   history.push(res.user.path)
    // }
  }

  const formInputHandler = (e) => {
    const { name, value } = e.target

    setFormData((oldState) => ({ ...oldState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  useEffect(() => {
    const reg = /\S+@\S+\.\S+/
    if (
      reg.test(formData.email) &&
      formData.name.length !== 0
      // &&
      // formData.phoneNumber.length !== 0
    ) {
      setIsvalid(false)
    } else {
      setIsvalid(true)
    }
  }, [formData])

  return (
    <div className="login">
      <header className="header">
        <Link to={'/login'} className="text-primary">
          <img
            style={{ height: '24px', width: '24px' }}
            src={examhub_logo}
            alt=""
          />{' '}
          <span>The Exam HUB</span>
        </Link>
      </header>
      <div className="login-container">
        <div className="login-hero">
          <img src={background} alt="background" />
        </div>
        <div className="login-content">
          <h2 className="login-welcome">Welcome to The Exam HUB! 👋</h2>
          <p>Create a Student account</p>

          <form onSubmit={userSignupHandler}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              type="text"
              name="name"
              onChange={formInputHandler}
              value={formData.name}
              required
            />

            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              name="email"
              onChange={formInputHandler}
              value={formData.email}
              required
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              name="password"
              onChange={formInputHandler}
              value={formData.password}
              required
            />

            <div className="form-button mt-2">
              <CustomButton
                loading={loading}
                fullWidth
                type="submit"
              // onClick={loginHandler}
              >
                Signup
              </CustomButton>
            </div>
          </form>

          <div
            className="flex justify-end mt-4"
          // style={{ flexDirection: 'row-reverse', marginTop: '10px' }}
          >
            <Typography
              // className="flex"
              style={{
                textDecoration: 'none',
                color: '#64bdff',
                letterSpacing: '0px',
                fontSize: '13px',
                fontWeight: '400'
              }}
              component={Link}
              to={'/login'}
            >
              Have an Account? Sign In
            </Typography>
            {/* <Typography
              className="flex"
              style={{
                textDecoration: 'none',
                color: '#64bdff',
                letterSpacing: '0px',
                fontSize: '13px',
                fontWeight: '400',
                cursor: 'pointer'
              }}
            // onClick={() => setActiveTab(true)}
            ></Typography> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentRegistration

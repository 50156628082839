import { Typography } from '@material-ui/core'
import TextInput from 'global/globalComponents/TextInput/TextInput'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { LoadingButton } from 'global/globalComponents'
import { Link } from 'react-router-dom'

function AgencySignupStep2({
  formInputHandler,
  formData,
  setActiveTab,
  loading,
  setConfirmPassword,
  confirmPassword,
  validPasswordHandler,
  passwordValid
}) {
  return (
    <div className="flex flex-col">
      <FormLabel style={{ marginTop: '10px' }}>Designation</FormLabel>
      <TextInput
        onChange={formInputHandler}
        name="designation"
        placeholder="Designation"
        value={formData.designation}
        autoFocus={process.env.NODE_ENV === 'development'}
      />
      <FormLabel required style={{ marginTop: '10px' }}>
        {' '}
        Password
      </FormLabel>
      <TextInput
        onChange={formInputHandler}
        name="password"
        placeholder="Password (minimum 6 character)"
        type="password"
        value={formData.password}
        autoFocus={process.env.NODE_ENV === 'development'}
        onBlur={validPasswordHandler}
      />
      {formData.password && !passwordValid && (
        <p className="text-xs mt-1" style={{ color: '#f44336' }}>
          Password should be a combination of an uppercase/lowercase letter, a
          number, a symbol and length between 8 to 20.
        </p>
      )}

      <FormLabel required style={{ marginTop: '10px' }}>
        {' '}
        Confirm Password
      </FormLabel>
      <TextInput
        onChange={(e) => setConfirmPassword(e.target.value)}
        name="confirm password"
        placeholder="Confirm Password"
        type="password"
        value={confirmPassword}
        autoFocus={process.env.NODE_ENV === 'development'}
      />

      <div className="mt-4">
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          fullWidth
        >
          Create Account
        </LoadingButton>
      </div>
      <div className="flex justify-between mt-2" >
        <Typography
          className="flex"
          style={{
            textDecoration: 'none',
            color: '#64bdff',
            letterSpacing: '0px',
            fontSize: '13px',
            fontWeight: '400',
            cursor: 'pointer'
          }}
          onClick={() => setActiveTab(true)}
        >
          Previous step
        </Typography>
        <Typography
          className="flex"
          style={{
            textDecoration: 'none',
            color: '#64bdff',
            letterSpacing: '0px',
            fontSize: '13px',
            fontWeight: '400'
          }}
          component={Link}
          to={'/login'}
        >
          Have an Account? Sign In
        </Typography>
      </div>
    </div>
  )
}

export default AgencySignupStep2

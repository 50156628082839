import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment
} from '@material-ui/core'

import Select from 'react-select'
import userRoles from 'utils/userRoles'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  LoadingButton, ToggleSwitch, Pill,
  CustomTooltip
} from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import SubjectStyles from './SubjectStyles'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

import { addSubject } from 'thunks/subject/actions'

import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'


const AddNewSubject = (props) => {
  const { open, onClose } = props
  const classes = SubjectStyles()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [toggleSwitch, setToggleSwitch] = useState(false)

  const categoryData = useSelector((state) => state.exams)

  const [selectedCategory, setSelectedCategory] = useState([])


  const [clientData, setClientData] = useState({
    icon: "",
    code: '',
    name: '',
    isActive: false,
    types: []
  })


  const [subjectTypes, setSubjectTypes] = useState({
    key: "",
    value: ""
  })


  const [categroyOptions, setCategroyOptions] = useState({
    loading: true,
    data: []
  })

  useEffect(() => {

    setCategroyOptions((prev) => ({
      ...prev,
      loading: false,
      data: categoryData.data
        .filter((item) => item.isActive === true)
        .map((item) => ({
          label: item.name,
          value: item._id
        }))
    }))
  }, [categoryData])


  const handleSubjectTypeChanges = (e) => {
    setSubjectTypes({
      key: e.target.value.trim().split(" ").join("_").toLowerCase(),
      value: e.target.value,
    })
  }

  const handleAddSubjectTypes = (e) => {
    e.preventDefault()

    setClientData((prev) => ({
      ...prev,
      types: [...prev.types, subjectTypes]
    }))

    setSubjectTypes({ key: '', value: '' })
  }

  const handleDeleteOfferings = (value) => {
    const arr = clientData.types.filter((item, index) => index !== value)
    setClientData((prev) => ({
      ...prev,
      types: arr
    }))
  }


  const formInputHandler = (e) => {
    const { value, name } = e.target

    if (name == 'phone' && value.length > 10) {
      return
    } else {
      setClientData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  const handleButtonClick = () => {
    if (!clientData.name) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }

    var randomNumber = Math.floor(1000 + Math.random() * 9000)

    let data = {
      ...clientData,
      code: `EHS-${randomNumber}`,
      exam: selectedCategory.map((item) => item.value),
      isActive: toggleSwitch
    }
    setClientData(data)
    setLoading(true)

    dispatch(
      addSubject(data, (res, err) => {
        addSubjectCallback(res, err)
      })
    )
  }

  const addSubjectCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      let data = {
        icon: "",
        code: '',
        name: '',
        isActive: false
      }
      setSelectedCategory([])
      setClientData(data)
      setToggleSwitch(false)

      dispatch(fireSuccessToaster('Subject added successfully!'))
      closeSidePanel()
    }
  }

  const handleSelectCategory = (newValue) => {
    setSelectedCategory(newValue)
  }

  const closeSidePanel = () => {
    onClose()
    setClientData({
      icon: "",
      code: '',
      name: '',
      isActive: false
    })
    setSelectedCategory([])
    setToggleSwitch(false)
  }

  const handleSwitch = () => {
    setToggleSwitch((prev) => !prev)
  }

  return (
    <>
      <CustomDrawer
        onClose={closeSidePanel}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={closeSidePanel}>Add Subject</DrawerHeader>
        <DrawerContent open={open}>

          <Box mt={2}>
            <FormLabel required>Subject Icon Url</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              inputProps={{
                autoComplete: 'off'
              }}
              name="icon"
              type="text"
              placeholder="Subject Icon Url"
              value={clientData.icon}
              onChange={formInputHandler}
            />
          </Box>

          <Box mt={2}>
            <FormLabel required>Subject Name</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              inputProps={{
                autoComplete: 'off'
              }}
              name="name"
              type="text"
              placeholder="Subject Name"
              value={clientData.name}
              onChange={formInputHandler}
            />
          </Box>


          <Box mt={2}>
            <FormLabel required>Subject Type</FormLabel>

            <form onSubmit={handleAddSubjectTypes}>
              <div className="flex items-center justify-between gap-2">
                <TextField
                  name="types"
                  value={subjectTypes.value}
                  onChange={handleSubjectTypeChanges}
                  className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                  placeholder="Subject Type"
                  id="type"
                />
                <Button
                  type="submit"
                  disabled={subjectTypes.value.trim().length === 0}
                  size="small"
                >
                  Add
                </Button>
              </div>
            </form>
          </Box>


          <div className="mt-3">
            {clientData.types?.map((item, index) => (
              <li className={'flex items-center py-2 '} key={index}>
                <span className="mr-2 text-sm">{index + 1}.</span>
                <div className="flex items-center">
                  <div>
                    <span className="block text-sm font-medium">
                      {item.value}
                    </span>
                  </div>
                  {/* <div className="ml-4">
                    <Pill variant="blue">{item}</Pill>
                  </div> */}
                </div>
                <div className="ml-auto leading-3 flex space-x-4">
                  <CustomTooltip title="Remove" placement="top">
                    <button
                      className="text-custom-red-main opacity-60 hover:opacity-100"
                      onClick={() => handleDeleteOfferings(index)}
                      data-id={index}
                    >
                      <TrashIcon />
                    </button>
                  </CustomTooltip>
                </div>
              </li>
            ))}
          </div>

          {/* <Box mt={2}>
            <FormLabel required>Exam</FormLabel>

            <Select
              styles={reactSelectCustomStyles}
              placeholder="Exam"
              options={categroyOptions.data}
              value={selectedCategory.length ? selectedCategory : ''}
              className="w-full"
              name="exam"
              onChange={handleSelectCategory}
              isMulti
              isLoading={categroyOptions.loading}
            />
          </Box> */}

          <Box className="flex items-center justify-between mt-3">
            <p className="text-sm font-medium text-gray-700">
              Publish this subject{' '}
            </p>

            <div className="flex items-center gap-1">
              <span className="text-sm font-medium text-gray-700">No</span>
              <ToggleSwitch
                checked={toggleSwitch}
                onChange={handleSwitch}
                disableRipple={true}
              />
              <span className="text-sm font-medium text-gray-700">Yes</span>
            </div>
          </Box>
        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button
            variant="outlined"
            onClick={closeSidePanel}
            className="w-full"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleButtonClick}
            className="w-full"
          >
            Add
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(AddNewSubject)

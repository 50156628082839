import { makeStyles } from '@material-ui/core'
import Header from 'components/Header'
import SideDrawer from 'components/SideDrawer/SideDrawer'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { Redirect, useHistory, withRouter } from 'react-router-dom'

import { checkLogin, getAuthToken, userRoles } from 'utils'
import { useEffect } from 'react'
import { fetchMeData } from 'thunks/me/actions'
// import { useHistory } from 'react-router'

const Dashboard = (props) => {
  axios.defaults.headers.common['Authorization'] = getAuthToken()
    ? getAuthToken()
    : ' '
  const dispatch = useDispatch()
  const history = useHistory()

  const { sideDrawerOpen } = useSelector((state) => state.generalState)
  const classes = useStyles({ sideDrawerOpen: sideDrawerOpen })

  const { loggedIn } = useSelector((state) => state.login)

  const meData = useSelector((state) => state.me.data)

  useEffect(() => {
    if (loggedIn) {
      if (!meData.fetched) {
        dispatch(fetchMeData())
      }
    }
  }, [loggedIn])

  useEffect(() => {}, [props])

  useEffect(() => {
    const loginStatus = checkLogin()
    if (loginStatus) {
      if (props.location.pathname === '/') {
        if (Object.keys(meData).includes('path')) {
          history.push(meData.pathname)
        }
      }
    } else {
      history.push('/login')
    }
  }, [loggedIn, meData])

  return (
    <div>
      <Header />
      <div className={classes.bodyContainer}>
        <SideDrawer />
        <div
          style={{
            paddingTop: '20px',
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingBottom: '30px'
          }}
          className={classes.mainSectionContainer}
        >
          {props.children}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    marginTop: theme.custom.header.height,
    height: `calc(100vh - ${theme.custom.header.height}px )`,
    backgroundColor: 'rgb(244, 245, 247)',
    'overflow-x': 'hidden'
  },
  mainSectionContainer: {
    width: ({ sideDrawerOpen }) =>
      `calc(100vh - ${
        sideDrawerOpen
          ? theme.custom.sideDrawer.width.open
          : theme.custom.sideDrawer.width.closed
      }px)`,
    transition: theme.transitions.create(['width'], {
      duration: theme.transitions.duration.leavingScreen
    }),
    flexGrow: 1,
    flexShrink: 1
  }
}))

export default withRouter(Dashboard)

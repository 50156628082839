import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment
} from '@material-ui/core'
import userRoles from 'utils/userRoles'
import Select from 'react-select'

import { updateFaculty } from '../../thunks/faculty/actions'

import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import FacultyStyles from './FacultyStyles'

import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'

import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

const FacultyEditPanel = (props) => {
  const { open, onClose, data } = props
  const classes = FacultyStyles()
  const [load, setLoad] = useState('')
  const dispatch = useDispatch()
  const [passwordValid, setPasswordValid] = useState(true)

  //   const [isModalOpen, setIsModalOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  //   const [clientFinalData, setClientFinalData] = useState({})

  //   const [toggleSwitch, setToggleSwitch] = useState({
  //     checked: false,
  //     disable: false,
  //     load: false
  //   })

  const [facultyData, setFacultyData] = useState({
    name: '',
    role: userRoles.USER_CLIENT,
    email: '',
    phoneNumber: '',
    status: '',
    password: ''
  })

  var facultyStatusDropDownOptions = [
    { label: 'Requested', value: 'requested' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Rejected', value: 'rejected' }
  ]
  useEffect(() => {
    setFacultyData((prev) => ({
      ...prev,
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      status: data.status
    }))
  }, [data])

  const formInputHandler = (e) => {
    const { value, name } = e.target
    if (name === 'password') {
      setPasswordValid(true)
    }

    if (name == 'phoneNumber' && value.length > 10) {
      return
    } else {
      setFacultyData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  const handleStatusChange = (e) => {
    setFacultyData((prevState) => ({ ...prevState, status: e.value }))
  }

  const clientCallback = (res, err) => {
    setLoad(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      onClose()
      setFacultyData({ name: '' })
      dispatch(fireSuccessToaster('Faculty Updated'))
    }
  }

  const handleUpdateFaculty = () => {
    const filteredDataSet = {
      ...facultyData,
      email: facultyData.email.trim().toLowerCase()
    }

    if (!filteredDataSet.name.trim()) {
      dispatch(fireErrorToaster('Invalid name value!'))
      return
    }

    if (facultyData.password && !validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    // Do not add password field in the body if no password value added
    if (facultyData.password === '') delete filteredDataSet.password
    if (filteredDataSet.email === data.email) delete filteredDataSet.email

    dispatch(updateFaculty(data._id, filteredDataSet, clientCallback))
  }

  const validEmailAddress = () => {
    let validEmail = /.+@.+\..+/.test(facultyData.email)
    if (!validEmail) {
      dispatch(fireErrorToaster('please enter valid email address'))
      return false
    } else {
      return true
    }
  }

  const validPasswordHandler = () => {
    const password = facultyData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const togglePasswordView = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <>
      <CustomDrawer
        onClose={onClose}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={onClose}>Edit Faculty</DrawerHeader>
        <DrawerContent open={open}>
          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
            // p={2}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Full Name</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="name"
                value={facultyData.name}
                type="text"
                placeholder="Full Name"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FormLabel required>Email</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              inputProps={{
                autoComplete: 'off'
              }}
              name="email"
              type="email"
              placeholder="Email"
              value={facultyData.email}
              onChange={formInputHandler}
              onBlur={validEmailAddress}
            />
          </Box>

          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
            // p={2}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Phone Number</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="phoneNumber"
                value={facultyData.phoneNumber}
                type="number"
                placeholder="PhoneNumber"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
            // p={2}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Status</FormLabel>
              <Select
                options={facultyStatusDropDownOptions}
                value={
                  facultyData.status
                    ? facultyStatusDropDownOptions.find(
                        (item) => item.value === facultyData.status
                      )
                    : ''
                }
                styles={reactSelectCustomStyles}
                placeholder="Status "
                className="w-full"
                name="status"
                onChange={handleStatusChange}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FormLabel required>Set Password</FormLabel>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '12px'
              }}
            >
              <TextField
                className={classes.textFieldStyle}
                error={facultyData.password && !passwordValid ? true : false}
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={facultyData.password}
                onChange={formInputHandler}
                onBlur={validPasswordHandler}
                InputProps={{
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={togglePasswordView}>
                        {!showPassword ? (
                          <EyeIcon className="text-base" />
                        ) : (
                          <EyeSlashIcon className="text-base" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            {facultyData.password && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                Password should be a combination of an uppercase/lowercase
                letter, a number, a symbol and length between 8 to 20.
              </p>
            )}
          </Box>
        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button variant="outlined" onClick={onClose} className="w-full">
            Cancel
          </Button>

          <LoadingButton
            loading={load == 'save' ? true : false}
            onClick={handleUpdateFaculty}
            className="w-full"
          >
            Update Faculty
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(FacultyEditPanel)

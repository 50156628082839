import React from 'react'

const SectionHeading = (props) => {
  const { children, className, ...restProps } = props
  return (
    <h1
      className={`text-2xl font-semibold text-primary-dark font-heading capitalize mb-1 ${
        className ?? ''
      }`}
      {...restProps}
    >
      {children}
    </h1>
  )
}

export default SectionHeading

const initialState = {
  loading: true,
  error: false,
  data: [],
  fetched: false
}

export const TEAMMATE = {
  LOADING: 'TEAMMATE_LOADING',
  ERROR: 'TEAMMATE_ERROR',
  FETCHED: 'TEAMMATE_FETCHED',
  UPDATED: 'TEAMMATE_UPDATED',
  Add_ONE_TEAMMATE: 'ADD_ONE_TEAMMATE',
  UPDATE_ONE_TEAMMATE: 'UPDATE_ONE_TEAMMATE',
  DELETE_ONE_TEAMMATE: 'DELETE_ONE_TEAMMATE'
}

const teammateReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)

  switch (action.type) {
    case TEAMMATE.LOADING:
      return { ...state, loading: true, error: false, fetched: false }

    case TEAMMATE.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case TEAMMATE.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case TEAMMATE.UPDATED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case TEAMMATE.ADD_ONE_TEAMMATE:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data],
        fetched: true
      }

    case TEAMMATE.UPDATE_ONE_TEAMMATE:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        data: updatedData1,
        fetched: true
      }

    case TEAMMATE.DELETE_ONE_TEAMMATE:
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data.filter((item) => item._id !== action.payload._id),
        fetched: true
      }
    default:
      return state
  }
}

export default teammateReducer

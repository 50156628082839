const initialState = {
  loading: true,
  error: false,
  data: [],
  fetched: false
}

export const STUDENT = {
  LOADING: 'STUDENT_LOADING',
  ERROR: 'STUDENT_ERROR',
  FETCHED: 'STUDENT_FETCHED',
  UPDATED: 'STUDENT_UPDATED',
  ADD_ONE_STUDENT: 'ADD_ONE_STUDENT',
  UPDATE_ONE_STUDENT: 'UPDATE_ONE_STUDENT',
  DELETE_ONE_STUDENT: 'STUDENT_DELETE_ONE_STUDENT'
}

const studentReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)

  switch (action.type) {
    case STUDENT.LOADING:
      return { ...state, loading: true, error: false, fetched: false }

    case STUDENT.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case STUDENT.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case STUDENT.UPDATED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case STUDENT.ADD_ONE_STUDENT:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data],
        fetched: true
      }

    case STUDENT.UPDATE_ONE_STUDENT:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        data: updatedData1,
        fetched: true
      }

    case STUDENT.DELETE_ONE_STUDENT:
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data.filter((item) => item._id !== action.payload._id),
        fetched: true
      }
    default:
      return state
  }
}

export default studentReducer

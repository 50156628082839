import { Avatar } from '@material-ui/core'
import React from 'react'
import stringToHslColor from 'utils/stringToHslColor'

const sizeStyles = {
  small: {
    width: '25px',
    height: '25px',
    fontSize: 13
  }
}

const ColoredAvatars = (props) => {
  const { children, bgColorString = '', style, size = '', ...restProps } = props
  return (
    <Avatar
      style={{
        textTransform: 'uppercase',
        backgroundColor: stringToHslColor(
          bgColorString
            ? bgColorString
            : typeof children === 'string'
            ? children
            : 'asdfasfeee'
        ),
        ...style,
        ...sizeStyles[size]
      }}
      {...restProps}
    >
      {children}
    </Avatar>
  )
}

export default ColoredAvatars

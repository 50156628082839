import React, { useState, useEffect } from 'react'
import {
  Box,
  // makeStyles,
  // createStyles,
  // Typography,
  Button,
  TextField,
  IconButton,
  InputAdornment
  // Toolbar,
  // Drawer,
  // Divider
} from '@material-ui/core'
// import { Add, Close } from '@material-ui/icons'
// import Select from 'react-select'
import userRoles from 'utils/userRoles'

import { updateStudent } from '../../thunks/student/actions'

// import { inviteClient } from 'thunks/addClient/actions'
import { useDispatch, useSelector } from 'react-redux'
// import Category from 'utils/workspaceCategories'
// import Countries from '../../static/countries/Countries'
import {
  fireErrorToaster,
  fireSuccessToaster
  // fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
// import clsx from 'clsx'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

import StudentStyles from './StudentStyles'

// import SendClientCreateMailModal from './SendClientCreateMailModal'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'
// import currency from 'static/Currency/Currency'

const StudentEditPanel = (props) => {
  const { open, onClose, data } = props
  const classes = StudentStyles()
  const [load, setLoad] = useState('')
  const dispatch = useDispatch()
  const [passwordValid, setPasswordValid] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [clientFinalData, setClientFinalData] = useState({})

  const [toggleSwitch, setToggleSwitch] = useState({
    checked: false,
    disable: false,
    load: false
  })

  const [studentData, setStudentData] = useState({
    name: '',
    role: userRoles.USER_CLIENT,
    email: '',
    phoneNumber: '',
    password: ''
  })

  useEffect(() => {
    setStudentData((prev) => ({
      ...prev,
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber
    }))
  }, [data])

  const formInputHandler = (e) => {
    const { value, name } = e.target
    if (name === 'password') {
      setPasswordValid(true)
    }
    if (name == 'phoneNumber' && value.length > 10) {
      return
    } else {
      setStudentData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  const clientCallback = (res, err) => {
    setLoad(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      onClose()
      setStudentData({ name: '' })
      dispatch(fireSuccessToaster('Student Updated'))
    }
  }

  const handleUpdateStudent = () => {
    const filteredDataSet = {
      ...studentData,
      email: studentData.email.trim().toLowerCase()
    }

    if (!filteredDataSet.name.trim()) {
      dispatch(fireErrorToaster('Invalid name value!'))
      return
    }

    if (studentData.password && !validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    // Do not add password field in the body if no password value added
    if (studentData.password === '') delete filteredDataSet.password
    if (filteredDataSet.email === data.email) delete filteredDataSet.email

    dispatch(updateStudent(data._id, filteredDataSet, clientCallback))
  }

  const validEmailAddress = () => {
    let validEmail = /.+@.+\..+/.test(studentData.email)
    if (!validEmail) {
      dispatch(fireErrorToaster('please enter valid email address'))
      return false
    } else {
      return true
    }
  }

  const validPasswordHandler = () => {
    const password = studentData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const togglePasswordView = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <>
      <CustomDrawer
        onClose={onClose}
        anchor="right"
        open={open}
        // variant="persistent"
      >
        <DrawerHeader onClose={onClose}>Edit Student</DrawerHeader>
        <DrawerContent open={open}>
          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
            // p={2}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Full Name</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="name"
                value={studentData.name}
                type="text"
                placeholder="Full Name"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FormLabel required>Email</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              inputProps={{
                autoComplete: 'off'
              }}
              name="email"
              type="email"
              placeholder="Email"
              value={studentData.email}
              onChange={formInputHandler}
              onBlur={validEmailAddress}
            />
          </Box>

          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
            // p={2}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Phone Number</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="phoneNumber"
                value={studentData.phoneNumber}
                type="number"
                placeholder="Phone Number"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FormLabel required>Set Password</FormLabel>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '12px'
              }}
            >
              <TextField
                className={classes.textFieldStyle}
                // error={studentData.password && !passwordValid  ? true : false}
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={studentData.password}
                onChange={formInputHandler}
                onBlur={validPasswordHandler}
                InputProps={{
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={togglePasswordView}>
                        {!showPassword ? (
                          <EyeIcon className="text-base" />
                        ) : (
                          <EyeSlashIcon className="text-base" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            {studentData.password && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                Password should be a combination of an uppercase/lowercase
                letter, a number, a symbol and length between 8 to 20.
              </p>
            )}
          </Box>
        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button variant="outlined" onClick={onClose} className="w-full">
            Cancel
          </Button>

          <LoadingButton
            loading={load == 'save' ? true : false}
            onClick={handleUpdateStudent}
            className="w-full"
          >
            Update Student
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
      {/* <SendClientCreateMailModal
          open={isModalOpen}
          handleClose={toggleInvoiceModal}
          closeSidePanel={closeSidePanel}
          client={clientFinalData}
        /> */}
    </>
  )
}

export default React.memo(StudentEditPanel)

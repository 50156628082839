const initialState = {
  loading: true,
  error: false,
  data: []
}

export const CONTACTUS = {
  LOADING: 'CONTACTUS_LOADING',
  ERROR: 'CONTACTUS_ERROR',
  FETCHED: 'CONTACTUS_FETCHED',
  UPDATED: 'CONTACTUS_UPDATED',
  UPDATE_ONE_CONTACTUS: 'UPDATE_ONE_CONTACTUS',
  DELETE_ONE_CONTACTUS: 'DELETE_ONE_CONTACTUS'
}

  console.log()
  const studentReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case CONTACTUS.LOADING:
      return { ...state, loading: true, error: false }

    case CONTACTUS.ERROR:
      return { ...state, loading: false, error: action.payload }

    case CONTACTUS.FETCHED:
      return { ...state, loading: false, error: false, data: action.payload }

    case CONTACTUS.UPDATED:
      return { ...state, loading: false, error: false, data: action.payload }

    case CONTACTUS.UPDATE_ONE_CONTACTUS:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        data: updatedData1
    }

    case CONTACTUS.DELETE_ONE_CONTACTUS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }
    default:
      return state
  }
}

export default studentReducer

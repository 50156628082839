import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { APP_TOASTER } from 'thunks/fireToaster/reducers'
import { Toast } from '..'

const UniversalToast = () => {
  const dispatch = useDispatch()
  const { message, open, severity } = useSelector((state) => state.appToaster)

  const closeHandler = () => {
    dispatch({ type: APP_TOASTER.CLOSE })
  }

  useEffect(() => {
    autoCloseToast()
  }, [message])

  const autoCloseToast = () => {
    setTimeout(() => {
      closeHandler()
    }, 3000)
  }

  return (
    <Toast
      open={open}
      closeHandler={closeHandler}
      severity={severity}
      message={message}
    />
  )
}

export default UniversalToast

/**
 * Universal Toaster message component
 * Rendered in CookieContainer component
 *
 *
 * dispatch action 'FIRE_TOASTER' with payload as in format {type: "error || success, message: "messageString"}
 *
 * dispatch({action: 'FIRE_TOASTER, payload:{type:"type", message: "message"}})
 *
 */



//  {Object.keys(userStripeInfo.data).includes('accountId') ? (
//   <div className="mt-4 p-4 border border-custom-gray-border rounded-lg bg-white">
//     <p className="mb-2 text-sm text-custom-gray-main">
//       Connected Stripe Account
//     </p>
//     <div className="flex items-center justify-between">
//       <div>
//         <p className="flex items-center gap-2 font-medium">
//           {userStripeInfo.data?.displayName}
//           <CheckCircleOutlinedIcon
//             style={{ fontSize: 16 }}
//             fontSize="small"
//             color="primary"
//           />
//         </p>
//         <p className="text-sm my-2">{userStripeInfo.data?.accountId}</p>
//       </div>
//       <div className="flex items-center justify-center h-10 bg-indigo-400 rounded-md overflow-hidden w-20">
//         <img
//           width="80px"
//           height="80px"
//           alt=""
//           className="h-20 object-contain"
//           src="https://woocommerce.com/wp-content/uploads/2011/12/stripe-logo-blue.png"
//         />
//       </div>
//     </div>
//   </div>
// ) : (
  // <div className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 ">
  //   <div className="w-full flex items-center justify-between p-6 space-x-6">
  //     <div className="flex-1 truncate">
  //       <div className="flex items-center space-x-3">
  //         <h3 className="text-gray-900 text-sm font-medium truncate">
  //           Stripe
  //         </h3>
  //         {/* <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
  //         {person.role}
  //       </span> */}
  //         <Pill
  //           className="flex-shrink-0 text-xs"
  //           variant={
  //             Object.keys(userStripeInfo.data).includes('accountId')
  //               ? 'green'
  //               : 'pink'
  //           }
  //         >
  //           {Object.keys(userStripeInfo.data).includes('accountId')
  //             ? 'Active'
  //             : 'Inactive'}
  //         </Pill>
  //       </div>
  //       <p className="mt-1 text-gray-500 text-sm truncate">
  //         {/* {person.title} */}
  //       </p>
  //     </div>
  //     <div className="flex items-center justify-center h-10 w-20">
  //       <Stripe />
  //     </div>
  //   </div>
  //   <div>
  //     <div
  //       className="-mt-px px-6 flex divide-x divide-gray-200"
  //       onClick={HandleClick}
  //     >
  //       <button className="w-full h-10 text-sm font-medium text-blue-600">
  //         Connect
  //       </button>
  //     </div>
  //   </div>
  // </div>
// )}
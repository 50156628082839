const initialState = {
  open: false,
  message: '',
  severity:''
}

export const APP_TOASTER = {
  ERROR: 'FIRE_ERROR_TOASTER',
  SUCCESS:'FIRE_TOASTER_SUCCESS',
  WARNING:'FIRE_TOASTER_WARNING',
  CLOSE: 'CLOSE_TOASTER'
}

export const fireToaster = (state = initialState, action) => {
  switch (action.type) {
    case APP_TOASTER.ERROR:
      return { ...state, open: true, message: action.payload, severity:'error' }

    case APP_TOASTER.SUCCESS:
      return { ...state, open: true, message: action.payload, severity:'success' }

    case APP_TOASTER.WARNING:
      return { ...state, open: true, message: action.payload, severity:'warning' }

    case APP_TOASTER.CLOSE:
      return { ...state, open: false, message: '', severity:'' }
      
    default:
      return state
  }
}

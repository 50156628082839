import { useRef } from 'react'
import { Dialog, CircularProgress } from '@material-ui/core'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'
import { createStyles, makeStyles } from '@material-ui/core'
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded'

export default function AlertModal(props) {
  const {
    open,
    handleDialog,
    handleDeleteAction,
    warningText = '',
    deleteBtnText = 'Delete',
    cancelText = 'Cancel',
    alertType = 'error',
    heading = 'Alert',
    loading
  } = props
  // const [open, setOpen] = useState(true)

  const cancelButtonRef = useRef()
  const classes = useStyles()

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={handleDialog}
    >
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div
              className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 ${alertType === 'error' ? 'bg-red-100' : 'bg-green-100'
                }`}
            >
              {alertType === 'error' ? (
                <ErrorOutlineRoundedIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              ) : (
                <HelpOutlineRoundedIcon
                  className="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {heading}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{warningText}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          {deleteBtnText && (
            <button
              type="button"
              className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${alertType === 'error'
                ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
                : 'bg-primary-main focus:ring-green-500'
                }`}
              onClick={handleDeleteAction}
            >
              {loading ? (
                <CircularProgress
                  value={0}
                  size={15}
                  style={{ color: 'white' }}
                />
              ) : (
                `${deleteBtnText}`
              )}
            </button>
          )}
          {cancelText && (
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => handleDialog(false)}
              ref={cancelButtonRef}
            >
              {cancelText}
            </button>
          )}
        </div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      minWidth: 500
    }
  })
)

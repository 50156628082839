import { Button, CircularProgress, makeStyles, withStyles } from '@material-ui/core';

// const CustomButton = withStyles({
//   root: {
//     background: 'linear-gradient(45deg, #7A00F6 30%, #7A00F6 90%)',
//     borderRadius: 3,
//     border: 0,
//     color: 'white',
//     height: 48,
//     padding: '0 30px',
//     boxShadow: '0 2px 2px 2px #B300FF50',
//   },
//   label: {
//     textTransform: 'capitalize',
//   },
// })(Button);


const CustomButton = (props) => {
  const classes = useStyles()
  const { loading, ...buttonProps } = props

  return (
    <Button
      classes={{ root: classes.root }}
      {...buttonProps}
      disabled={props.loading || props.disabled}
    >
      {props.children}
      {loading && (
        <CircularProgress
          classes={{ root: classes.progressRoot }}
          size={19}
          aria-busy={true}
          color={'primary'}
        />
      )}
    </Button>
  )
}

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #7A00F6 30%, #7A00F6 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 37,
    padding: '0 30px',
    // boxShadow: '0 2px 2px 2px #B300FF50',
  },
  label: {
    textTransform: 'capitalize',
  },
  progressRoot: {
    position: 'absolute',
    color: '#FFFFFF',
  },
})


export default CustomButton

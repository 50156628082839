import { ToggleSwitch } from 'global/globalComponents'
import React, { useEffect, useState } from 'react'

import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { PopupMenu } from 'global/globalComponents'

import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FacultyEditPanel from './FacultyEditPanel'

import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { updateFaculty, deleteFaculty } from 'thunks/faculty/actions'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
import { errorMessages } from 'utils'
import { format } from 'date-fns'

import userRoles, { getUserPath } from 'utils/userRoles'

const RejectedFaculty = () => {
  const dispatch = useDispatch()

  var userFaculty = useSelector((state) => state.faculty)

  const [rejectedFacultyData, setRejectedFacultyData] = useState([])

  const [facultyData, setFacultyData] = useState({
    open: false,
    data: {},
    close: true
  })

  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])

  const [deleteFacultyData, setdeleteFacultyData] = useState({
    open: false,
    data: {},
    loading: false
  })

  useEffect(() => {
    setRejectedFacultyData(
      userFaculty.data.filter((item) => item.status === 'rejected')
    )
  }, [userFaculty])

  const handleEditPanelOpen = (item) => {
    if (facultyData.open) {
      setFacultyData({
        open: false,
        data: {}
      })
    } else {
      setFacultyData({
        open: true,
        data: item
      })
    }
  }

  const handleEditPanelClose = () => {
    setFacultyData({ open: false, data: {} })
  }

  const handleSwitch = (item) => {
    setToggleSwitchLoading((prevState) => [...prevState, item._id])
    dispatch(
      updateFaculty(item._id, { isActive: !item.isActive }, switchCallback)
    )
  }
  const switchCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      setToggleSwitchLoading((prevState) =>
        prevState.filter((item) => res._id !== item)
      )
    }
  }

  const deleteFacultyCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setdeleteFacultyData((prev) => ({
        ...prev,
        loading: false
      }))
    } else {
      setdeleteFacultyData({
        open: false,
        data: {},
        loading: false
      })
      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }

  const handleDeleteFaculty = (faculty) => {
    if (deleteFacultyData.open) {
      setdeleteFacultyData({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setdeleteFacultyData({
        open: true,
        data: faculty,
        loading: false
      })
    }
  }

  const handleFacultyData = () => {
    setdeleteFacultyData((prev) => ({ ...prev, loading: true }))
    dispatch(deleteFaculty(deleteFacultyData.data._id, deleteFacultyCallback))
  }
  return (
    <div>
      <TableContainer mb={9}>
        <Thead>
          <Th className="w-44 whitespace-nowrap">Name</Th>
          {/* <Th className="w-44 whitespace-nowrap">Title</Th> */}

          <Th className="text-center">Status</Th>

          <Th className="text-center">Last Login</Th>

          <Th className="whitespace-nowrap text-center">More Options</Th>
        </Thead>

        <Tbody>
          {userFaculty.loading ? (
            <TableDataLoading cols={5} />
          ) : rejectedFacultyData.length > 0 ? (
            rejectedFacultyData.map((item, index) => (
              <Row
                key={index}
                item={item}
                handleEditPanelOpen={handleEditPanelOpen}
                handleDeleteFaculty={handleDeleteFaculty}
                handleSwitch={handleSwitch}
                toggleSwitchLoading={toggleSwitchLoading}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
              >
                There are no Rejected Faculty
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      <FacultyEditPanel
        open={facultyData.open}
        onClose={handleEditPanelClose}
        data={facultyData.data}
      />
      <DeleteModal
        warningText="Faculty will be deleted. Do you want to proceed ?"
        open={deleteFacultyData.open}
        handlehandleEditPanelOpen={handleEditPanelOpen}
        handleDeleteFaculty={handleDeleteFaculty}
        handleSwitch={handleSwitch}
        toggleSwitchLoading={toggleSwitchLoading}
        Dialog={handleDeleteFaculty}
        handleDeleteAction={handleFacultyData}
        loading={deleteFacultyData.loading}
      />
    </div>
  )
}

const Row = ({
  item,
  handleEditPanelOpen,
  handleDeleteFaculty,

  handleSwitch,
  toggleSwitchLoading
}) => {
  // const meData // handleEditPanelOpen={handleEditPanelOpen}
  // handleDeleteClient={handleDeleteClient}
  // handleSwitch={handleSwitch}
  // togg = useSelector((state) => state.me.data)
  const history = useHistory()
  // const access = useSelector((state) => state.permission.data?.access)

  const meData = useSelector((state) => state.me.data)

  const openEditFacultyPanel = () => handleEditPanelOpen(item)
  const handleDelete = () => handleDeleteFaculty(item)
  const OpenSingleClientPage = (e) => {
    history.push(`${getUserPath(meData.role)}/dashboard`)

    // history.push('/admin/dashboard')
  }

  return (
    <Tr key={item._id}>
      <Td
        onClick={null} //OpenSingleClientPage
        className="whitespace-nowrap text-left cursor-pointer"
      >
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.name}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.email}</span>
        </div>
      </Td>
      {/* <Td className="whitespace-nowrap text-left">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.title}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.title}</span>
        </div>
      </Td> */}

      <Td className="whitespace-nowrap text-left">
        <ToggleSwitch
          style={{ margin: '0' }}
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>
      <Td className="whitespace-nowrap text-center">
        {item.lastLogin ? (
          <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
            <svg
              className="mr-1 w-3 h-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                clip-rule="evenodd"
              ></path>
            </svg>
            {item.lastLogin ? format(new Date(item.lastLogin), 'd MMM p') : '-'}
          </span>
        ) : (
          '-'
        )}
        {/* <span className="text-primary-mid-dark-2 font-medium text-sm">
          Faculty
        </span> */}
      </Td>
      <Td className=" whitespace-nowrap text-center">
        <PopupMenu
          menuItems={[
            {
              label: 'Edit Faculty',
              method: openEditFacultyPanel,
              icon: <EditIcon className="text-primary-main" />
            },
            {
              label: 'Settings',
              method: OpenSingleClientPage,
              icon: <SettingIcon className="text-primary-main" />
            },
            {
              label: 'Delete Faculty',
              method: handleDelete,
              icon: <DeleteIcon className="text-primary-main" />
            }
          ]}
        />
      </Td>
    </Tr>
  )
}

export default RejectedFaculty

import { Typography, Button } from '@material-ui/core'
import TextInput from 'global/globalComponents/TextInput/TextInput'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { Link } from 'react-router-dom'

function AgencySignupStep1({
  formData,
  formInputHandler,
  isValid,
  setActiveTab
}) {
  return (
    <div className="flex flex-col">
      <div>
        <FormLabel required style={{ marginTop: '10px' }}>
          Name
        </FormLabel>
        <TextInput
          placeholder="Name"
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          type="text"
          name="name"
          onChange={formInputHandler}
          value={formData.name}
          required
        />
      </div>

      <FormLabel required style={{ marginTop: '10px' }}>
        Email
      </FormLabel>

      <TextInput
        onChange={formInputHandler}
        name="email"
        placeholder="Email"
        type="email"
        value={formData.email}
      />
      <FormLabel required style={{ marginTop: '10px' }}>
        Phone Number
      </FormLabel>
      <TextInput
        onChange={formInputHandler}
        type="number"
        name="phoneNumber"
        placeholder="Phone Number"
        value={formData.phoneNumber}
      />
      <div className="flex">
        <div className="flex-1 my-2">
          <Button
            variant="contained"
            disabled={isValid}
            onClick={() => setActiveTab(false)}
          >
            Next
          </Button>
        </div>

        <div className="flex-1 text-center my-2">
          <Typography
            style={{
              textDecoration: 'none',
              color: '#64bdff',
              letterSpacing: '0px',
              fontSize: '13px',
              fontWeight: '400'
            }}
            component={Link}
            to={'/login'}
          >
            Have an Account? Sign In
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default AgencySignupStep1

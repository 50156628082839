import { BREADCRUMBS } from './reducer'

export const pushBreadcrumb = (payload) => ({
  type: BREADCRUMBS.PUSH,
  payload
})

export const popBreadcrumb = () => ({ type: BREADCRUMBS.POP })

export const clearAndReplaceBreadCumbs = (bredcrumbs) => ({
  type: BREADCRUMBS.REPLACE_ALL,
  payload: bredcrumbs
})

const initialState = {
  loading: true,
  error: false,
  fetched: false,
  data: []
}

export const EXAM = {
  LOADING: 'EXAM_LOADING',
  ERROR: 'EXAM_ERROR',
  FETCHED: 'EXAM_FETCHED',
  UPDATED: 'EXAM_UPDATED',
  ADD_ONE_EXAM: 'ADD_ONE_EXAM',
  UPDATE_ONE_EXAM: 'UPDATE_ONE_EXAM',
  DELETE_ONE_EXAM: 'DELETE_ONE_EXAM'
}

const examReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case EXAM.LOADING:
      return { ...state, loading: true, error: false, fetched: true }

    case EXAM.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case EXAM.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case EXAM.UPDATED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case EXAM.ADD_ONE_EXAM:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data],
        fetched: true
      }

    case EXAM.UPDATE_ONE_EXAM:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        fetched: true,
        data: updatedData1
      }

    case EXAM.DELETE_ONE_EXAM:
      return {
        ...state,
        loading: false,
        fetched: true,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }
    default:
      return state
  }
}

export default examReducer

import { useEffect, useState, useRef, useImperativeHandle } from 'react'
import React from 'react'
import FieldLabel from './FieldLabel'
import { AdvanceEditor } from 'global/globalComponents'
import { makeStyles } from '@material-ui/core'
import { getQuillHTML } from 'utils'


const QuestionEditor = React.forwardRef(({ taskDescription, updateQuestion }, ref) => {
  const classes = useStyles()

  const [description, setDescription] = useState('')

  const editorRef = useRef()

  useImperativeHandle(ref, () => {
    return {
      clearEditor: () => {
        const editor = editorRef.current.editor
        if (editor) {
          editor.setText("")
        }
      }
    }
  })

  useEffect(() => {
    setDescription(taskDescription || '')
  }, [taskDescription])

  const handleDescriptionChange = (editor) => {


    console.log(editor)
    // const desc = getQuillHTML(description)


    // if (desc !== editor.getText()) {
    //   setDescription(editor.getContents())
    //   updateQuestion(description)
    // }
  }

  const handleClick = (e) => {
    if (e.target.tagName === 'A' && e.target.href !== '') {
      const a = document.createElement('a')
      a.setAttribute('href', e.target.href)
      a.setAttribute('target', '_blank')
      a.click()
    }
  }

  const handleKeyPress = () => {
    setTimeout(() => {
      const editor = editorRef.current.editor

      console.log(editor, "$$$$$$$$$$$$$$$$44444444444")

      handleDescriptionChange(editor)
      // setDescription(editor.getContents())

    }, 100)
  }

  return (
    <>
      <div className="mb-4">
        <FieldLabel>Question</FieldLabel>
      </div>
      <div className="text-sm" onClick={handleClick}>
        <AdvanceEditor
          ref={editorRef}
          // value={description}
          // autoFocus={true}
          // onKeyDown={handleKeyPress}

          // onChange={handleKeyPress}

          onChange={handleDescriptionChange}
          // onBlur={handleDescriptionChange}
          className={classes.editor}
        />
      </div>
    </>
  )
})

const useStyles = makeStyles({
  editor: {
    '& .ql-editor': {
      height: 150,
      width: 1000,
      overflow: 'auto'
    }
  }
})

export default React.memo(QuestionEditor)

import React, { useState, useEffect } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import { updateExamCategory } from '../../thunks/examCategory/actions'

import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'

import ExamCategoryStyles from './ExamCategoryStyles'

const ExamCategoryEditPanel = (props) => {
  const { open, onClose, data } = props

  const classes = ExamCategoryStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const [examCategoryData, setExamCategoryData] = useState({
    name: ""
  })



  useEffect(() => {
    setExamCategoryData((prev) => ({
      ...prev,
      name: data.name
    }))
  }, [data])

  const formInputHandler = (e) => {
    const { value, name } = e.target
    setExamCategoryData((prevState) => ({ ...prevState, [name]: value }))
  }

  const examCategoryCallback = (res, err) => {
    setLoading(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      onClose()
      setExamCategoryData({ name: '' })
      dispatch(fireSuccessToaster('Exam Category Updated'))
    }
  }

  const handleUpdateExamCategory = () => {
    setLoading(true)
    const filteredDataSet = {
      ...examCategoryData,
      name: examCategoryData.name.trim().toLowerCase()
    }

    if (!filteredDataSet.name.trim()) {
      dispatch(fireErrorToaster('Invalid name value!'))
      return
    }

    dispatch(updateExamCategory(data._id, filteredDataSet, examCategoryCallback))
  }



  return (
    <>
      <CustomDrawer
        onClose={onClose}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={onClose}>Update Exam Category</DrawerHeader>
        <DrawerContent open={open}>
          <Box mt={2}>
            <FormLabel required>Exam Category Name</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              name="name"
              inputProps={{
                autoComplete: 'off'
              }}
              value={examCategoryData.name}
              type="text"
              placeholder="Exam Category Name"
              onChange={formInputHandler}
            />
          </Box>

        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button variant="outlined" onClick={onClose} className="w-full">
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleUpdateExamCategory}
            className="w-full"
          >
            Update
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(ExamCategoryEditPanel)

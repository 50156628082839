import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { LOGIN } from 'thunks/login/reduers'
import { checkLogin } from 'utils'
// import './styles.css'

const ProtectedRoutes = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const { loggedIn } = useSelector((state) => state.login)

  useEffect(() => {
    if (checkLogin()) {
      dispatch({ type: LOGIN.LOGGED_IN })
    }
  }, [])

  return (
   
    <Route
      {...rest}
      render={(props) => {
        if (checkLogin()) {
          return <Component />
        } else {

          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
      }}
    />
  )
}

export default ProtectedRoutes

import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin } from 'utils'
import { fetchMeData } from '../../thunks/me/actions'
import UniversalToast from 'global/globalComponents/UniversalToast/UniversalToast'

import { addPermission } from '../../thunks/permission/actions'

var permission = {
  10: {

    examCategory: {
      create: true,
      view: true,
      update: true,
      delete: true
    },

    section: {
      create: true,
      view: true,
      update: true,
      delete: true
    },

    exam: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    faculty: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    student: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    subject: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    topics: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    questions: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    contactus: {
      create: true,
      view: true,
      update: true,
      delete: true
    }
  },
  11: {
    category: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    section: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    faculty: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    student: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    subjects: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    topics: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    questions: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    contactus: {
      create: true,
      view: true,
      update: false,
      delete: false
    }
  },

  12: {
    category: {
      create: false,
      view: true,
      update: false,
      delete: false
    },

    section: {
      create: false,
      view: true,
      update: false,
      delete: false
    },

    faculty: {
      create: false,
      view: true,
      update: true,
      delete: false
    },
    student: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    subjects: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    topics: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    questions: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    contactus: {
      create: false,
      view: true,
      update: false,
      delete: false
    }
  },

  13: {
    category: {
      create: false,
      view: true,
      update: false,
      delete: false
    },

    section: {
      create: false,
      view: true,
      update: false,
      delete: false
    },

    faculty: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    student: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    subjects: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    topics: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    questions: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    contactus: {
      create: false,
      view: true,
      update: false,
      delete: false
    }
  },

  20: {
    category: {
      create: false,
      view: true,
      update: false,
      delete: false
    },

    section: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    faculty: {
      create: false,
      view: false,
      update: false,
      delete: false
    },
    student: {
      create: false,
      view: false,
      update: false,
      delete: false
    },
    subjects: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    topics: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    questions: {
      create: true,
      view: true,
      update: true,
      delete: true
    },
    contactus: {
      create: false,
      view: false,
      update: false,
      delete: false
    }
  },

  30: {
    category: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    section: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    faculty: {
      create: false,
      view: false,
      update: false,
      delete: false
    },
    student: {
      create: false,
      view: false,
      update: false,
      delete: false
    },
    subjects: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    topics: {
      create: false,
      view: true,
      update: false,
      delete: false
    },
    questions: {
      create: false,
      view: false,
      update: false,
      delete: false
    },
    contactus: {
      create: false,
      view: false,
      update: false,
      delete: false
    }
  }
}

function App(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loggedIn } = useSelector((state) => state.login)
  const me = useSelector((state) => state.me.data)
  useEffect(() => {
    if (checkLogin()) {
      const meCallback = (res, err) => { }
      if (!me.fetched) dispatch(fetchMeData(meCallback))
    }
  }, [])

  useEffect(() => {
    dispatch(addPermission(permission[me.role]))
  }, [me])

  // useEffect(() => {
  //   dispatch(updateUserAcces(me.role))
  // }, [me])

  useEffect(() => {
    const loginStatus = checkLogin()

    if (loginStatus) {
      if (props.location.pathname === '/') {
        if (Object.keys(me).includes('path')) {
          history.push(me.path)
        }
      }
      // history.push(props.location.path)
    } else {
      if (props.location.pathname === '/') {
        history.push('/login')
      }
    }
  }, [loggedIn, me])

  return (
    <div className="App">
      {props.children}
      <UniversalToast />
    </div>
  )
}

export default App

export const ME = {
  LOADING: 'LOADING_ME',
  FETCHED: 'ME_FETCHED',
  ERROR: 'ME_ERROR',
  UPDATE: 'ME_UPDATE',
  UPDATE_AGENCY_DOMAIN: 'ME_UPDATE_AGENCY_DOMAIN'
}

const initialState = {
  error: false,
  loading: false,
  fetched: false,
  data: {}
}

const meReducer = (state = initialState, action) => {
  switch (action.type) {
    case ME.LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        fetched: false
      }
    case ME.ERROR:
      return {
        ...state,
        loading: false,
        fetched: true,
        error: action.payload
      }
    case ME.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        data: action.payload
      }
    case ME.UPDATE:
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    case ME.UPDATE_AGENCY_DOMAIN:
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        data: {
          ...state.data,
          whitelabelUrl: action.payload
        }
      }
    default:
      return state
  }
}

export default meReducer

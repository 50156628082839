import { ToggleSwitch } from 'global/globalComponents'
import React, { useEffect, useState } from 'react'
import Pill from 'global/globalComponents/Pill/Pill'

import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { errorMessages } from 'utils'
import { PopupMenu } from 'global/globalComponents'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { useHistory } from 'react-router-dom'

import userRoles, { getUserPath } from 'utils/userRoles'

import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'

import { updateSubject, deleteSubject } from 'thunks/subject/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import SubjectEditPanel from './SubjectEditPanel'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'

const ActiveSubject = () => {
  const dispatch = useDispatch()

  var userSubject = useSelector((state) => state.subject)
  const permission = useSelector((state) => state.permission.data)[0]

  const [activeSubjectData, setActiveSubjectData] = useState([])

  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])

  const [subjectData, setSubjectData] = useState({
    open: false,
    data: {},
    close: true
  })

  const [deleteSubjectData, setDeleteSubjectData] = useState({
    open: false,
    data: {},
    loading: false
  })

  useEffect(() => {
    setActiveSubjectData(userSubject.data.filter((item) => item.isActive))
  }, [userSubject])

  const handleEditPanelOpen = (item) => {
    if (subjectData.open) {
      setSubjectData({
        open: false,
        data: {}
      })
    } else {
      setSubjectData({
        open: true,
        data: item
      })
    }
  }

  const handleSwitch = (item) => {
    setToggleSwitchLoading((prevState) => [...prevState, item._id])
    dispatch(
      updateSubject(item._id, { isActive: !item.isActive }, switchCallback)
    )
  }
  const switchCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      setToggleSwitchLoading((prevState) =>
        prevState.filter((item) => res._id !== item)
      )
    }
  }

  const handleDeleteSubject = (subject) => {
    if (deleteSubjectData.open) {
      setDeleteSubjectData({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeleteSubjectData({
        open: true,
        data: subject,
        loading: false
      })
    }
  }

  const handleEditPanelClose = () => {
    setSubjectData({ open: false, data: {} })
  }

  const handleSubjectData = () => {
    setDeleteSubjectData((prev) => ({ ...prev, loading: true }))
    dispatch(deleteSubject(deleteSubjectData.data._id, deleteSubjectCallback))
  }

  const deleteSubjectCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setDeleteSubjectData((prev) => ({
        ...prev,
        loading: false
      }))
    } else {
      setDeleteSubjectData({
        open: false,
        data: {},
        loading: false
      })

      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }

  return (
    <div>
      <TableContainer mb={9}>
        <Thead>
          <Th className="w-44 whitespace-nowrap">Code</Th>

          <Th className="w-44 whitespace-nowrap">Name</Th>
          <Th className="w-44 whitespace-nowrap">Exams</Th>

          <Th className="text-center">Status</Th>

          <Th className="text-center">Last Update</Th>

          {(permission?.subject.update || permission?.subject?.delete) && (
            <Th className="whitespace-nowrap text-center">More Options</Th>
          )}
        </Thead>

        <Tbody>
          {userSubject.loading ? (
            <TableDataLoading cols={5} />
          ) : activeSubjectData.length > 0 ? (
            activeSubjectData.map((item, index) => (
              <Row
                key={index}
                item={item}
                handleEditPanelOpen={handleEditPanelOpen}
                handleDeleteSubject={handleDeleteSubject}
                handleSwitch={handleSwitch}
                toggleSwitchLoading={toggleSwitchLoading}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
              >
                There are no Active Subject
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>

      <SubjectEditPanel
        open={subjectData.open}
        onClose={handleEditPanelClose}
        data={subjectData.data}
      />
      <DeleteModal
        warningText="Subject will be deleted. Do you want to proceed ?"
        open={deleteSubjectData.open}
        handleDialog={handleDeleteSubject}
        handleDeleteAction={handleSubjectData}
        loading={deleteSubjectData.loading}
      />
    </div>
  )
}

const Row = ({
  item,
  handleEditPanelOpen,
  handleDeleteSubject,
  handleSwitch,
  toggleSwitchLoading
}) => {
  const history = useHistory()
  const meData = useSelector((state) => state.me.data)

  const OpenSingleClientPage = (e) => {
    history.push(`${getUserPath(meData.role)}/subject/topic/${item._id}`)
    // history.push('/admin/subject/topic/' + item._id)
  }

  const openEditSubjectPanel = () => handleEditPanelOpen(item)
  const handleDelete = () => handleDeleteSubject(item)

  return (
    <Tr key={item._id}>
      <Td
        onClick={OpenSingleClientPage} //OpenSingleClientPage
        className="whitespace-nowrap text-left cursor-pointer"
      >
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.code}
          </span>
          {/* <span className="text-primary-mid-dark-2 text-xs">{item.code}</span> */}
        </div>
      </Td>
      <Td className="whitespace-nowrap text-left">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.name}
          </span>
          {/* <span className="text-primary-mid-dark-2 text-xs">{item.name}</span> */}
        </div>
      </Td>


      <Td className="whitespace-nowrap w-0.5 truncate">

        {item?.exam?.length ? (
          <>
            {item.exam.slice(0, 2).map((exam, index) => (

              <div className={index === 0 ? 'leading-6' : 'mt-2'}>

                {index === 0 ? (
                  <div className="leading-3">
                    <Pill variant="pink">{exam.name}</Pill>
                  </div>
                ) : (
                  <div className="flex items-center leading-3">
                    <Pill variant="blue">{exam.name}</Pill>

                    {item.exam?.length > 2 ? (
                      <Pill
                        variant="black"
                        style={{
                          fontSize: 10,
                          padding: '5px',
                          fontWeight: 'bold'
                        }}
                      >
                        +{item.exam?.length - 2}
                      </Pill>
                    ) : null}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <div>-</div>
        )}

        {/* <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.name}
          </span>
        </div> */}
      </Td>

      <Td className="whitespace-nowrap text-left">
        <ToggleSwitch
          style={{ margin: '0' }}
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>
      <Td className="whitespace-nowrap text-center">
        <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          <svg
            className="mr-1 w-3 h-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {item.updatedAt ? format(new Date(item.updatedAt), 'd MMM p') : '-'}
        </span>
        {/* <span className="text-primary-mid-dark-2 font-medium text-sm">
          {item.updatedAt ? format(new Date(item.updatedAt), 'd MMM p') : '-'}
        </span> */}
      </Td>
      <Td className=" whitespace-nowrap text-center">
        <PopupMenu
          menuItems={[
            {
              label: 'Edit',
              method: openEditSubjectPanel,
              icon: <EditIcon className="text-primary-main" />
            },
            // {
            //   label: 'Settings',
            //   method: OpenSingleClientPage,
            //   icon: <SettingIcon className="text-primary-main" />
            // },
            {
              label: 'Delete',
              method: handleDelete,
              icon: <DeleteIcon className="text-error-main" />
            }
          ]}
        />
      </Td>
    </Tr >
  )
}
export default ActiveSubject

import { APP_TOASTER } from './reducers'

/**
 * Fire error toast message
 * @param {String} mssg Toaster message
 */
export const fireErrorToaster = (mssg) => {
  return {
    type: APP_TOASTER.ERROR,
    payload: mssg
  }
}


/**
 * Fire success toast message
 * @param {String} mssg Toaster message
 */
export const fireSuccessToaster = (mssg) => {
  return { type: APP_TOASTER.SUCCESS, payload: mssg }
}

/**
 * Fire warning toast message 
 * @param {Strin} mssg Toaster message
 */
export const fireWarningToaster = (mssg) => {
  return { type: APP_TOASTER.WARNING, payload: mssg }
}

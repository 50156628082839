import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CircularProgress } from '@material-ui/core'

import { OuterContentWrapper, SectionHeading } from 'global/globalComponents'

import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { deleteContactUs, fetchAllContactUs } from 'thunks/contactUs/actions'

import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { format } from 'date-fns'

const ContactUs = () => {
  const dispatch = useDispatch()

  const contactUsData = useSelector((state) => state.contactUs)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!contactUsData.fetched) {
      dispatch(fetchAllContactUs())
    }
  }, [contactUsData.fetched])

  const handleDeleteContactUs = (contact, e) => {
    setLoading(true)
    e.preventDefault()

    dispatch(deleteContactUs(contact, deleteContactUsCallback))
  }

  const deleteContactUsCallback = (res, err) => {
    if (err) {
      setLoading(false)

      dispatch(fireErrorToaster(res))
    } else {
      setLoading(false)

      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }

  return (
    <OuterContentWrapper>
      <div className="flex justify-between items-center mb-9">
        <SectionHeading>Contact Us</SectionHeading>
      </div>
      <TableContainer mb={9}>
        <Thead>
          <Th className="w-44 whitespace-nowrap">Name</Th>
          <Th className="w-48 whitespace-nowrap">Message</Th>

          <Th className="text-center">Created At</Th>

          <Th className="whitespace-nowrap text-center">More Options</Th>
        </Thead>

        <Tbody>
          {contactUsData.loading ? (
            <TableDataLoading cols={5} />
          ) : contactUsData.data.length > 0 ? (
            contactUsData.data.map((item, index) => (
              <Row
                key={index}
                item={item}
                handleDeleteContactUs={handleDeleteContactUs}
                loading={loading}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
              >
                There are no queries
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
    </OuterContentWrapper>
  )
}

const Row = ({ item, loading, handleDeleteContactUs }) => {
  const handleDelete = () => handleDeleteContactUs(item)

  return (
    <Tr key={item._id}>
      <Td onClick={null} className="whitespace-nowrap text-left cursor-pointer">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.name}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.email}</span>
        </div>
      </Td>
      <Td className="whitespace-wrap text-left">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.title}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">
            {item.message}
          </span>
        </div>
      </Td>

      <Td className="whitespace-nowrap text-center">
        <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          <svg
            className="mr-1 w-3 h-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {item.updatedAt ? format(new Date(item.updatedAt), 'd MMM p') : '-'}
        </span>
      </Td>

      <Td className="text-center">
        {loading ? (
          <CircularProgress
            className="text-primary-main"
            value={item._id}
            size={15}
          />
        ) : (
          <DeleteIcon
            disable={true}
            style={{ color: 'red', display: 'inline-block' }}
            className="text-primary-main"
            onClick={handleDelete}
          />
        )}
      </Td>
    </Tr>
  )
}

export default ContactUs

import axios from 'axios'
import { EXAM } from './reducers'
import { errorMessages } from 'utils'

export const addExam = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/exam',
        data
      })
      dispatch({ type: EXAM.ADD_ONE_EXAM, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: EXAM.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

export const fetchAllExam = (callback) => {
  return async (dispatch) => {
    // dispatch({ type: STUDENT.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/exam'
      })

      dispatch({ type: EXAM.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: EXAM.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}

/**
 * update Teamamte isACtive status.
 * @param {id} id Teammate id
 * @param {Object} data isACtive
 * @param {Function} callback callback function
 */
export const updateExam = (id, data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: EXAM.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/exam/${id}`,
        data
      })
      dispatch({ type: EXAM.UPDATE_ONE_EXAM, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      // dispatch({type: , payload:err.response.data.message})
      if (callback) callback(err.response, true)
    }
  }
}

export const deleteExam = (exam, callback) => {
  return async (dispatch) => {
    // dispatch({ type: EXAM.LOADING })

    try {
      const res = await axios({
        method: 'DELETE',
        url: `/exam/${exam._id}`
      })

      if (callback) callback(res, false)
      dispatch({ type: EXAM.DELETE_ONE_EXAM, payload: res.data })
    } catch (err) {
      dispatch({ type: EXAM.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}

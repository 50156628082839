import axios from 'axios'

/**
 * Forgot password api call
 * @param {String} email User email
 * @param {Function} callback callback function 
 */
export const forgotPassword = async (email, callback) => {
  try {
    const res = await axios({
      url: '/user/forgetpassword',
      method: 'POST',
      data: {
        email
      }
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(err?.response?.data?.message ?? 'something went wrong', true)
  }
}


/**
 * Reset password Api call
 * @param {{newPassword:String, confirmNewPassword:String}} data user new password
 * @param {String} authToken User auth token recieved in url params
 * @param {Function} callback Callback function
 */
export const resetPassword = async (data, authToken, callback) =>{
  try {
    const res = await axios({
      url: `/user/resetPassword/${authToken}`,
      method: 'POST',
      data
    })
    if(callback) callback(res.data, false)
  } catch (err) {
    if(callback)
      callback(err?.response?.data?.message ?? "Something went wrong",)
  }
}
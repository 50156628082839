import { MuiThemeProvider } from '@material-ui/core'
import { Provider } from 'react-redux'
import Routes from 'routes/Routes'
import theme from 'theme/theme'
import configureStore from 'store/store'
import axios from 'axios'
import { storeToken } from 'utils/userLogin'

import useWindowDimensions from './useWindowDimensions'

const store = configureStore()

const backendUrls = {
  dev: 'https://examhub.cyclic.app',
  herokuMaster: 'https://examhub.cyclic.app',
  prod: 'https://examhub.cyclic.app',
  local: 'http://localhost:5000'
}
axios.defaults.baseURL =

  process.env.NODE_ENV === 'development' ? backendUrls.prod : backendUrls.prod

axios.defaults.headers.common['Content-Type'] = 'application/json'

var url_string = window.location.href
var url = new URL(url_string)
var auth_token = url.searchParams.get('auth_token')
if (auth_token) {
  storeToken(`Bearer ${auth_token}`)
}
const App = () => {
//   const { height, width } = useWindowDimensions()
  
  return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <div>
            <Routes />
          </div>
        </Provider>
      </MuiThemeProvider>
    )

//   if (
//     process.env.NODE_ENV === 'development' ||
//     (width >= 849 && height >= 403)
//   ) {
//     return (
//       <MuiThemeProvider theme={theme}>
//         <Provider store={store}>
//           <div>
//             <Routes />
//           </div>
//         </Provider>
//       </MuiThemeProvider>
//     )
//   } else {
//     return (
//       <MuiThemeProvider theme={theme}>
//         <h1
//           className="text-center"
//           style={{
//             position: 'absolute',
//             left: '50%',
//             top: '50%',
//             transform: 'translate(-50%, -50%)',
//             fontSize: '20px'
//           }}
//         >
//           This Website can't ne access in mobile. <br />
//           <br />
//           <h5>Please use a desktop computer to access this website</h5>
//           <br />
//           <br /> <b> : Mandeep</b>
//         </h1>
//       </MuiThemeProvider>
//     )
//   }
}

export default App

import axios from 'axios'
import { DASHBOARD } from './reduers'

export const dashboardAnalytics = (callback) => {
  return async (dispatch) => {
    dispatch({ type: DASHBOARD.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/dashboard/analytics'
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(err?.response?.data?.message ?? 'Something went wrong', true)
    }
  }
}

const { createTheme } = require('@material-ui/core')

const theme = createTheme({
  palette: {
    darkBlue: {
      main: '#1600E5'
    },
    primary: {
      main: '#1600E5'
    },
    secondary: {
      main: '#6B6B71'
    },
    text: {
      primary: '#14142b',
      secondary: '#778CA2'
    },
    custom: {
      gray: {
        main: '#778ca2',
        light: '#F9F9F9'
      },
      dark: {
        main: '#17191C',
        light: '#14142b'
      },
      green: {
        main: '#7acd65'
      }
    }
  },
  typography: {
    fontFamily: `'Inter', sans-serif`,
    h1: {
      color: 'red'
    },
    h5: {
      fontSize: '1.625rem'
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 400
    }
  },
  props: {
    MuiButton: {
      fontSize: '14px',
      variant: 'contained',
      color: 'primary',
      disableRipple: true,
      disableElevation: true,
      background: '#1600E5'
    },
    MuiTextField: {
      size: 'small',
      variant: 'outlined'
    },
    MuiCheckbox: {
      color: 'primary'
    },

    MuiSelect: {
      variant: 'outlined'
    },
    MuiTableRow: {
      hover: true
    }
  },

  overrides: {
    MuiTouchRipple: {
      rippleVisible: {
        display: 'none'
      },
      childLeaving: {
        display: 'none'
      }
    },
    MuiTableContainer: {
      root: {
        boxShadow: 'rgba(198, 198, 198, 0.32) 0px -1px 2px 0px',
        background: '#fff',

      }
    },
    MuiTableCell: {
      root: {
        padding: '8px 16px',
        fontSize: '15px'
      },
      head: {
        color: 'rgba(9,30,66,.74)',
        fontSize: '11px',
        textTransform: 'uppercase',
        fontWeight: '700',
        background: 'rgb(250, 251, 252)'
      },
      body: {
        fontSize: '14px',
        fontWeight: '400',
        color: 'rgba(9,30,66,.74)',
        letterSpacing: 'normal',
        fontFamily: 'Inter, sans-serif'
      },
      stickyHeader: {
        backgroundColor: 'rgb(250, 251, 252)'
      }
    },

    MuiButton: {
      sizeSmall: {
        fontSize: '12px',
        fontWeight: '500',
        minWidth: 65,
        padding: '4px 10px'
      },
      root: {
        fontSize: '14px',
        textTransform: 'capitalize',
        backgroundColor: '#1600E5',
        borderRadius: '100vh',
        padding: '8px 18px',
        fontFamily: 'Inter, sans-serif',
        minWidth: 100
      },
      outlined: {
        background: '#fff'
      },
      outlinedPrimary: {
        color: '#1600E5',
        border: '1px solid #CDC8FF',
        background: '#fff',
        padding: '8px 18px',
        '&:hover, &:focus': {
          background: '#fff'
        },
        '&$disabled': {
          '&&': {
            background: '#fff',
            color: '#C8C3FA'
          }
        }
      },
      containedPrimary: {
        border: '1px solid #1600E5',
        backgroundColor: '#1600E5',
        '&:hover, &:focus': {
          backgroundColor: '#1400CC'
        },
        '&$disabled': {
          '&&': {
            background: '#DAD7FD',
            color: '#fff',
            borderColor: '#DAD7FD'
          }
        }
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: 14
      },
      root: {
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#1600E5'
        }
        // '&.Mui-focused': {
        //   border: '1px solid rgba(0, 0, 0, 0.23)',
        //   '& .MuiOutlinedInput-notchedOutline': {
        //     border: 'none'
        //   }
        // }
        // "&.Mui-focused fieldset": {
        //   border: "2px solid green",},
        // '&.Mui-focused fieldset': {
        //   borderColor: 'rgba(0, 0, 0, 0.23)',
        // }
      }
    },
    MuiDialogTitle: {
      root: {
        borderBottom: '1px solid #d6d6d6',
        padding: '12px 24px'
      }
    },
    MuiDialogActions: {
      root: {
        borderTop: '1px solid #d6d6d6',
        padding: '12px 24px'
      }
    },
    MuiAccordionSummaryContent: {
      margin: 0,
      display: 'flex',
      flexGrow: 1,
      transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    MuiPopover: {
      paper: {
        boxShadow:
          '0 0 0 1px rgb(111 119 130 / 15%), 0 5px 20px 0 rgb(21 27 38 / 8%)'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '.875rem'
      },
      input: {
        height: '1.01876em'
      }
    },
    MuiDrawer: {
      paperAnchorDockedRight: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)'
      }
    },
    MuiTypography: {
      body2: {
        fontSize: '0.75rem',
        lineHeight: '1.3',
        fontFamily: 'Inter, sans-serif'
      },
      body1: {
        fontFamily: 'Inter, sans-serif'
      }
    },
    MuiTabs: {
      indicator: {
        width: '50%',
        backgroundColor: '#1600E5',
        height: 3
      }
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
        '&$selected': {
          color: '#1600E5',
          fontWeight: '500'
        },
        '@media (min-width: 0px)': {
          minWidth: 'initial'
        }
      }
    }
  },

  custom: {
    header: {
      height: 74
    },
    sidebar: {
      width: 230
    },
    sideDrawer: {
      width: { open: 220, closed: 80 }
    },
    drawerPaper: {
      // boxShadow:'-3px 0px 27px 2px rgb(189 180 180 / 75%)'
      boxShadow: '0 5px 20px 0 rgb(21 27 38 / 8%)'
    },
    logsDrawer: {
      width: 300
    }
  }
})

export default theme

import axios from 'axios'
import { storeToken } from 'utils/userLogin'
import { LOGIN } from './reduers'

/**
 * Agency user login action
 * @route `user/login`
 * @param {Object} data User data
 * @param {Function} callback Callback function. (response/errorMessage, error)
 */
export const userLogin = (data, callback) => {

  return async (dispatch) => {
    dispatch({ type: LOGIN.LOADING })
    try {
      const res = await axios({
        method: 'POST',
        url: '/user/login',
        data
        // data: {
        //   ...data,
        //    agencyDomain: 'abc.clientvenue.com'
        // }
      })
      storeToken(res.data.token)
      if (callback) callback(res.data, false)
      dispatch({ type: LOGIN.LOGGED_IN, payload: res.data })
    } catch (err) {
      console.log(err)
      dispatch({
        type: LOGIN.ERROR,
        payload: err?.response?.data?.message ?? 'Something went wrong'
      })
      if (callback)
        callback(err?.response?.data?.message ?? 'Something went wrong', true)
    }
  }
}

import React, { useState } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import FormLabel from 'global/globalComponents/FormLabel/FormLabel'

import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'

import ExamCategoryStyles from './ExamCategoryStyles'
import { addExamCategory } from 'thunks/examCategory/actions'
import { LoadingButton } from 'global/globalComponents'

const AddNewExamCategory = (props) => {
  const { open, onClose } = props
  const classes = ExamCategoryStyles()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  // const [toggleSwitch, setToggleSwitch] = useState(false)

  const [examCategoryData, setExamCategoryData] = useState({
    icon: "",
    code: '',
    name: '',
    isActive: false
  })


  const formInputHandler = (e) => {
    const { value, name } = e.target
    setExamCategoryData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleButtonClick = () => {
    if (
      !examCategoryData.name) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }

    var randomNumber = Math.floor(1000 + Math.random() * 10000)

    let data = {
      ...examCategoryData,
      code: `ET-${randomNumber}`,
      isActive: true,
    }

    setExamCategoryData(data)
    setLoading(true)

    dispatch(
      addExamCategory(data, (res, err) => {
        addExamCategoryCallback(res, err)
      })
    )
  }

  const addExamCategoryCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      let data = {
        icon: "",
        code: '',
        name: '',
        isActive: false
      }
      setExamCategoryData(data)
      // setToggleSwitch(false)

      dispatch(fireSuccessToaster('Exam added successfully!'))
      closeSidePanel()
    }
  }

  const closeSidePanel = () => {
    onClose()
    setExamCategoryData({
      code: '',
      name: '',
      isActive: false
    })

    // setToggleSwitch(false)
  }

  return (
    <>
      <CustomDrawer
        onClose={closeSidePanel}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={closeSidePanel}>Add Exam Category</DrawerHeader>
        <DrawerContent open={open}>

          <div>
            <Box mt={2} className="flex-1">
              <FormLabel required>Exam Category Icon</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="icon"
                type="text"
                placeholder="Exam Category Icon"
                value={examCategoryData.icon}
                onChange={formInputHandler}
              />
            </Box>

            <div className="flex space-x-4">


              <Box mt={2} className="flex-1">
                <FormLabel required>Exam Category Name</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="name"
                  type="text"
                  placeholder="Exam Category Name"
                  value={examCategoryData.name}
                  onChange={formInputHandler}
                />
              </Box>
            </div>

          </div>

        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button
            variant="outlined"
            onClick={closeSidePanel}
            className="w-full"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleButtonClick}
            className="w-full"
          >
            Add
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(AddNewExamCategory)

export const DASHBOARD = {
  LOADING: 'DASHBOARD_LOADING',
  ERROR: 'DASHBOARD_ERROR'
}

const initialState = {
  loading: false,
  error: false
}

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD.LOADING:
      return { ...state, loading: true, error: false }

    case DASHBOARD.ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}

import { makeStyles, createStyles } from '@material-ui/core'

const SubjectStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      width: '70vw',
      maxWidth: '600px',
      marginTop: theme.custom.header.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      '&.drawerOpen': {
        boxShadow: theme.custom.drawerPaper.boxShadow,
        overflow: 'visible'
      }
    },
    textFieldStyle: {
      width: '100%'
    },
    saveBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(2),
      padding: theme.spacing(4, 0)
    },
    panelFooter: {
      background: '#f3f3f3',
      justifyContent: 'flex-start',
      width: '100%'
    },
    attachment: {
      height: '120px',
      width: '200px',
      objectFit: 'cover',
      borderRadius: '10px'
    }
  })
)

export default SubjectStyles

import {
  Box,
  createStyles,
  FormLabel,
  makeStyles,
  TextField
} from '@material-ui/core'
import { Create } from '@material-ui/icons'
import { LoadingButton, SectionHeading, Wrapper } from 'global/globalComponents'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateUser } from 'thunks/user/actions'
import { format } from 'date-fns'
import useTitle from 'customHooks/useTitle'
import examhub_logo from '../../../static/images/examhub_logo.png'


const General = () => {
  useTitle('Profile | The Exam HUB', examhub_logo)

  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    data: meData,
    loading: userLoading,
    error: userError
  } = useSelector((state) => state.me)

  // const {
  //   data: userData,
  //   loading: userLoading,
  //   error: userError
  // } = useSelector((state) => state.user)

  const [formData, setFormData] = useState({
    name: '',
    email: "", //
    profileImage: '',
    phoneNumber: '',
    profile: {}
  })

  useEffect(() => {
    setFormData((oldData) => ({
      ...oldData,
      username: meData.username ?? "",
      name: meData.name,
      email: meData.email,
      profileImage: meData.profileImage,
      phoneNumber: meData.phoneNumber,
      profile: meData?.profile ?? {},
      createdAt: meData.createdAt,
    }))
  }, [meData])

  const formInputHandler = (e) => {
    const { name, value } = e.target

    if (name === "email" || name === "createdAt") return

    else if (name === "line1" || name === "line2" || name === "city" || name === "state" || name === "zipCode" || name === "country") {
      setFormData((oldData) => ({
        ...oldData,
        profile: {
          address: {
            ...oldData?.profile?.address,
            country: "india",
            [name]: value
          }
        }
      }))
    } else {
      setFormData((oldData) => ({
        ...oldData,
        [name]: value
      }))

    }
  }
  const handleSaveForm = () => {
    dispatch(updateUser(formData));
  }


  const imageLoadHandler = (e) => {
    // console.log(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    const imgReader = new FileReader()
    imgReader.onloadend = function () {
      if (file) {
        console.log('Base64 Format', imgReader.result)
        setFormData((oldData) => ({
          ...oldData,
          profileImage: imgReader.result
        }))
      }
    }
    imgReader.readAsDataURL(file)
  }

  return (
    <Wrapper>
      <SectionHeading className="mb-8">General</SectionHeading>

      <form>
        <div className="mt-2 gap-8 mb-8">
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file1"
            multiple={false}
            type="file"
            onChange={imageLoadHandler}
          />

          {/* <FormLabel className="pb-2">Profile Picture</FormLabel> */}
          <label htmlFor="contained-button-file1" className="inline-block">
            <PersonAvatar
              img={
                formData?.profileImage ? formData?.profileImage : "defaultImage"
              }
            />
          </label>
        </div>

        <div className="flex justify-between items-center space-x-4">
          <div className=" w-full">
            <FormLabel htmlFor="userName" required>
              Username
            </FormLabel>
            <TextField
              id="userName"
              className={classes.inputBox}
              // value={username}
              // inputProps={{ onBlur: handleuserNameUpdate }}
              disabled={true}
              // onChange={handleUsernameInput}
              name={'username'}
            />
          </div>

          <div className="w-full">
            <div className="flex-grow">
              <FormLabel htmlFor="name" required>
                Name
              </FormLabel>
              <TextField
                className={classes.inputBox}
                id="name"
                value={formData.name}
                onChange={formInputHandler}
                disabled={userLoading}
                name={'name'}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center space-x-4 mt-4">
          <div className="w-full">
            <FormLabel required>Email</FormLabel>
            <TextField
              className={classes.inputBox}
              value={formData.email}
              disabled={true}
              onChange={formInputHandler}
              name="email"
            />
          </div>

          <div className="w-full">
            <FormLabel htmlFor="phone">Phone</FormLabel>
            <TextField
              id="phone"
              className={classes.inputBox}
              value={formData.phoneNumber}
              onChange={formInputHandler}
              type="number"
              // disabled={userLoading}
              name={'phoneNumber'}
            />
          </div>
        </div>

        <div className="flex justify-between items-center space-x-4 mt-4">
          <div className="w-full">
            <div className="flex-grow">
              <FormLabel htmlFor="name" required>
                Address
              </FormLabel>
              <TextField
                className={classes.inputBox}
                id="line1"
                placeholder="Line 1"
                value={formData?.profile?.address?.line1}
                onChange={formInputHandler}
                disabled={userLoading}
                name={'line1'}
              />
            </div>
          </div>

          <div className="w-full">
            <div className="flex-grow">
              <FormLabel htmlFor="name" style={{ visibility: "hidden" }}>
                Line 2
              </FormLabel>
              <TextField
                className={classes.inputBox}
                id="line2"
                placeholder="Line 2"
                value={formData?.profile?.address?.line2}
                onChange={formInputHandler}
                disabled={userLoading}
                name={'line2'}
              />
            </div>
          </div>

          <div className="w-full">
            <div className="flex-grow">
              <FormLabel htmlFor="name" style={{ visibility: "hidden" }} required>
                City
              </FormLabel>
              <TextField
                className={classes.inputBox}
                id="city"
                placeholder="City"
                value={formData?.profile?.address?.city}
                onChange={formInputHandler}
                disabled={userLoading}
                name={'city'}
              />
            </div>
          </div>
        </div>


        <div className="flex justify-between items-center space-x-4 mt-4">
          <div className="w-full">

            <TextField
              className={classes.inputBox}
              id="state"
              placeholder="State"
              value={formData?.profile?.address?.state}
              onChange={formInputHandler}
              disabled={userLoading}
              name={'state'}
            />
          </div>

          <div className="w-full">
            <TextField
              className={classes.inputBox}
              id="zipCode"
              placeholder="Pin Code"
              value={formData?.profile?.address?.zipCode}
              onChange={formInputHandler}
              disabled={userLoading}
              type="number"
              name={'zipCode'}
            />
          </div>

          <div className="w-full">
            <TextField
              className={classes.inputBox}
              id="country"
              placeholder="Country"
              value={formData?.profile?.address?.country}
              onChange={formInputHandler}
              disabled={true}
              name={'country'}
            />
          </div>
        </div>


        <div className="flex justify-between items-center space-x-4 mt-4">
          <div className=" w-full">
            <FormLabel htmlFor="createdAt">
              Created At
            </FormLabel>
            <TextField
              id="createdAt"
              className={classes.inputBox}
              value={formData.createdAt ? format(new Date(formData.createdAt), 'd MMM p') : ''}
              disabled={true}
              name={'createdAt'}
            />
          </div>

          <div className=" w-full">
            {/* <FormLabel htmlFor="userName" required>
              created At
            </FormLabel>
            <TextField
              id="userName"
              className={classes.inputBox}
              value={meData.createdAt ? format(new Date(meData.createdAt), 'd MMM p') : ''}
              disabled={true}
              name={'username'}
            /> */}
          </div>

        </div>

        < div className="text-right mt-12" >
          <LoadingButton
            loading={userLoading}
            variant="contained"
            onClick={handleSaveForm}
            className="w-40"
          >
            Save
          </LoadingButton>
        </div >
      </form >


    </Wrapper >
  )
}

const PersonAvatar = (props) => {
  const {
    img = 'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png',
    ...restPorps
  } = props

  return (
    <div
      className="flex flex-row shadow-md rounded-full w-16 h-16 relative cursor-pointer"
      {...restPorps}
    >
      <img
        src={
          img?.includes('https://res.cloudinary.com')
            ? `${img}?${Date.now()}`
            : img
        }
        alt="fallback logo"
        width="64px"
        height="64px"
        className="object-cover rounded-full"
      />
      <span className="absolute bottom-0 right-0 rounded-full p-1 bg-danger-main w-6 h-6 flex items-center justify-center border-2 border-white">
        <Create
          style={{
            color: 'primary',
            fontSize: 14
          }}
        />
      </span>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      display: 'none'
    },
    inputBox: {
      width: '100%'
    }
  })
)

export default General

const initialState = {
  loading: true,
  error: false,
  fetched: false,
  data: []
}

export const FACULTY = {
  LOADING: 'FACULTY_LOADING',
  ERROR: 'FACULTY_ERROR',
  FETCHED: 'FACULTY_FETCHED',
  ADD_ONE_FACULTY: 'ADD_ONE_FACULTY',
  UPDATE_ONE_FACULTY: 'UPDATE_ONE_FACULTY',
  DELETE_ONE_FACULTY: "DELETE_ONE_FACULTY"
}

const studentReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)

  switch (action.type) {
    case FACULTY.LOADING:
      return { ...state, loading: true, error: false, fetched: false }

    case FACULTY.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case FACULTY.FETCHED:
      return { ...state, loading: false, error: false, data: action.payload, fetched: true }

    case FACULTY.ADD_ONE_FACULTY:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data],
        fetched: true
      }
    case FACULTY.UPDATE_ONE_FACULTY:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        data: updatedData1,
        fetched: true
      }

    case FACULTY.DELETE_ONE_FACULTY:
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }

    default:
      return state
  }
}

export default studentReducer

import axios from 'axios'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import { getAuthToken } from 'utils'
import { CONTACTUS } from './reducers'


// var facultyTempData = {
//   data: [
//     {
//       _id: 1,
//       name: 'Jane Cooper',
//       title: 'Regional Paradigm Technician',
//       department: 'Optimization',
//       role: 20,
//       isActive: true,
//       isBlocked: false,
//       status: 'accepted',
//       email: 'jane.cooper@example.com',
//       image:
//         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
//     },

//     {
//       _id: 2,
//       name: 'Jane Cooper',
//       title: 'Regional Paradigm Technician',
//       department: 'Optimization',
//       role: 20,
//       isActive: true,
//       isBlocked: true,
//       status: 'accepted',
//       email: 'jane.cooper@example.com',
//       image:
//         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
//     },

//     {
//       _id: 3,
//       name: 'Jane Cooper',
//       title: 'Regional Paradigm Technician',
//       department: 'Optimization',
//       role: 20,
//       isActive: true,
//       isBlocked: false,
//       status: 'accepted',
//       email: 'jane.cooper@example.com',
//       image:
//         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
//     },

//     {
//       _id: 4,
//       name: 'Jane Cooper',
//       title: 'Regional Paradigm Technician',
//       department: 'Optimization',
//       role: 20,
//       isActive: true,
//       isBlocked: false,
//       status: 'accepted',
//       email: 'jane.cooper@example.com',
//       image:
//         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
//     },

//     {
//       _id: 5,
//       name: 'Inactive Faculty -2',
//       title: 'Regional Paradigm Technician',
//       department: 'Optimization',
//       role: 20,
//       isActive: true,
//       isBlocked: false,
//       status: 'accepted',
//       email: 'jane.cooper@example.com',
//       image:
//         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
//     },

//     {
//       _id: 6,
//       name: 'Request Faculty-1',
//       title: 'Regional Paradigm Technician',
//       department: 'Optimization',
//       role: 20,
//       isActive: true,
//       isBlocked: false,
//       status: 'request',
//       email: 'jane.cooper@example.com',
//       image:
//         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
//     },
//     {
//       _id: 7,
//       name: 'Rejected Faculty-1',
//       title: 'Regional Paradigm Technician',
//       department: 'Optimization',
//       role: 20,
//       isActive: false,
//       isBlocked: false,
//       status: 'rejected',
//       email: 'jane.cooper@example.com',
//       image:
//         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
//     }
//   ]
// }
/**
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchAllContactUs = (callback) => {
  return async (dispatch) => {
    // dispatch({ type: STUDENT.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/contact'
      })

      dispatch({ type: CONTACTUS.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: CONTACTUS.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}


/**
 * update Teamamte isACtive status. 
 * @param {id} id Teammate id
 * @param {Object} data isACtive
 * @param {Function} callback callback function
 */
export const updateOneContactUs = (id, data, callback) => {
  return async (dispatch) => {
    dispatch({ type: CONTACTUS.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/contact/${id}`,
        data
      })
      dispatch({ type: CONTACTUS.UPDATE_ONE_CONTACTUS, payload: res.data })
      if (callback) callback(res, false)
    } catch (err) {
      console.log(err)
      // dispatch({type: , payload:err.response.data.message})
      if (callback) callback(err.response, true)
    }
  }
}

export const deleteContactUs = (contactUs, callback) => {
  return async (dispatch) => {
    dispatch({ type: CONTACTUS.LOADING })

    try {
      const res = await axios({
        method: 'DELETE',
        url: `/contact/${contactUs._id}`
      })

      dispatch({ type: CONTACTUS.DELETE_ONE_CONTACTUS, payload: res.data })
      if (callback) callback(res, false)
    } catch (err) {
      dispatch({ type: CONTACTUS.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err.response, true)
    }
  }
}

import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'

const severityParts = {
  error: {
    text: 'Error',
    color: 'text-red-400'
  },
  success: {
    text: 'Success',
    color: 'text-green-400'
  },
  warning: {
    text: 'Warning',
    color: 'text-yellow-500'
  },
  info: {
    text: 'Info',
    color: 'text-blue-500'
  }
}

const ToastIcon = (props) => {
  const { severity, ...restProps } = props
  if (['info', 'error', 'warning'].includes(severity)) {
    return <ErrorOutlineRoundedIcon {...restProps} />
  } else {
    return <CheckCircleOutlineRoundedIcon {...restProps} />
  }
}

const Toast = (props) => {
  const { open, message, severity, closeHandler } = props
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end justify-start px-4 py-6 pointer-events-none sm:p-6 sm:items-end sm:justify-start"
      style={{ zIndex: 3000 }}
    >
      <Transition
        show={open}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <ToastIcon
                  className={`h-6 w-6 ${severityParts[severity]?.color ?? ''}`}
                  aria-hidden="true"
                  severity={severity}
                />
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">
                  {severityParts[severity]?.text ?? ''}
                </p>
                <p className="mt-1 text-sm text-gray-500">{message}</p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={closeHandler}
                >
                  <CloseIcon fontSize="small" />
                  <span className="sr-only">Close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default Toast

// Old component

// function Alert(props) {
//   return <MuiAlert elevation={6} {...props} />
// }

// /**
//  *
//  * @param {Object} props custom toasrt props
//  */
// const Toast = (props) => {
//   const { open, message, severity, closeHandler } = props
//   return (
//     <Snackbar
//       open={open}
//       autoHideDuration={3000}
//       onClose={() => closeHandler(false)}
//       anchorOrigin={{
//         vertical: 'top',
//         horizontal: 'center'
//       }}
//     >
//       <Alert
//         onClose={() => closeHandler(false)}
//         variant="filled"
//         style={{ background: 'black' }}
//         severity={severity}
//       >
//         {message}
//       </Alert>
//     </Snackbar>
//   )
// }

// Toast.propTypes = {
//   open: PropTypes.bool.isRequired,
//   message: PropTypes.string.isRequired,
//   severity: PropTypes.string,
//   closeHandler: PropTypes.func.isRequired
// }

import * as React from 'react';
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { Button, Dialog, ListItemText, ListItem, List, Divider, AppBar, Toolbar, IconButton, Typography, Slide } from '@material-ui/core'

import { getQuillHTML } from 'utils'


import CustomDrawer, {
    DrawerContent,
    DrawerFooter,
    DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'



import { LoadingButton } from 'global/globalComponents'

import ExamCategoryStyles from '../ExamCategory/ExamCategoryStyles'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewQuestion({ open, setPreviewQuestionState, questionArray }) {

    const classes = ExamCategoryStyles()

    const handleClose = () => {
        setPreviewQuestionState(false)
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <DrawerHeader className="bg-red-400" onClose={handleClose}>Add Exam Type</DrawerHeader>

                <Divider />

                <DrawerContent open={open}>

                    {/* <List className="mt-8"> */}

                    {

                        questionArray?.map((question, index) =>
                        (
                            <div key={index} className="mt-8 shadow">

                                <h2 className="ml-2"><b>{index + 1}.</b>{` ${getQuillHTML(question.question)}`}</h2>
                                <div className="ml-4 mb-4">
                                    <div className="flex mt-4">
                                        <p name="option1" className="flex-1"><b>1.</b> {` ${question.option1}`}</p>
                                        <p name="option2" className="flex-1"> <b>2.</b> {` ${question.option2}`}</p>
                                    </div>

                                    <div className="flex mt-4 mb-4">
                                        <p name="option3" className="flex-1"><b>3.</b> {` ${question.option3}`}</p>
                                        <p name="option4" className="flex-1"> <b>4.</b> {` ${question.option4}`}</p>
                                    </div>

                                </div>

                                <DeleteIcon
                                    disable={true}
                                    style={{ color: 'red'}}
                                    className="text-primary-main"
                                // onClick={handleDelete}
                                />

                            </div>

                        )
                        )
                    }

                    {/* {
                            questionArray?.map(async question =>

                            
                                <ListItem button>
                                    <ListItemText primary={} secondary="Titania" />
                                </ListItem>
                                <Divider />
                                

                            )
                        } */}
                    {/* </List> */}
                </DrawerContent>

                <DrawerFooter className={classes.panelFooter}>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        className="w-full"
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        // loading={loading}
                        // onClick={handleButtonClick}
                        className="w-full"
                    >
                        Final Save
                    </LoadingButton>
                </DrawerFooter>
            </Dialog>
        </div>
    );
}

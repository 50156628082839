import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import AddNewExam from './AddNewExam'
import { PopupMenu } from 'global/globalComponents'

import { ToggleSwitch } from 'global/globalComponents'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'

import Pill from 'global/globalComponents/Pill/Pill'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'

import { OuterContentWrapper, SectionHeading } from 'global/globalComponents'

import { errorMessages } from 'utils'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

import { deleteExam, fetchAllExam, updateExam } from 'thunks/exam/actions'
import ExamEditPanel from './ExamEditPanel'


import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { format } from 'date-fns'

import { fetchSubject } from 'thunks/subject/actions'


const Exam = () => {
  const dispatch = useDispatch()

  const examData = useSelector((state) => state.exams)
  const subjectData = useSelector((state) => state.subject)
  const permission = useSelector((state) => state.permission.data)

  const [examAddPanel, setExamAddPanel] = useState(false)
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])

  const [loading, setLoading] = useState(false)

  const [teammateData, setTeammateData] = useState({
    open: false,
    data: {},
    close: true
  })

  const [deleteTeammateData, setDeleteTeammateData] = useState({
    open: false,
    data: {},
    loading: false
  })

  useEffect(() => {
    if (!examData.fetched) {
      dispatch(fetchSubject())
    }
  }, [examData.fetched])

  useEffect(() => {
    if (!subjectData.fetched) {
      dispatch(fetchAllExam())
    }
  }, [subjectData])

  const handleDeleteExam = (contact, e) => {

    setLoading(true)
    // e.preventDefault()
    setDeleteTeammateData((prev) => ({ ...prev, loading: true }))

    dispatch(deleteExam(contact, deleteExamCallback))
  }

  const deleteExamCallback = (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      setLoading(false)

      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }


  const handleClose = () => {
    setExamAddPanel(false)
  }

  const handleSidePanel = () => {
    setExamAddPanel(true)
  }

  const handleEditPanelOpen = (item) => {
    if (teammateData.open) {
      setTeammateData({
        open: false,
        data: {}
      })
    } else {
      setTeammateData({
        open: true,
        data: item
      })
    }
  }

  const handleDeleteTeammate = (teammate) => {
    if (deleteTeammateData.open) {
      setDeleteTeammateData({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeleteTeammateData({
        open: true,
        data: teammate,
        loading: false
      })
    }
  }

  const handleEditPanelClose = () => {
    setTeammateData({ open: false, data: {} })
  }

  const handleSwitch = (item) => {
    setToggleSwitchLoading((prevState) => [...prevState, item._id])
    dispatch(
      updateExam(item._id, { isActive: !item.isActive }, switchCallback)
    )
  }
  const switchCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      setToggleSwitchLoading((prevState) =>
        prevState.filter((item) => res._id !== item)
      )
    }
  }

  return (
    <OuterContentWrapper>
      <div>
        <div className="flex justify-between items-center mb-9">
          <SectionHeading>exam</SectionHeading>
          {permission[0]?.exam?.create && (
            <Button
              variant="outlined"
              startIcon={<AddIcon fontSize="small" />}
              onClick={handleSidePanel}
            >
              Add Exam
            </Button>
          )}
        </div>
        <TableContainer mb={9}>
          <Thead>
            <Th className="w-44 whitespace-nowrap">Name</Th>
            <Th className="w-48 whitespace-nowrap">Category</Th>
            <Th className="w-48 whitespace-nowrap">Subject</Th>
            <Th className="w-48 whitespace-nowrap">Total Question</Th>
            <Th className="w-48 whitespace-nowrap">Total Time (Minutes)</Th>

            <Th className="w-48 whitespace-nowrap">Status</Th>

            {/* <Th className="w-48 text-center whitespace-nowrap">Created At</Th> */}
            {(permission[0]?.exam?.update ||
              permission[0]?.exam?.delete) && (
                <Th className="whitespace-nowrap text-center">More Options</Th>
              )}
          </Thead>

          <Tbody>
            {examData.loading ? (
              <TableDataLoading cols={7} />
            ) : examData.data.length > 0 ? (
              examData.data.map((item, index) => (
                <Row
                  key={index}
                  item={item}
                  handleEditPanelOpen={handleEditPanelOpen}
                  handleDeleteExam={handleDeleteExam}
                  permission={permission}
                  loading={loading}
                  handleSwitch={handleSwitch}
                  toggleSwitchLoading={toggleSwitchLoading}
                />
              ))
            ) : (
              <Tr className="bg-white">
                <th
                  colSpan="9"
                  className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
                >
                  There are no exam
                </th>
              </Tr>
            )}
          </Tbody>
        </TableContainer>
        <AddNewExam open={examAddPanel} onClose={handleClose} />

        <ExamEditPanel
          open={teammateData.open}
          onClose={handleEditPanelClose}
          data={teammateData.data}
        />
        <DeleteModal
          warningText="Teammate will be deleted. Do you want to proceed ?"
          open={deleteTeammateData.open}
          handleDialog={handleDeleteTeammate}
          handleDeleteAction={handleDeleteExam}
          loading={deleteTeammateData.loading}
        />
      </div>
    </OuterContentWrapper>
  )
}

const Row = ({
  item,
  handleSwitch,
  handleEditPanelOpen,
  handleDeleteExam,
  toggleSwitchLoading,
  permission
}) => {
  const openEditTeammatePanel = () => handleEditPanelOpen(item)
  const handleDelete = (e) => handleDeleteExam(item, e)

  return (
    <Tr key={item._id}>
      <Td onClick={null} className="whitespace-nowrap text-left cursor-pointer">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.abbreviation}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.name}</span>
        </div>
      </Td>

      <Td className="whitespace-nowrap w-0.5 truncate">
        {item?.examCategory?.length ? (
          <>
            {item.examCategory.slice(0, 2).map((examCategory, index) => (
              <div className={index === 0 ? 'leading-6' : 'mt-2'}>
                {index === 0 ? (
                  <div className="leading-3">
                    <Pill variant="pink">{examCategory.name}</Pill>
                  </div>
                ) : (
                  <div className="flex items-center leading-3">
                    <Pill variant="blue">{examCategory.name}</Pill>
                    {item.examCategory?.length > 2 ? (
                      <Pill
                        variant="black"
                        style={{
                          fontSize: 10,
                          padding: '5px',
                          fontWeight: 'bold'
                        }}
                      >
                        +{item.examCategory?.length - 2}
                      </Pill>
                    ) : null}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <div>-</div>
        )}
      </Td>
      {/* <Td className="whitespace-wrap text-left">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.title}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.type}</span>
        </div>
      </Td> */}

      <Td className="whitespace-nowrap w-0.5 truncate">
        {item?.subject?.length ? (
          <>
            {item.subject.slice(0, 2).map((subject, index) => (
              <div className={index === 0 ? 'leading-6' : 'mt-2'}>
                {index === 0 ? (
                  <div className="leading-3">
                    <Pill variant="pink">{subject.name}</Pill>
                  </div>
                ) : (
                  <div className="flex items-center leading-3">
                    <Pill variant="blue">{subject.name}</Pill>
                    {item.subject?.length > 2 ? (
                      <Pill
                        variant="black"
                        style={{
                          fontSize: 10,
                          padding: '5px',
                          fontWeight: 'bold'
                        }}
                      >
                        +{item.subject?.length - 2}
                      </Pill>
                    ) : null}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <div>-</div>
        )}
      </Td>

      <Td className="whitespace-wrap text-center">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.title}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">
            {item.totalQuestions}
          </span>
        </div>
      </Td>
      <Td className="whitespace-wrap text-center">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.title}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">
            {item.totalTimeInMinutes}
          </span>
        </div>
      </Td>

      <Td className="whitespace-nowrap text-left">
        <ToggleSwitch
          style={{ margin: '0' }}
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>
      {/* <Td className="whitespace-nowrap text-center">
        <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          <svg
            className="mr-1 w-3 h-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {item.updatedAt ? format(new Date(item.updatedAt), 'd MMM p') : '-'}
        </span>
      </Td> */}

      {(permission[0]?.exam.update || permission[0]?.exam.delete) && (
        <Td className=" whitespace-nowrap text-center">
          <PopupMenu
            menuItems={[
              {
                label: 'Edit',
                method: openEditTeammatePanel,
                icon: <EditIcon className="text-primary-main" />
              },
              // {
              //   label: 'Settings',
              //   method: OpenSingleClientPage,
              //   icon: <SettingIcon className="text-primary-main" />
              // },
              {
                label: 'Delete',
                method: handleDelete,
                icon: <DeleteIcon className="text-error-main" />
              }
            ]}
          />
        </Td>
      )}

    </Tr>
  )
}

export default Exam
